
import Select, { components } from 'react-select';

const SelectCustomOption = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {props.data.label}
                </div>
            </components.Option>
        </div>
    );
};

export default SelectCustomOption;