import { combineReducers } from 'redux'
import { reducer as user } from './user'
import { reducer as settings } from './settings'

const reducers = {
  user,
  settings
}

export const appReducer = combineReducers(reducers)
