import { useDispatch } from 'react-redux';

export function useApiCall() {

    const dispatch = useDispatch()

    const callApi = (sagaAction: string, dataOrParams: any, callbackSuccess: Function, callbackError: Function) => {
        dispatch({
            type: sagaAction,
            payload: dataOrParams,
            callbackSuccess: (data: any) => {
                let message = data && data.message ? data.message : 'Request processed successfully'
                let resp:any = data ? data : null
                callbackSuccess && callbackSuccess(message, resp)
            },
            callbackError: (error: any) => {
                let message = error.message || (error.data && error.data.message) || 'Unable to process the request!'
                let resp = error && error.data ? error.data : null
                callbackError && callbackError(message, resp)
            }
        })
    }
    return callApi
}