import React, { useEffect, useState, ReactNode } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from "react-hook-form";
import filterSolidIcon from '../../assets/images/filter-solid-icon.svg';
import Pagination from '../../components/Pagination';
import { transTableDataApi } from '../../resources/hooks/api/transApiHook'
import { currencyFormat } from '../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { SalesTransSearchFormI } from '../../resources/form-props';
import { GL_VP, MONTHS } from '../../resources/constants';
import toast from 'react-hot-toast';
import moment from 'moment';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import {getBranch} from '../../store/user/selectors'

interface Props {

}

const SaleTransaction: React.FC<Props> = () => {
    const [record, setRecords] = useState<any>([]);
    const { fetchBankTransData } = transTableDataApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        year: 2024,
        month: 1,
        trans_type: 1,
    });
    const [ contentMaxHeight, setContentMaxHeight ] = useState<number>(0)

    // Start Paggination
    const [currentPage, setCurrentPage] = useState<any>(1);
    // const [currentPage, setCurrentPage] = useState(1);
    const [recordPerPage] = useState<any>(5000); // Change this value as needed
    const [totalPages, setTotalPages] = useState<any>(1);

    const [firstIndex, setFirstIndex] = useState<any>(1);
    const [lastIndex, setLastIndex] = useState<any>(1);
    const [totalGross, setTotalGross] = useState<any>(0);
    const [totalStateTax, setTotalStateTax] = useState<any>(0);

    const branchData = useSelector(getBranch)

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<SalesTransSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        //resolver: yupResolver(GLSearchFormValidate)
    });
    useEffect(() => {
        fetchTransactionList();
    }, [filterData])
    useEffect(() => {
        setContentMaxHeight( calculateMaxHeightContent(78) );
    })

    const fetchTransactionList = () => {
        const params: any = {
            "page": currentPage,
            "per_page": recordPerPage,
            year: +filterData['year'],
            month: +filterData['month'],
            account_type: 2,
            branch_id: +branchData['id']
        }

        let tempTotalGross = 0;
        let tempTotalStateTax = 0;


        setIsLoading(true);

        fetchBankTransData(params, (message: string, resp: any) => {

            const result = resp.data.data.result;
            
            setFirstIndex(resp.data.data.page);
            setLastIndex(resp.data.data.total);
            setTotalPages(resp.data.data.total);
            const push_rec: any[] = [];


            result.map((rec: any, index: any) => {
                const grossSale = rec['breakdowns'].filter((e: any) => e['account_type'] == "Gross_Sale");
                const stateTax = rec['breakdowns'].filter((e: any) => e['account_type'] == "State_Tax");

                const rec2 = {
                    parent_id: rec.parent_id,
                    description: rec.description,
                    account_name: rec.account_name,
                    transaction_at: rec.transaction_at,
                    state_tax: stateTax.length && stateTax[0]['account_value'] || 0,
                    gross_sale: grossSale.length && grossSale[0]['account_value'] || 0,
                    posted: rec.updated_at ? 'YES' : 'NO',

                }
                tempTotalGross += Math.abs(rec2.gross_sale);
                tempTotalStateTax += Math.abs(rec2.state_tax);
                push_rec.push(rec2);

            })

            setData(push_rec);
            setTotalGross(tempTotalGross);
            setTotalStateTax(tempTotalStateTax);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const processSearchData = (data: any) => {
        setFilterData({
            year: +data['year']['value'],
            month: +data['month']['value'],
        })
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Sales Transactions</h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="table-top" id="ux_page_filter">
                            <div className="row">
                                <Form >
                                    <div className="col-sm-6">
                                        <div className="filter-wrap">
                                            <Form.Group className="filter-box" controlId="formBasicEmail">
                                                <Controller
                                                    name="month"
                                                    control={control2}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={MONTHS}
                                                            className={'zindex-10'}
                                                        />
                                                    )}
                                                />
                                                {
                                                    errors2 && errors2['month'] && errors2['month'].message && (
                                                        <Form.Text className="text-danger">
                                                            {errors2['month'].message as ReactNode || ''}
                                                        </Form.Text>
                                                    ) || ''
                                                }

                                            </Form.Group>
                                            <Form.Group className="filter-box" controlId="formBasicEmail">
                                                <Controller
                                                    name="year"
                                                    control={control2}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={lastYears(5)}
                                                            className={'zindex-10'}
                                                        />
                                                    )}
                                                />
                                                {
                                                    errors2 && errors2['year'] && errors2['year'].message && (
                                                        <Form.Text className="text-danger">
                                                            {errors2['year'].message as ReactNode || ''}
                                                        </Form.Text>
                                                    ) || ''
                                                }

                                            </Form.Group>

                                            <div className="filter-btn">
                                                <button type="button" onClick={handleSubmit2(processSearchData)}><img src={filterSolidIcon} alt="" /> Filter</button>
                                            </div>
                                            <div className="filter-btn">
                                                <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                                    <div className="loader"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                            <table className="table table-default">
                                <thead>
                                    <tr className="align-middle">
                                        <th>#</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-end">Gross Sale</th>
                                        <th className="text-end">State Sale</th>
                                        <th className="text-center">Posted</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        <>
                                            {data.map((rec: any, index: any) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr className="align-middle h-60">
                                                            <td>{index + 1}</td>
                                                            <td className="text-center"><strong>{moment(rec.transaction_at).format('MM-DD-YYYY')}</strong></td>
                                                            <td className="text-end">{currencyFormat(Math.abs(rec.gross_sale))}</td>
                                                            <td className="text-end">{currencyFormat(Math.abs(rec.state_tax))}</td>
                                                            <td className="text-center">{rec.posted}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            {/* Total row */}
                                            <tr className="align-middle h-60">
                                                <td></td>
                                                <td className="text-right"><strong>Total</strong></td>
                                                <td className="text-end">{currencyFormat(Math.abs(totalGross).toFixed(2))}</td>
                                                <td className="text-end">{currencyFormat(Math.abs(totalStateTax).toFixed(2))}</td>
                                                <td colSpan={3}></td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className='text-center'>No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                           
                        </div>

                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={totalPages} fetchData={fetchTransactionList} /> */}
                    </div>


                </div>
            </div>
        </div>

    )
}

export default SaleTransaction;