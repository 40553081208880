
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser,updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* bankTrans(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.TRANSACTION.TRANS_LIST + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }

  // try {
  //   let result = yield call(CallApi.GET, API_URLS.TRANSACTION.TRANS_LIST,{},true);
  //   //yield put(updateUser(result.data));
  //   console.log(result.data)
  // } catch (e) {
  //   action && action.callbackError(e)
  // }
}


export function* createAdjustment(action: any): any {
  try {
    // const payload = action.payload;
    const { branch_id, params } = action.payload;
   
    const url = API_URLS.TRANSACTION.CREATE_TRANSACTION_ADJUSTMENT + `?branch_id=${branch_id}`;
    let result = yield call(CallApi.POST, url,params,true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }

}

