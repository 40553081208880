
import { call, put } from "redux-saga/effects";
import { updateSettings, updateOpeningBalanceExists } from "../store/settings";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

/*export function* fetchSettings(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.SETTINGS.FETCH_DETAILS  + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    
    yield put(updateAccounting(result.data.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}*/

export function* updateAccountingSettings(action: any): any {
  try {
    const payload = action.payload;
    const {params, ...queryString} = payload;
    const url = API_URLS.SETTINGS.UPDATE_ACCOUNTING  + '?' + formQueryString(queryString);
    let result = yield call(CallApi.PUT, url, params,true);
    
    // yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* updateSalesSettings(action: any): any {
  try {
    const payload = action.payload;
    const {params, ...queryString} = payload;
    const url = API_URLS.SETTINGS.UPDATE_SALES  + '?' + formQueryString(queryString);
    let result = yield call(CallApi.PUT, url, params,true);
    // yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* fetchAccountSettings(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.SETTINGS.FETCH_ACCOUNT_DETAILS  + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url,{},true);
    
    yield put(updateSettings(result.data.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* updateTolatestGl(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.SETTINGS.UPGRADE_TO_LATEST_GL  + '?' + formQueryString(payload);
    let result = yield call(CallApi.POST, url,{},true);
    console.log('result.data.data: ', result.data.data)
    yield put(updateOpeningBalanceExists(result.data.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}