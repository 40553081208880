import React, { useEffect, useState } from 'react'
import { useNavigate,Link } from 'react-router-dom'
//import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { LoginFormValidate } from '../../resources/form-validator'
import { LoginFormI } from '../../resources/form-props'
import { useUserApi } from '../../resources/hooks/api/userApiHook'

import { getUser, getAuthenticated } from '../../store/user/selectors'
import { URLS } from '../../resources/constants';
import logo from '../../assets/images/logo-rr.png';


interface Props {
  
}

const Login: React.FC<Props> = () => {
  // const value = useSelector(getValue)
  // const dispatch = useDispatch()
  const today = new Date();
  const year = today.getFullYear();
  const navigate = useNavigate();
  const { login,fetchUserData } = useUserApi();
  const checkAuthenticated = useSelector(getAuthenticated);

  const { control, handleSubmit, formState: { errors } } = useForm<LoginFormI>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(LoginFormValidate)
  });

  const processData = (data: any) => {
    login(data, (message: string, resp: any) => {
      toast.success(message)
      fetchUserData({},(message: string, resp: any)=>{

      }, (message: string, resp: any) => {
      })

      navigate(URLS.DASHBOARD);
    }, (message: string, resp: any) => {
      toast.error(message)
    })
  }

  return (
    <div className="login_page">
        <div className="loin_area">
            <div className="login_logo d-flex justify-content-center mb-2">
                <Link to="#"><img src={logo}/></Link>
            </div>
            <h3 className="h3">Welcome to <strong>RevRex</strong>, Sign In to Continue</h3>
            <div className="login_content">
            <Form  onSubmit={handleSubmit(processData)}>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <Form.Control {...field} autoComplete={'new-email'} />}
                  /> 
                  {
                    errors && errors['email'] && errors['email'].message && (
                      <Form.Text className="text-danger">
                        {errors['email'].message}
                      </Form.Text>
                    ) || '' 
                  }
                </Form.Group>

                <Form.Group className="form-group" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Form.Control {...field} type="password"  autoComplete={'new-password'} />}
                  />
                  {
                    errors && errors['password'] && errors['password'].message && (
                      <Form.Text className="text-danger">
                        {errors['password'].message}
                      </Form.Text>
                    ) || '' 
                  }
                </Form.Group>
                <div className="forgot-link">
                    <a href="#">Reset Password?</a>
                </div>
                <div className="btn-wrap">
                <button className="btn-solid">
                   Login to Continue
                </button>
                    
                </div>
                
            </Form>
            </div>
            {/* <div className="login_content">
                <div className="form-group">
                    <label for="">Email or Phone</label>
                    <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                    <label for="">Password</label>
                    <input type="text" className="form-control" />
                </div>
            </div>
            <div className="forgot-link">
                <a href="#">Reset Password?</a>
            </div>
            <div className="btn-wrap">
                <a href="dashboard.html" className="btn-solid">Login to Continue</a>
            </div> */}
            <div className="or-wrap">
                <span>Or</span>
            </div>
            <div className="btn-wrap">
                <a href="#" className="btn-outline">Use Two-Factor Authentication</a>
            </div>
        </div>
        <div className="login-footer">
            <p>&copy;{year} RevRex. All rights reserved.</p>
        </div>
        {/* <div className="login-footer">
            <p>&copy;
                <script>
                    document.write(new Date().getFullYear());
                </script> RevRex. All rights reserved.</p>
        </div> */}
    </div>
    
  )
}

export default Login;