import React from 'react'
import ReactDOM, {  } from 'react-dom'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'
import './assets/css/custom.css';

//import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from './App'

const Main: React.FC = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

// ReactDOM.(<Main />, document.getElementById('root'))

const root = createRoot(document.getElementById('root')!);   // notice the '!'
root.render(<Main />);