import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useBankFeedApi() {

    const callApi = useApiCall();

    const fetchBankTransData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.BANK_FEED.FETCH_LIST, data, onSuccess, onError);
    }

    const fetchRegisterTransData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.VIEW_REGISTER.FETCH_LIST, data, onSuccess, onError);
    }

    const feedUpdate = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.BANK_FEED.UPDATE, data, onSuccess, onError);
    }


    return {
        fetchBankTransData,
        fetchRegisterTransData,
        feedUpdate
    }
}