import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAccountSettings } from '../../store/settings/selectors'

export function userSteps() {

  const _getAccountSettings = useSelector(getAccountSettings);

  const userStepsChecker = () => {
    return _getAccountSettings.transaction_activity_status;
  }
  const [userStepsDetails, setUserStepsDetails] = useState(userStepsChecker())

  useEffect(() => {
    setUserStepsDetails(userStepsChecker())
  }, [_getAccountSettings.transaction_activity_status]);
  return userStepsDetails
}