import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { getMappedAcc } from '../../store/user/selectors'
import { getBankAccountMatchSettings } from '../../store/settings/selectors'

export function useAccountMapped() {

  const bankAccountMatch = useSelector(getBankAccountMatchSettings);

  const mappedAccChecker = () => {
    return bankAccountMatch;
  }
  const [mappedAccDetails, setMappedAccDetails] = useState(mappedAccChecker())

  useEffect(() => {
    setMappedAccDetails(mappedAccChecker())
  }, [bankAccountMatch]);

  return mappedAccDetails
}