
import React, { FC, useEffect, useState, ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';

import { RevRexBankAccSyncGlI } from '../../../../resources/form-props';
import { RevRexBankAccSyncGlValidate } from '../../../../resources/form-validator';
import { GL_VP, SELECT_CUSTOM_STYLE } from '../../../../resources/constants';
import SelectCustomOption from '../../../../components/SelectCustomOption';

interface Props {
    banks: any[],
    formRef: any,
    processData: ( data: any) => void,
    fechAccountList: (searchText: string, excludeVP: number[], includeVP: number[]) => Promise<any>
}
const BanksAccSync: FC<Props> = ({ banks, formRef, processData, fechAccountList }) => {

    const { control, register, handleSubmit, formState: { errors } } = useForm<RevRexBankAccSyncGlI>({
        defaultValues: {
            banks: []
        },
        resolver: yupResolver(RevRexBankAccSyncGlValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "banks"
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));

    useEffect(() => {
        if (banks.length) {
            banks.filter((bank: any) => bank['name'] !== "Manual Entry")
                .map((bank: any, index: number) => {
                    const bankRecord = {
                        label: `${bank['name']} ${bank['number']}`,
                        value: bank['bank_account_id'],
                    }
                    formAppend({
                        bank_account_id: bankRecord,
                        gl_account: null
                    })
                });
        }
    }, [banks])


    const filterBankAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, [], GL_VP.BANK_ACCOUNT.account)
        return data;
    };

    const _processData = (data: any) => {
        processData(data);
    }

    return (
        <div className='mb-2'>
            <Form ref={formRef} onSubmit={handleSubmit(_processData)}>
                {
                    fields.map((field: any, index: number) => (
                        <div className="row mt-2" key={index}>
                            <div className="align-items-center col-md-6 d-flex justify-content-center">
                                <div className="form-group mb-0">
                                <>{console.log('field: ', fields[index])}</>
                                    <p className="lebel  mb-0"><b>{fields[index]['bank_account_id']['label']}</b></p>
                                </div>
                            </div>
                            <div className="align-items-center col-md-6 d-flex justify-content-center">
                                <Form.Group className="col-sm-6" controlId="formBasicEmail">
                                    <Controller
                                        control={control}
                                        name={`banks.${index}.gl_account`}
                                        render={({ field: { value, onChange, onBlur, disabled } }) => (
                                            <AsyncSelect
                                                isClearable={true}
                                                defaultOptions
                                                loadOptions={filterBankAccountList}
                                                onChange={onChange}
                                                className='vp-dropdown'
                                                menuPosition='fixed'
                                                value={value}
                                                defaultValue={value}
                                                isDisabled={disabled}
                                                styles={selectCustomStyle}
                                                components={{ Option: SelectCustomOption }}
                                            />
                                        )}
                                    />
                                    {
                                        errors && errors.banks && errors.banks[index] && errors.banks[index]?.gl_account && errors.banks[index]?.gl_account?.message && (
                                            <Form.Text className="d-flex text-danger">
                                                <strong>
                                                    {errors.banks[index]?.gl_account?.message as ReactNode || ''}
                                                </strong>
                                            </Form.Text>
                                        ) || ''
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    ))
                }
                {
                    errors && errors.banks && errors.banks.message && (
                        <div className='mt-2 text-center'>
                            <span className="text-danger">
                                <strong>
                                    {errors.banks.message as ReactNode || ''}
                                </strong>
                            </span>
                        </div>

                    ) || ''
                }
                {
                    fields.length == 0 && (
                        <span>No Bank Account Found</span>
                    )
                }
            </Form>
        </div>
    )
}

export default BanksAccSync