

import { FC, useEffect, useState, useRef, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
// import './ChartAccountModal.css'

import { SalesValidate } from '../../../resources/form-validator';
import closeBtn from '../../../assets/images/close-btn.svg'
import { SalesI } from '../../../resources/form-props';
import { useSettingsApi } from '../../../resources/hooks/api/useSettingsApiHook';
import AsyncSelect from 'react-select/async';

import { getBranch } from '../../../store/user/selectors';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';

interface Props {
    closeModal: () => void,
    saleAccount: any,
    stateTaxAccount: any,
    type: string
}

const SalesAccountingModal: FC<Props> = ({ closeModal, saleAccount, stateTaxAccount, type }) => {

    const [showLoader, setShowLoader] = useState<boolean>(false)

    const { updateSalesSettings } = useSettingsApi();

    const { glAccountsList } = useChartAccountApi();

    const branchData = useSelector(getBranch);

    const defaultGlAccount = type === 'state_tax'
        ? stateTaxAccount
            ? {
                label: `${stateTaxAccount.name} (${stateTaxAccount.account})`,
                value: stateTaxAccount.id
            }
            : null
        : saleAccount
            ? {
                label: `${saleAccount.name} (${saleAccount.account})`,
                value: saleAccount.id
            }
            : null;

    const { control, register, reset, handleSubmit, formState: { errors } } = useForm<SalesI>({
        defaultValues: {
            gl_account: defaultGlAccount,
        },
        resolver: yupResolver(SalesValidate)
    });
    const [glAccount, setGlAccount] = useState<any[]>([]);
    useEffect(() => {

        fetchGlAccountList().then(r => {
            setGlAccount(r);
        })

    }, [])

    const fetchGlAccountList = (searchText: string = ''): Promise<any[]> => {

        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_in'] = (type == 'state_tax') ? '4300' : '6100';
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }


    const processData = (data: any) => {
        setShowLoader(true);
        const params: any = {};
        if (type === 'state_tax') {
            params.state_tax_account_id = data.gl_account?.value || null;
        } else {
            params.gross_sale_account_id = data.gl_account?.value || null;
        }



        updateSalesSettings({ params, branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setShowLoader(false);
            toast.success(message);
            _handleCloseModal();
        }, (message: string, resp: any) => {
            setShowLoader(false);
            toast.error(message);
        })
    }
    const filterGlAccountList = async (inputValue: string) => {
        const data = await fetchGlAccountList(inputValue)
        return data;
    };

    const _handleCloseModal = () => {
        reset();
        closeModal();
    }

    return (
        <Modal show={true} onHide={_handleCloseModal} backdrop="static" animation={false} size="lg">
            <div className="">
                <div className="">
                    <Form onSubmit={handleSubmit(processData)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Accounting</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={_handleCloseModal}><img src={closeBtn} alt="" /></button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">{(type == 'state_tax') ? 'Default State Tax GL Account' : 'Default Gross Sale Gl Account'}<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            control={control}
                                            name='gl_account'
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <AsyncSelect
                                                    isClearable={true}
                                                    placeholder={'Please select GL acc'}
                                                    defaultOptions={glAccount}
                                                    loadOptions={filterGlAccountList}
                                                    onChange={(e: any) => {
                                                        onChange(e);

                                                    }}
                                                    className='vp-dropdown'
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            width: '350px',
                                                        }),
                                                    }}
                                                    menuPosition='fixed'
                                                    value={value}
                                                    defaultValue={value}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors['gl_account'] && errors['gl_account'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['gl_account'].message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-cancel" onClick={_handleCloseModal}>Close</button>
                            <button type="submit" className="btn btn-secondary">
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save changes'}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default SalesAccountingModal;