
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getSettings } from '../../store/settings/selectors'
import { SettingsI } from '../../store/settings/types'
// import {getUser} from '../../store/user/selectors'
// import {UserI} from '../../store/user/types'
import { Link } from 'react-router-dom'

import { URLS } from '../../resources/constants'

const Footer: FC = () => {
    const today = new Date();
    const year = today.getFullYear();

    const SettingsData = useSelector(getSettings);
    // const UserData = useSelector(getUser)

    const [settingsData, setSettingsData] = useState<SettingsI | null>(null)
    // const [userData, setUserData] = useState<UserI | null>(null)

    useEffect(() => {
        setSettingsData(SettingsData)
    }, [SettingsData])
    // useEffect(() => {
    //     setUserData(UserData)
    // }, [UserData])

    return (
        <div id="ux_page_footer">

            <div className="mob-menu-wrapper">
                <ul className="mob-menu d-flex">
                    <li className="nav-item-mob active">
                        <Link className="active d-flex align-items-center gap-2 dashboard-menu-btn" aria-current="page" to="#"><i className="home-icon"></i> Dashboard</Link>

                    </li>
                    <li className="nav-item-mob">
                        <Link className="d-flex align-items-center gap-2" to="airport.html"><i className="airport-icon"></i> Airport</Link>
                    </li>
                    <li className="nav-item-mob">
                        <Link className="d-flex align-items-center gap-2" to="idea-list.html"><i className="usecase-icon"></i> Use Case</Link>
                    </li>
                    <li className="nav-item-mob">
                        <Link className="d-flex align-items-center gap-2" to="#"><i className="report-icon"></i> Reports</Link>
                    </li>
                    <li className="nav-item-mob">
                        <Link className="d-flex align-items-center gap-2 more-menu-btn" to="#"><i className="more-icon"></i> More</Link>
                    </li>
                </ul>
                <div className="more-menu-panel">
                    <ul>
                        <li><Link to="list-filter.html">Admin Employee</Link></li>
                        <li><Link to="solution-provider.html">Solution Provider</Link></li>
                    </ul>
                </div>
                <div className="dashboard-menu-panel">
                    <ul>
                        <li><Link to="#">Executive Summary</Link></li>
                        <li><Link to="#">KPI and Trending</Link></li>
                        <li><Link to="#">Period Comparison Chart</Link></li>
                        <li><Link to="#">Trending by Phase Chart</Link></li>
                        <li><Link to="#">Dynamic Drilldown</Link></li>
                    </ul>
                </div>

            </div>
            <div className="footer py-3 mt-4">
                <div className="container">
                    <p>&copy;
                        {year} RevRex. All rights reserved.</p>
                </div>
            </div>
        </div>

    )
}

export default Footer;