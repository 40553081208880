import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useFourThreeThreeBApi() {

    const callApi = useApiCall();
    const getFTTB = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.FOUR_THREE_THREE_B.DETAILS, data, onSuccess, onError);
    }

    const createFTTB = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.FOUR_THREE_THREE_B.CREATE, data, onSuccess, onError);
    }

    const updateFTTB = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.FOUR_THREE_THREE_B.UPDATE, data, onSuccess, onError);
    }
    const getMonthlyExpense = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.FOUR_THREE_THREE_B.MONTHLY_EXPENSE, data, onSuccess, onError);
    }
    const getPersonalBalanceSheet = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.DETAILS, data, onSuccess, onError);
    }
    const createPersonalBalanceSheet = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.CREATE, data, onSuccess, onError);
    }
    const getPersonalIncomeStatment = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.DETAILS, data, onSuccess, onError);
    }
    const createPersonalIncomeStatment = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.CREATE, data, onSuccess, onError);
    }


    return {
        getFTTB,
        createFTTB,
        updateFTTB,
        getMonthlyExpense,
        getPersonalBalanceSheet,
        createPersonalBalanceSheet,
        getPersonalIncomeStatment,
        createPersonalIncomeStatment
    }
}