import axios from 'axios';
import {store} from '../store';

// axios.defaults.withCredentials = true;
const getAccessToken=()=>{
  return store.getState().user.accessToken;
}

export class CallApi {
  static GET(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers:any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if(isAuth == true)
      {
        headers['Authorization'] =  'Bearer ' + token;
      }
      return axios({method: 'GET', url, headers, data}).then((success: any) => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response || error);
      });
    });
  }

  static POST(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers:any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if(isAuth == true)
      {
        headers['Authorization'] =  'Bearer ' + token;
      }
      
      return axios({method: 'POST', url, headers, data})
        .then(async success => {
          return resolve(success);
        })
        .catch(error => {
          return reject(error.response || error);
        });
    });
  }

  static PUT(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers:any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if(isAuth == true)
      {
        headers['Authorization'] =  'Bearer ' + token;
      }
      return axios({ method: 'PUT', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response || error);
      });
    });
  }

  static DELETE(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers:any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if(isAuth == true)
      {
        headers['Authorization'] =  'Bearer ' + token;
      }
      return axios({ method: 'DELETE', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response || error);
      });
    });
  }

  static DOWNLOAD(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers:any = {
        'Content-Type': 'application/pdf'
      }
      if(isAuth == true)
      {
        headers['Authorization'] =  'Bearer ' + token;
      }

      return axios({method: 'GET', url, headers, responseType: 'blob', data}).then((success: any) => {
        return resolve(success.data);
      }).catch(error => {
        return reject(error.response || error);
      });
    });
  }
}
