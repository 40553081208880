import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUser, getAuthenticated } from '../../store/user/selectors'

export function useAuthStatus() {

  const user = useSelector(getUser);
  const checkAuthenticated = useSelector(getAuthenticated);

  const authChecker = () => {
    return (checkAuthenticated && user) ? true : false
  }
  const [isAuthenticated, setAuthenticated] = useState(authChecker())

  useEffect(() => {
    setAuthenticated(authChecker())
  }, [user]);

  return isAuthenticated
}