
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReportApi } from '../../resources/hooks/api/useReportApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS } from '../../resources/constants';
import { calculateMaxHeightContent, fileDownloadBlob, lastYears, usdCurrencyFormat } from '../../resources/functions';
import { currencyFormat } from '../../resources/helper';
import {getBranch} from '../../store/user/selectors'

interface Props {

}

const ProfitLoss: React.FC<Props> = () => {
    const { profitLossData, downloadReport } = useReportApi();

    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [glProfitLossList, setGlProfitLossList] = useState<any[]>([]);
    const [month, setMonth] = useState<any>(1);
    const [year, setYear] = useState<any>(2024);
    const [searchParams, setSearchParams] = useState<any>({
        month: { value: '01', label: 'January' },
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)

    const branchData = useSelector(getBranch)

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(78));
    })

    const fetchProfitLossList = () => {
        const params: any = {
            "page": 1,
            "per_page": 5000,
            year: searchParams.year['value'],
            month: searchParams.month['value'],
            branch_id: +branchData['id']
        }
        setYear(searchParams.year['value']);
        setMonth(searchParams.month['value']);

        setIsLoading(true);
        profitLossData(params, (message: string, resp: any) => {
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data'] && resp['data']['data']['result']) {
                setGlProfitLossList(resp.data.data.result);
                console.log(resp.data.data.result);
            } else {
                setGlProfitLossList([]);
            }

        }, (message: string, resp: any) => {
            setGlProfitLossList([]);
            setIsLoading(false);
            toast.error(message);
        })
    }


    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'month') {
            setSearchParams({
                ...searchParams,
                'month': e
            })
        } else if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }
    const getLastDayOfMonth = (month: any, year: any) => {
        return new Date(year, month, 0).getDate();
    }
    const percentageFormat = (value: any) => {
        return `${value}%`;
    };

    const calculateRatio = (amount: any, budget: any) => {
        // Ensure numerator and denominator are not zero to avoid division by zero error
        if (budget !== 0) {
            const ratio = (amount / budget) * 100;
            return percentageFormat(ratio.toFixed(2));
        } else {
            // Handle division by zero error gracefully
            return percentageFormat((0).toFixed(2))
        }
    };

    const _downloadReport = () => {
        const params: any = {
            year: searchParams.year['value'],
            month: +searchParams.month['value'],
            branch_id: +branchData['id'],
            type: 2 // for profit/loss
        }

        setIsLoading(true);
        downloadReport(params, (message: string, resp: any) => {
            setIsLoading(false);
            fileDownloadBlob(resp, `ps-${searchParams.year['value']}-${searchParams.month['value']}`)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Profit and Loss (P&L) Statement</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap">
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'month')}
                                        options={MONTHS}
                                        defaultValue={searchParams['month']}
                                        className={'zindex-10'}
                                    />
                                </div>
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchProfitLossList} className="btn-primary btn btn-lg">Generate Report &nbsp; <i className="fa fa-search" aria-hidden="true"></i></button>
                                </div>
                                {
                                    glProfitLossList.length ? (
                                        <div className="filter-box text-end">
                                            <button type="button" onClick={_downloadReport} className="btn-dark btn btn-lg">
                                                Export PDF &nbsp;
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    ) : <></>
                                }
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                        <table className="table table-default table-bordered">
                            <thead>
                                <tr className="align-middle">
                                    <th className="text-center position-fix" colSpan={2}></th>
                                    <th className="text-center" colSpan={4}>CURRENT MONTH<br />{month}/01/{year}  TO  {month}/{getLastDayOfMonth(month, year)}/{year}</th>
                                    <th colSpan={1}></th>
                                    <th className="text-center" colSpan={4}>YEAR TO DATE<br />01/01/{year} TO {month}/{getLastDayOfMonth(month, year)}/{year}</th>
                                    <th colSpan={1}></th>
                                    <th className="text-center" colSpan={2}>CURRENT MONTH PRIOR YEAR<br />{month}/01/{year - 1} TO {month}/{getLastDayOfMonth(month, year - 1)}/{year - 1}</th>
                                    <th colSpan={1}></th>
                                    <th className="text-center" colSpan={2}>YEAR TO DATE PRIOR YEAR<br />01/01/{year - 1} TO {month}/{getLastDayOfMonth(month, year - 1)}/{year - 1}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    glProfitLossList.length && (
                                        <>
                                            <tr className="align-middle h-60  bs-table-body-SH">

                                                <td className="position-fix" colSpan={2}></td>
                                                
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>

                                                <td colSpan={1}></td>
                                                
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>

                                                <td colSpan={1}></td>

                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                {/* <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td> */}

                                                <td colSpan={1}></td>

                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                {/* <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                <td className="text-center"><h4 className='under-line'>RATIO</h4></td> */}
                                                

                                            </tr>
                                            {
                                                glProfitLossList.map((data: any, index: number) => {
                                                    const { report_code } = data['account_chart'];
                                                    const shouldHideRow = ['VP', 'VP/IP', 'IP'].includes(report_code) && !data['total_amount'] && !data['current_year_budget_amount'] && !data['ytd_total_amount'] && !data['budget_ytd_total_amount'] && !data['prev_year_total_amount'] && !data['budget_prev_year_total_amount'] && !data['prev_ytd_total_amount'] && !data['budget_prev_ytd_total_amount'] ? 'd-none' : '';
                                                    if(shouldHideRow == '')
                                                    {
                                                        return (

                                                            <tr className={`position-fix align-middle h-60 bs-table-body-${data['account_chart']['report_code']}`} key={index}>

                                                                <td colSpan={2}><h4 className='sub-data'>{data['account_chart']['name']}</h4></td>
                                                                {
                                                                    data['account_chart']['report_code'] == 'SH' && (
                                                                        <>
                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>

                                                                            <td></td>

                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>

                                                                            <td></td>

                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                                            {/* <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td> */}

                                                                            <td></td>

                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>AMOUNT</h4></td>
                                                                            {/* <td className="text-center"><h4 className='under-line'>BUDGET</h4></td>
                                                                            <td className="text-center"><h4 className='under-line'>RATIO</h4></td> */}
                                                                        </>
                                                                    ) ||
                                                                    (

                                                                        <>
                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['ratio'] || 0).toFixed(2))}</h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>
                                                                                {data['total_amount'] >= 0 ? currencyFormat((data['total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['total_amount'].toFixed(2)))})`}
                                                                                </h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>{data['current_year_budget_amount'] >= 0 ? currencyFormat((data['current_year_budget_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['current_year_budget_amount'].toFixed(2)))})`}</h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>{calculateRatio(data['total_amount'] || 0, data['current_year_budget_amount'] || 0)}</h4></td>
                                                                            

                                                                            <td></td>

                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['ytd_ratio'] || 0).toFixed(2))}</h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>{data['ytd_total_amount'] >= 0 ? currencyFormat((data['ytd_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['ytd_total_amount'].toFixed(2)))})`}
                                                                                </h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>
                                                                            {data['budget_ytd_total_amount'] >= 0 ? currencyFormat((data['budget_ytd_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['budget_ytd_total_amount'].toFixed(2)))})`}</h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>{calculateRatio(data['ytd_total_amount'] || 0, data['budget_ytd_total_amount'] || 0)}</h4></td>

                                                                            <td></td>

                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['prev_year_ratio'] || 0).toFixed(2))}</h4></td>
                                                                            {/* {calculateRatio(data['prev_year_total_amount'] || 0, data['budget_prev_year_total_amount'] || 0)} */}
                                                                            <td className="text-end"><h4 className='sub-data'>
                                                                            {data['prev_year_total_amount'] >= 0 ? currencyFormat((data['prev_year_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['prev_year_total_amount'].toFixed(2)))})`}</h4></td>
                                                                            {/* <td className="text-end"><h4 className='sub-data'>
                                                                            {data['budget_prev_year_total_amount'] >= 0 ? currencyFormat((data['budget_prev_year_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['budget_prev_year_total_amount'].toFixed(2)))})`}
                                                                            </h4></td>
                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['prev_year_ratio'] || 0).toFixed(2))}</h4></td> */}

                                                                            <td></td>

                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['prev_ytd_ratio'] || 0).toFixed(2))}</h4></td>
                                                                            {/* {calculateRatio(data['prev_ytd_total_amount'] || 0, data['budget_prev_ytd_total_amount'] || 0)} */}
                                                                            <td className="text-end"><h4 className='sub-data'>
                                                                            {data['prev_ytd_total_amount'] >= 0 ? currencyFormat((data['prev_ytd_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['prev_ytd_total_amount'].toFixed(2)))})`}</h4></td>
                                                                            {/* <td className="text-end"><h4 className='sub-data'>{data['budget_prev_ytd_total_amount'] >= 0 ? currencyFormat((data['budget_prev_ytd_total_amount'] || 0).toFixed(2)) : `(${currencyFormat(Math.abs(data['budget_prev_ytd_total_amount'].toFixed(2)))})`}
                                                                                </h4>
                                                                            </td>
                                                                            <td className="text-end"><h4 className='sub-data'>{percentageFormat((data['prev_ytd_ratio'] || 0).toFixed(2))}</h4></td> */}
                                                                            
                                                                        </>
                                                                    )
                                                                }
                                                            </tr>
                                                        );
                                                    }
                                                })
                                            }
                                        </>

                                    ) || (
                                        <tr className="align-middle h-60">
                                            <td colSpan={16} className='text-center'><span>Profit & Loss Statement is not available for the select month and year</span></td>
                                        </tr>

                                    )
                                }

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
        </div>

    )
}

export default ProfitLoss;