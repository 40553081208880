
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReportApi } from '../../resources/hooks/api/useReportApiHook';
import { useFourThreeThreeBApi } from '../../resources/hooks/api/fourThreeThreeBApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS, STATES } from '../../resources/constants';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { CreateFourThreeThreeFormI } from '../../resources/form-props';
import Spinner from 'react-bootstrap/Spinner';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {getBranch} from '../../store/user/selectors'


interface Props {

}

const FourThreeThreeB: React.FC<Props> = () => {
    const { profitLossData } = useReportApi();
    const { createFTTB, updateFTTB, getFTTB, getMonthlyExpense } = useFourThreeThreeBApi();
    const { control, register, watch, handleSubmit, getValues, resetField, reset, setValue, formState: { errors } } = useForm<CreateFourThreeThreeFormI>({
        defaultValues: {

        },
        //resolver: yupResolver(CAFormValidate)
    });
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [glProfitLossList, setGlProfitLossList] = useState<any[]>([]);
    const [month, setMonth] = useState<any>(1);
    const [year, setYear] = useState<any>(2024);
    const [record, setRecord] = useState<any[]>([]);


    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [currentMonthIndex, setCurrentMonthIndex] = useState<any>(new Date().getMonth());
    const currentMonthName = months[currentMonthIndex];
    const [selectedMonthName, setSelectedMonthName] = useState<any>(currentMonthName);

    const [searchParams, setSearchParams] = useState<any>({
        month: { value: new Date().getMonth() + 1, label: currentMonthName },
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);

    const branchData = useSelector(getBranch)

    const captureRef = useRef(null);

    // Function to capture the content and generate PDF

    const styles = {
        page: {
            marginLeft: '5rem',
            marginRight: '5rem',
            'page-break-after': 'always',
        },
    };
    const generatePDF = () => {
        if (captureRef.current) {
            // const doc = new jsPDF({
            //     format: 'a4',
            //     unit: 'px',

            // });
            const doc = new jsPDF('p', 'px', 'a4');
            //const doc = new jsPDF('p', 'px', [1350, 1000])
            //doc.setFontSize(5);

            // Adding the fonts.
            // doc.setFont('Inter-Regular', 'normal');

            doc.html(captureRef.current, {
                async callback(doc) {
                    await doc.save('433B-Form');
                },
            });
        }
    };


    const pagesRefs = useRef<(HTMLDivElement | null)[]>([]);

    // Function to capture a page and return its canvas
    const capturePage = async (pageRef: any) => {
        const canvas = await html2canvas(pageRef.current);
        return canvas;
    };

    const captureContentAndGeneratePDF = async () => {
        const pdf = new jsPDF('p', 'pt', 'a4');

        for (let i = 0; i < pagesRefs.current.length; i++) {
            const pageRef = pagesRefs.current[i];

            if (pageRef === null) {
                continue; // Skip if ref is null
            }

            // Capture the content of each page
            const canvas = await html2canvas(pageRef, { scale: 3 });

            // Add a new page if not the first page
            if (i > 0) {
                pdf.addPage();
            }

            // Add the captured content as an image to the PDF
            pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        }

        // Save or download the PDF
        pdf.save('433B-FORM.pdf');
    };



    // const captureContentAndGeneratePDF = () => {
    //     if (captureRef.current) {
    //     html2canvas(captureRef.current)
    //         .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF('p', 'mm', 'a4');
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
    //         const imgWidth = canvas.width;
    //         const imgHeight = canvas.height;
    //         const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //         const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //         const imgY = 30;
    //         pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio); 
    //         pdf.save('433B.pdf');
    //         //pdf.save('capture.pdf');
    //         })
    //         .catch((error) => {
    //         console.error('Error generating PDF:', error);
    //         });
    //     }
    // };

    useEffect(() => {
        //fetchProfitLossList();
        fetchMonthlyIncome();
    }, [])

    const [inputField, setInputField] = useState<any>({
        s1_bussiness_name: '',
        s1_bussiness_street_address: '',
        s1_mailing_address: '',
        s1_city: '',
        s1_state: '',
        s1_zip: '',
        s1_county: '',
        s1_business_telephone_code: '',
        s1_business_telephone_number: '',
        s1_business_type: '',
        s1_bussiness_website: '',
        s1_type_of_entity: '',
        s1_type_of_entity_other: '',
        s1_type_of_entity_other_llc: '',
        s1_date_incorporated: '',
        s1_employer_identification_no: '',
        s1_number_of_employees: '',
        s1_monthly_gross_payroll: '',
        s1_frequency_of_tax_deposits: '',
        s1_federal_tax_payment_system: '',
        s1_business_engage: '',
        s1_payment_name_address1: '',
        s1_payment_account_no1: '',
        s1_payment_name_address2: '',
        s1_payment_account_no2: '',
        s1_credit_card_type1: '',
        s1_credit_card_merchant_account1: '',
        s1_credit_card_issue_bank1: '',
        s1_credit_card_issue_bank_phone1: '',
        s1_credit_card_type2: '',
        s1_credit_card_merchant_account2: '',
        s1_credit_card_issue_bank2: '',
        s1_credit_card_issue_bank_phone2: '',
        s1_credit_card_type3: '',
        s1_credit_card_merchant_account3: '',
        s1_credit_card_issue_bank3: '',
        s1_credit_card_issue_bank_phone3: '',

        s2_full_name1: '',
        s2_title1: '',
        s2_home_address1: '',
        s2_city1: '',
        s2_state1: '',
        s2_zip1: '',
        s2_responsible1: '',
        s2_taxpayer_number1: '',
        s2_home_telephone_code1: '',
        s2_home_telephone_number1: '',
        s2_work_cell_code1: '',
        s2_work_cell_number1: '',
        s2_ownership_percentage1: '',
        s2_annual_salary_draw1: '',
        s2_full_name2: '',
        s2_title2: '',
        s2_home_address2: '',
        s2_city2: '',
        s2_state2: '',
        s2_zip2: '',
        s2_responsible2: '',
        s2_taxpayer_number2: '',
        s2_home_telephone_code2: '',
        s2_home_telephone_number2: '',
        s2_work_cell_code2: '',
        s2_work_cell_number2: '',
        s2_ownership_percentage2: '',
        s2_annual_salary_draw2: '',
        s2_full_name3: '',
        s2_title3: '',
        s2_home_address3: '',
        s2_city3: '',
        s2_state3: '',
        s2_zip3: '',
        s2_responsible3: '',
        s2_taxpayer_number3: '',
        s2_home_telephone_code3: '',
        s2_home_telephone_number3: '',
        s2_work_cell_code3: '',
        s2_work_cell_number3: '',
        s2_ownership_percentage3: '',
        s2_annual_salary_draw3: '',
        s2_full_name4: '',
        s2_title4: '',
        s2_home_address4: '',
        s2_city4: '',
        s2_state4: '',
        s2_zip4: '',
        s2_responsible4: '',
        s2_taxpayer_number4: '',
        s2_home_telephone_code4: '',
        s2_home_telephone_number4: '',
        s2_work_cell_code4: '',
        s2_work_cell_number4: '',
        s2_ownership_percentage4: '',
        s2_annual_salary_draw4: '',

        s3_business_use_payroll: '',
        s3_name_address: '',
        s3_effective_date: '',
        s3_business_party_lawsuit: '',
        s3_plaintiff_defendant: '',
        s3_location_of_filing: '',
        s3_represented_by: '',
        s3_docket_case_no: '',
        s3_amount_of_suit: '',
        s3_possible_completion_date: '',

        s3_subject_of_suit: '',
        s3_business_ever: '',
        s3_date_filed: '',
        s3_date_dismissed: '',
        s3_date_discharged: '',
        s3_petition_No: '',
        s3_district_of_filing: '',
        s3_do_any_related_parties: '',
        s3_name_and_address: '',
        s3_date_of_loan: '',
        s3_current_balance_as_of_date: '',
        s3_current_balance_as_of_amount: '',
        s3_payment_date: '',
        s3_payment_amount: '',
        s3_have_any_assets_been_transferred: '',
        s3_list_asset: '',
        s3_value_at_time_of_transfer: '',
        s3_date_transferred: '',
        s3_to_Whom_or_where_transferred: '',
        s3_have_other_business_affiliations: '',
        s3_related_business_name_and_address: '',
        s3_related_business_ein: '',
        s3_any_increase_decrease_in_income: '',
        s3_how_much_will_it_increase_decrease: '',
        s3_when_will_it_increase_decrease: '',
        s3_federal_government_contractor: '',
        s4_cash_on_hand: '',

        s4_safe_business_premises: '',
        s4_contents: '',
        s4_s4_business_bank_account_as_of: '',
        s4_business_bank_account_type_of_account1: '',
        s4_business_bank_account_name_addess1: '',
        s4_business_bank_account_ac_number1: '',
        s4_business_bank_account_amount1: '',
        s4_business_bank_account_type_of_account2: '',
        s4_business_bank_account_name_addess2: '',
        s4_business_bank_account_ac_number2: '',
        s4_business_bank_account_amount2: '',
        s4_business_bank_account_type_of_account3: '',
        s4_business_bank_account_name_addess3: '',
        s4_business_bank_account_ac_number3: '',
        s4_business_bank_account_amount3: '',
        s4_business_bank_account_total_cash: '',
        s4_accounts_notes_receivable_address1: '',
        s4_accounts_notes_receivable_contact_name1: '',
        s4_accounts_notes_receivable_phone1: '',
        s4_accounts_notes_receivable_status1: '',
        s4_accounts_notes_receivable_date_due1: '',
        s4_accounts_notes_receivable_invoice_number1: '',
        s4_accounts_notes_receivable_amount_due1: '',
        s4_accounts_notes_receivable_address2: '',
        s4_accounts_notes_receivable_contact_name2: '',
        s4_accounts_notes_receivable_phone2: '',
        s4_accounts_notes_receivable_status2: '',
        s4_accounts_notes_receivable_date_due2: '',
        s4_accounts_notes_receivable_invoice_number2: '',
        s4_accounts_notes_receivable_amount_due2: '',
        s4_accounts_notes_receivable_address3: '',
        s4_accounts_notes_receivable_contact_name3: '',
        s4_accounts_notes_receivable_phone3: '',
        s4_accounts_notes_receivable_status3: '',
        s4_accounts_notes_receivable_date_due3: '',
        s4_accounts_notes_receivable_invoice_number3: '',
        s4_accounts_notes_receivable_amount_due3: '',
        s4_accounts_notes_receivable_address4: '',
        s4_accounts_notes_receivable_contact_name4: '',
        s4_accounts_notes_receivable_phone4: '',
        s4_accounts_notes_receivable_status4: '',
        s4_accounts_notes_receivable_date_due4: '',
        s4_accounts_notes_receivable_invoice_number4: '',
        s4_accounts_notes_receivable_amount_due4: '',
        s4_accounts_notes_receivable_address5: '',
        s4_accounts_notes_receivable_contact_name5: '',
        s4_accounts_notes_receivable_phone5: '',
        s4_accounts_notes_receivable_status5: '',
        s4_accounts_notes_receivable_date_due5: '',
        s4_accounts_notes_receivable_invoice_number5: '',
        s4_accounts_notes_receivable_amount_due5: '',
        s4_accounts_notes_receivable_outstanding_balance: '',
        s4_investments_company_address1: '',
        s4_investments_company_name1: '',
        s4_investments_current_value1: '',
        s4_investments_loan_balance1: '',
        s4_investments_equity1: '',
        s4_investments_company_address2: '',
        s4_investments_company_name2: '',
        s4_investments_current_value2: '',
        s4_investments_loan_balance2: '',
        s4_investments_equity2: '',
        s4_investments_total_investments: '',
        s4_available_credit_as_of_amount_owed: '',
        s4_available_credit_as_of_available_credit: '',
        s4_available_credit_name_address1: '',
        s4_available_credit_account_no1: '',
        s4_available_credit_credit_limit1: '',
        s4_available_credit_amount_owed1: '',
        s4_available_credit_available_credit1: '',
        s4_available_credit_name_address2: '',
        s4_available_credit_account_no2: '',
        s4_available_credit_credit_limit2: '',
        s4_available_credit_amount_owed2: '',
        s4_available_credit_available_credit2: '',
        s4_available_credit_total_credit_available: '',
        s4_real_property_property_description1: '',
        s4_real_property_current_value1: '',
        s4_real_property_current_fair1: '',
        s4_real_property_current_loan_balance1: '',
        s4_real_property_amount_of_monthly_payment1: '',
        s4_real_property_date_of_final1: '',
        s4_real_property_equity_fmv_minus_loan1: '',
        s4_real_property_location1: '',
        s4_real_property_name_address1: '',
        s4_real_property_property_description2: '',
        s4_real_property_current_value2: '',
        s4_real_property_current_fair2: '',
        s4_real_property_current_loan_balance2: '',
        s4_real_property_amount_of_monthly_payment2: '',
        s4_real_property_date_of_final2: '',
        s4_real_property_equity_fmv_minus_loan2: '',
        s4_real_property_location2: '',
        s4_real_property_name_address2: '',
        s4_real_property_property_description3: '',
        s4_real_property_current_value3: '',
        s4_real_property_current_fair3: '',
        s4_real_property_current_loan_balance3: '',
        s4_real_property_amount_of_monthly_payment3: '',
        s4_real_property_date_of_final3: '',
        s4_real_property_equity_fmv_minus_loan3: '',
        s4_real_property_location3: '',
        s4_real_property_name_address3: '',
        s4_real_property_property_description4: '',
        s4_real_property_current_value4: '',
        s4_real_property_current_fair4: '',
        s4_real_property_current_loan_balance4: '',
        s4_real_property_amount_of_monthly_payment4: '',
        s4_real_property_date_of_final4: '',
        s4_real_property_equity_fmv_minus_loan4: '',
        s4_real_property_location4: '',
        s4_real_property_name_address4: '',
        s4_real_property_total_equity: '',
        s4_vehical_year1: '',
        s4_vehical_make_model1: '',
        s4_vehical_purchase_date1: '',
        s4_vehical_current_fair1: '',
        s4_vehical_current_loan1: '',
        s4_vehical_amount_of_monthly_payment1: '',
        s4_vehical_date_of_final_payment1: '',
        s4_vehical_equityfmv_minus_loan1: '',
        s4_vehical_mileage1: '',
        s4_vehical_license_tag_number1: '',
        s4_vehical_name_address1: '',
        s4_vehical_phone1: '',
        s4_vehical_vehicle_identification_number1: '',
        s4_vehical_year2: '',
        s4_vehical_make_model2: '',
        s4_vehical_purchase_date2: '',
        s4_vehical_current_fair2: '',
        s4_vehical_current_loan2: '',
        s4_vehical_amount_of_monthly_payment2: '',
        s4_vehical_date_of_final_payment2: '',
        s4_vehical_equityfmv_minus_loan2: '',
        s4_vehical_mileage2: '',
        s4_vehical_license_tag_number2: '',
        s4_vehical_name_address2: '',
        s4_vehical_phone2: '',
        s4_vehical_vehicle_identification_number2: '',
        s4_vehical_year3: '',
        s4_vehical_make_model3: '',
        s4_vehical_purchase_date3: '',
        s4_vehical_current_fair3: '',
        s4_vehical_current_loan3: '',
        s4_vehical_amount_of_monthly_payment3: '',
        s4_vehical_date_of_final_payment3: '',
        s4_vehical_equityfmv_minus_loan3: '',
        s4_vehical_mileage3: '',
        s4_vehical_license_tag_number3: '',
        s4_vehical_name_address3: '',
        s4_vehical_phone3: '',
        s4_vehical_vehicle_identification_number3: '',
        s4_vehical_year4: '',
        s4_vehical_make_model4: '',
        s4_vehical_purchase_date4: '',
        s4_vehical_current_fair4: '',
        s4_vehical_current_loan4: '',
        s4_vehical_amount_of_monthly_payment4: '',
        s4_vehical_date_of_final_payment4: '',
        s4_vehical_equityfmv_minus_loan4: '',
        s4_vehical_mileage4: '',
        s4_vehical_license_tag_number4: '',
        s4_vehical_name_address4: '',
        s4_vehical_phone4: '',
        s4_vehical_total_equity: '',
        s4_vehical_vehicle_identification_number4: '',
        s4_business_equipment_asset_description1: '',
        s4_business_equipment_purchase_date1: '',
        s4_business_equipment_current_fair1: '',
        s4_business_equipment_current_loan1: '',
        s4_business_equipment_amount_of_monthly_payment1: '',
        s4_business_equipment_date_of_final_payment1: '',
        s4_business_equipment_equityfmv_minus_loan1: '',
        s4_business_equipment_location1: '',
        s4_business_equipment_name_address1: '',
        s4_business_equipment_phone1: '',
        s4_business_equipment_asset_description2: '',
        s4_business_equipment_purchase_date2: '',
        s4_business_equipment_current_fair2: '',
        s4_business_equipment_current_loan2: '',
        s4_business_equipment_amount_of_monthly_payment2: '',
        s4_business_equipment_date_of_final_payment2: '',
        s4_business_equipment_equityfmv_minus_loan2: '',
        s4_business_equipment_location2: '',
        s4_business_equipment_name_address2: '',
        s4_business_equipment_phone2: '',
        s4_business_equipment_asset_description3: '',
        s4_business_equipment_purchase_date3: '',
        s4_business_equipment_current_fair3: '',
        s4_business_equipment_current_loan3: '',
        s4_business_equipment_amount_of_monthly_payment3: '',
        s4_business_equipment_date_of_final_payment3: '',
        s4_business_equipment_equityfmv_minus_loan3: '',
        s4_business_equipment_location3: '',
        s4_business_equipment_name_address3: '',
        s4_business_equipment_phone3: '',
        s4_business_equipment_asset_description4: '',
        s4_business_equipment_purchase_date4: '',
        s4_business_equipment_current_fair4: '',
        s4_business_equipment_current_loan4: '',
        s4_business_equipment_amount_of_monthly_payment4: '',
        s4_business_equipment_date_of_final_payment4: '',
        s4_business_equipment_equityfmv_minus_loan4: '',
        s4_business_equipment_location4: '',
        s4_business_equipment_name_address4: '',
        s4_business_equipment_phone4: '',
        s4_business_equipment_asset_description5: '',
        s4_business_equipment_equityfmv_minus_loan5: '',
        s4_business_equipment_asset_description6: '',
        s4_business_equipment_equityfmv_minus_loan6: '',
        s4_business_equipment_asset_description7: '',
        s4_business_equipment_equityfmv_minus_loan7: '',
        s4_business_equipment_total_equity: '',
        s4_business_liailities_description1: '',
        s4_business_liailities_secured_unsecured1: '',
        s4_business_liailities_date_pledged1: '',
        s4_business_liailities_balance_owed1: '',
        s4_business_liailities_date_of_final_payment1: '',
        s4_business_liailities_payment_amount1: '',
        s4_business_liailities_name1: '',
        s4_business_liailities_address1: '',
        s4_business_liailities_city1: '',
        s4_business_liailities_phone1: '',
        s4_business_liailities_description2: '',
        s4_business_liailities_secured_unsecured2: '',
        s4_business_liailities_date_pledged2: '',
        s4_business_liailities_balance_owed2: '',
        s4_business_liailities_date_of_final_payment2: '',
        s4_business_liailities_payment_amount2: '',
        s4_business_liailities_name2: '',
        s4_business_liailities_address2: '',
        s4_business_liailities_city2: '',
        s4_business_liailities_phone2: '',
        s4_business_liailities_total_payment: '',

        s5_accounting_method: '',
        s5_income_expense_date_from: '',
        s5_income_expense_date_to: '',
        s5_gross_receipts: '',
        s5_materials_purchased: '',
        s5_gross_rental_income: '',
        s5_inventory_purchased: '',
        s5_interest_income: '',
        s5_gross_wages: '',
        s5_dividends: '',
        s5_rent: '',
        s5_cash_receipts: '',
        s5_supplies: '',
        s5_other_income: '',
        s5_utilities_telephone: '',
        s5_vehicle_gasoline_Oil: '',
        s5_repairs_maintenance: '',
        s5_insurance: '',
        s5_current_taxes: '',
        s5_other_expense_specify: '',
        s5_total_income: '',
        s5_irs_installment_payments: '',
        s5_total_expenses: '',
        s5_net_income: '',
        s5_other1: '',
        s5_other2: '',
        s5_other3: '',
        s5_other4: '',
        s5_other5: '',
        s5_text_other1: '',
        s5_text_other2: '',
        s5_text_other3: '',
        s5_text_other4: '',
        s5_text_other5: '',

    })

    const [grossReceipts, setGrossReceipts] = useState('');
    const [grossRentalIncome, setGrossRentalIncome] = useState('');
    const [interestIncome, setInterestIncome] = useState('');
    const [dividends, setDividends] = useState('');
    const [cashReceipts, setCashReceipts] = useState('');
    const [otherIncome, setOtherIncome] = useState('');
    const [other1, setOther1] = useState('');
    const [other2, setOther2] = useState('');
    const [other3, setOther3] = useState('');
    const [other4, setOther4] = useState('');
    const [other5, setOther5] = useState('');
    const [totalSum, setTotalSum] = useState(0);

    const [materialsPurchased, setMaterialsPurchased] = useState('');
    const [inventoryPurchased, setInventoryPurchased] = useState('');
    const [grossWages, setGrossWages] = useState('');
    const [rent, setRent] = useState('');
    const [supplies, setSupplies] = useState('');
    const [utilitiesTelephone, setUtilitiesTelephone] = useState('');
    const [vehicleGasolineOil, setVehicleGasolineOil] = useState('');
    const [repairsMaintenance, setRepairsMaintenance] = useState('');
    const [insurance, setInsurance] = useState('');
    const [currentTaxes, setCurrentTaxes] = useState('');
    const [otherExpenseSpecify, setOtherExpenseSpecify] = useState('');
    const [irsInstallmentPayments, setIrsInstallmentPayments] = useState('');
    const [totalSum2, setTotalSum2] = useState(0);
    const [netIncome, setNetIncome] = useState(0);


    const numberInputsHandler = (e: any) => {
        const { name, value } = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setInputField((prevState: any) => ({
                ...prevState,
                [name]: value,
            }));
        }

    }

    const inputsHandler = (e: any) => {
        const { name, value } = e.target;
        setInputField((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));


        if (name === 's5_gross_receipts') {
            setGrossReceipts(value);
        } else if (name === 's5_gross_rental_income') {
            setGrossRentalIncome(value);
        } else if (name === 's5_interest_income') {
            setInterestIncome(value);
        } else if (name === 's5_dividends') {
            setDividends(value);
        } else if (name === 's5_cash_receipts') {
            setCashReceipts(value);
        } else if (name === 's5_other_income') {
            setOtherIncome(value);
        }
        else if (name === 's5_other1') {
            setOther1(value);
        }
        else if (name === 's5_other2') {
            setOther2(value);
        }
        else if (name === 's5_other3') {
            setOther3(value);
        }
        else if (name === 's5_other4') {
            setOther4(value);
        }
        else if (name === 's5_other5') {
            setOther5(value);
        }
        else if (name === 's5_materials_purchased') {
            setMaterialsPurchased(value);
        } else if (name === 's5_inventory_purchased') {
            setInventoryPurchased(value);
        } else if (name === 's5_gross_wages') {
            setGrossWages(value);
        } else if (name === 's5_rent') {
            setRent(value);
        } else if (name === 's5_supplies') {
            setSupplies(value);
        }
        else if (name === 's5_utilities_telephone') {
            setUtilitiesTelephone(value);
        }
        else if (name === 's5_vehicle_gasoline_Oil') {
            setVehicleGasolineOil(value);
        }
        else if (name === 's5_repairs_maintenance') {
            setRepairsMaintenance(value);
        }
        else if (name === 's5_insurance') {
            setInsurance(value);
        }
        else if (name === 's5_current_taxes') {
            setCurrentTaxes(value);
        }
        else if (name === 's5_other_expense_specify') {
            setOtherExpenseSpecify(value);
        }
        else if (name === 's5_irs_installment_payments') {
            setIrsInstallmentPayments(value);
        }
     

    }

    // Function to calculate total sum
    const calculateTotal = () => {
        const grossReceiptsValue = parseFloat(grossReceipts) || 0;
        const grossRentalIncomeValue = parseFloat(grossRentalIncome) || 0;
        const interestIncomeValue = parseFloat(interestIncome) || 0;
        const dividendsValue = parseFloat(dividends) || 0;
        const cashReceiptsValue = parseFloat(cashReceipts) || 0;
        const otherIncomeValue = parseFloat(otherIncome) || 0;
        const other1Value = parseFloat(other1) || 0;
        const other2Value = parseFloat(other2) || 0;
        const other3Value = parseFloat(other3) || 0;
        const other4Value = parseFloat(other4) || 0;
        const other5Value = parseFloat(other5) || 0;

        return grossReceiptsValue + grossRentalIncomeValue + interestIncomeValue + dividendsValue + cashReceiptsValue + otherIncomeValue + other1Value + other2Value + other3Value + other4Value + other5Value;
    };

    const calculateTotal2 = () => {
        const materialsPurchasedValue = parseFloat(materialsPurchased) || 0;
        const inventoryPurchasedValue = parseFloat(inventoryPurchased) || 0;
        const grossWagesValue = parseFloat(grossWages) || 0;
        const rentValue = parseFloat(rent) || 0;
        const suppliesValue = parseFloat(supplies) || 0;
        const utilitiesTelephoneValue = parseFloat(utilitiesTelephone) || 0;
        const vehicleGasolineOilValue = parseFloat(vehicleGasolineOil) || 0;
        const repairsMaintenanceValue = parseFloat(repairsMaintenance) || 0;
        const insuranceValue = parseFloat(insurance) || 0;
        const currentTaxesValue = parseFloat(currentTaxes) || 0;
        const otherExpenseSpecifyValue = parseFloat(otherExpenseSpecify) || 0;
        const irsInstallmentPaymentsValue = parseFloat(irsInstallmentPayments) || 0;

        return materialsPurchasedValue + inventoryPurchasedValue + grossWagesValue + rentValue + suppliesValue + utilitiesTelephoneValue + vehicleGasolineOilValue + repairsMaintenanceValue + insuranceValue + currentTaxesValue + otherExpenseSpecifyValue + irsInstallmentPaymentsValue;
    };

    const calculateNetIncome = () => {
        const totalSumValue = totalSum;
        const totalSumValue2 = totalSum2;
        return totalSumValue - totalSumValue2;
    };


    // alert(grossReceipts);

    // Function to update total sum when inputs change
    const updateTotalSum = () => {
        const sum = calculateTotal();
        setInputField((prevState: any) => ({
            ...prevState,
            ['s5_total_income']: sum,
        }));

        setTotalSum(sum);
    };
    const updateTotalSum2 = () => {
        const sum2 = calculateTotal2();
        setInputField((prevState: any) => ({
            ...prevState,
            ['s5_total_expenses']: sum2,
        }));

        setTotalSum2(sum2);
    };

    const updateNetIncone = () => {
        const sunNet = calculateNetIncome();
        setInputField((prevState: any) => ({
            ...prevState,
            ['s5_net_income']: sunNet,
        }));

        setNetIncome(sunNet);
    };
    useEffect(() => {
        updateNetIncone();
    }, [totalSum, totalSum2]);

    useEffect(() => {
        updateTotalSum2();
    }, [materialsPurchased, inventoryPurchased, grossWages, rent, supplies, utilitiesTelephone, vehicleGasolineOil, repairsMaintenance, insurance, currentTaxes, otherExpenseSpecify, irsInstallmentPayments]);
    useEffect(() => {
        updateTotalSum();
    }, [grossReceipts, grossRentalIncome, cashReceipts, interestIncome, dividends, dividends, otherIncome, other1, other2, other3, other4, other5]);


    const [businessName, setBusinessName] = useState<any>('');
    const [businessAaddress, setBusinessAddress] = useState<any>({});
    const [zipCode, setZipCode] = useState<any>('');


    const fetchMonthlyIncome = () => {

        const params: any = {
            year: searchParams.year['value'],
            month: searchParams.month['value'],
            branch_id: +branchData['id']
        }
        const MonthName = months[searchParams.month['value'] - 1];
        setSelectedMonthName(MonthName);
        setYear(searchParams.year['value']);
        setMonth(searchParams.month['value']);


        setIsLoading(true);
        getMonthlyExpense(params, (message: string, resp: any) => {
            setInputField([]);
            setIsLoading(false);
            if (resp && resp['data']) {
                //console.log(resp.data.data);
            
                setBusinessName(resp.data.data.business_name);
                setBusinessAddress(resp.data.data.business_address)
                if (resp.data.data.total_product_sale_revenue === null) {
                    setGrossReceipts('');
                }
                else {
                    setGrossReceipts(resp.data.data.total_product_sale_revenue)
                }
                if (resp.data.data.total_rental_revenue === null) {
                    setGrossRentalIncome('');
                }
                else {
                    setGrossRentalIncome(resp.data.data.total_rental_revenue)
                }
                setInputField((prevState: any) => ({
                    ...prevState,
                    ['s1_bussiness_name']: resp.data.data.business_name,
                    ['s1_business_telephone_code']:  resp.data.data.phone.replace(/[()]/g, '').slice(0,3),
                    ['s1_business_telephone_number']: resp.data.data.phone.replace(/[()]/g, '').slice(3),
                    ['s5_gross_receipts']: resp.data.data.total_product_sale_revenue,
                    ['s5_gross_rental_income']: resp.data.data.total_rental_revenue,
                }));
                //   "business_address": {
                //     "zip": "33791",
                //     "city": "Casper",
                //     "phone": "3540043783",
                //     "state": "MS",
                //     "country": "USA",
                //     "businessName": "Adolph Brown",
                //     "businessAddress": "91601 Scarlett Mall"
                //   }
                // if (resp.data.data.business_address != null) {
                    setInputField((prevState: any) => ({
                        ...prevState,
                        ['s1_bussiness_street_address']: resp.data.data.business_address,
                        ['s1_city']: resp.data.data.city || '',
                        ['s1_state']: resp.data.data.state && getStateNameByAbbreviation(resp.data.data.state) || [],
                        ['s1_zip']: resp.data.data.zip || '',
                        ['s1_county']: resp.data.data.country || '',
                    }));
                // }






                //setRecord(resp.data..result);
            } else {
                // setRecord([]);

            }

        }, (message: string, resp: any) => {

            setIsLoading(false);
            toast.error(message);
        });

        getFTTB(params, (message: string, resp: any) => {
            //
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data']) {
                const result = resp.data.data;
                setRecord(result);

                let entityOther = '';
                if (result.business_entity_type == 3) {
                    let entityOther = 's1_type_of_entity_other';
                } else if (result.business_entity_type == 5) {
                    let entityOther = 's1_type_of_entity_other_llc';
                }
                if (resp && resp['data']) {
                    setInputField((prevState: any) => ({
                        ...prevState,
                        ['s1_bussiness_name']: result.business_name || null,
                        ['s1_business_telephone_number']: result.business_telephone.slice(3) || null,
                        ['s1_business_type']: result.type_of_business || null,
                        ['s1_bussiness_website']: result.business_website || null,
                        ['s1_employer_identification_no']: result.ein || null,
                        ['s1_type_of_entity']: result.business_entity_type || null,
                        [entityOther]: result.entity_other || null,
                        ['s1_date_incorporated']: result.incorporated_at || null,
                        ['s1_number_of_employees']: result.employee_count || null,
                        ['s1_monthly_gross_payroll']: result.monthly_gross_payroll || null,
                        ['s1_frequency_of_tax_deposits']: result.tax_deposit_frequency || null,
                        ['s1_federal_tax_payment_system']: result.is_enrolled_eftps || null,
                        ['s1_business_engage']: result.is_engage_ecommerce || null,

                        ['s1_bussiness_street_address']: result.business_address || null,
                        ['s1_mailing_address']: result.business_address.address_line_2 || null,
                        ['s1_state']: result.business_address.state || null,
                        ['s1_zip']: result.business_address.zip || null,
                        ['s1_county']: result.business_address.county || null,
                        ['s1_city']: result.business_address.city || null,

                        ['s1_credit_card_type1']: result['credit_cards'][0]?.card_type || null,
                        ['s1_credit_card_merchant_account1']: result['credit_cards'][0]?.merchant_account_number || null,
                        ['s1_credit_card_issue_bank1']: result['credit_cards'][0]?.issuing_bank_details || null,
                        ['s1_credit_card_issue_bank_phone1']: result['credit_cards'][0]?.phone || null,
                        ['s1_credit_card_type2']: result['credit_cards'][1]?.card_type || null,
                        ['s1_credit_card_merchant_account2']: result['credit_cards'][1]?.merchant_account_number || null,
                        ['s1_credit_card_issue_bank2']: result['credit_cards'][1]?.issuing_bank_details || null,
                        ['s1_credit_card_issue_bank_phone2']: result['credit_cards'][1]?.phone || null,
                        ['s1_credit_card_type3']: result['credit_cards'][2]?.card_type || null,
                        ['s1_credit_card_merchant_account3']: result['credit_cards'][2]?.merchant_account_number || null,
                        ['s1_credit_card_issue_bank3']: result['credit_cards'][2]?.issuing_bank_details || null,
                        ['s1_credit_card_issue_bank_phone3']: result['credit_cards'][2]?.phone || null,

                        ['s1_payment_name_address1']: result['payment_processors'][0]?.processor_details || null,
                        ['s1_payment_account_no1']: result['payment_processors'][0]?.payment_account_number || null,
                        ['s1_payment_name_address2']: result['payment_processors'][1]?.processor_details || null,
                        ['s1_payment_account_no2']: result['payment_processors'][1]?.payment_account_number || null,

                        ['s2_full_name1']: result['business_personell_contacts'][0]?.full_name || null,
                        ['s2_title1']: result['business_personell_contacts'][0]?.title || null,
                        ['s2_responsible1']: result['business_personell_contacts'][0]?.is_responsible_deposit_payroll_tax || null,
                        ['s2_taxpayer_number1']: result['business_personell_contacts'][0]?.taxpayer_identification_number || null,
                        ['s2_home_telephone_number1']: result['business_personell_contacts'][0]?.home_phone || null,
                        ['s2_work_cell_number1']: result['business_personell_contacts'][0]?.work_phone || null,
                        ['s2_ownership_percentage1']: result['business_personell_contacts'][0]?.ownership_and_share_of_interest || null,
                        ['s2_annual_salary_draw1']: result['business_personell_contacts'][0]?.annual_salary || null,
                        ['s2_home_address1']: result['business_personell_contacts'][0]?.business_personell_addresses[0].address_line_1 || null,
                        // ['s2_title']: result['business_personell_contacts'][0]?.business_personell_address.address_line_2 || null,
                        ['s2_state1']: result['business_personell_contacts'][0]?.business_personell_addresses[0].state || null,
                        ['s2_zip1']: result['business_personell_contacts'][0]?.business_personell_addresses[0].zip || null,
                        // ['s2_home_telephone_number']: result['business_personell_contacts'][0]?.business_personell_addresses.country || null,
                        ['s2_city1']: result['business_personell_contacts'][0]?.business_personell_addresses[0].city || null,

                        ['s2_full_name2']: result['business_personell_contacts'][1]?.full_name || null,
                        ['s2_title2']: result['business_personell_contacts'][1]?.title || null,
                        ['s2_responsible2']: result['business_personell_contacts'][1]?.is_responsible_deposit_payroll_tax || null,
                        ['s2_taxpayer_number2']: result['business_personell_contacts'][1]?.taxpayer_identification_number || null,
                        ['s2_home_telephone_number2']: result['business_personell_contacts'][1]?.home_phone || null,
                        ['s2_work_cell_number2']: result['business_personell_contacts'][1]?.work_phone || null,
                        ['s2_ownership_percentage2']: result['business_personell_contacts'][1]?.ownership_and_share_of_interest || null,
                        ['s2_annual_salary_draw2']: result['business_personell_contacts'][1]?.annual_salary || null,

                        ['s2_home_address2']: result['business_personell_contacts'][1]?.business_personell_addresses[0].address_line_1 || null,
                        // ['s2_title']: result['business_personell_contacts'][1]?.business_personell_addresses.address_line_2 || null,
                        ['s2_state2']: result['business_personell_contacts'][1]?.business_personell_addresses[0].state || null,
                        ['s2_zip2']: result['business_personell_contacts'][1]?.business_personell_addresses[0].zip || null,
                        // ['s2_home_telephone_number']: result['business_personell_contacts'][1]?.business_personell_addresses.country || null,
                        ['s2_city2']: result['business_personell_contacts'][1]?.business_personell_addresses[0].city || null,

                        ['s2_full_name3']: result['business_personell_contacts'][2]?.full_name || null,
                        ['s2_title3']: result['business_personell_contacts'][2]?.title || null,
                        ['s2_responsible3']: result['business_personell_contacts'][2]?.is_responsible_deposit_payroll_tax || null,
                        ['s2_taxpayer_number3']: result['business_personell_contacts'][2]?.taxpayer_identification_number || null,
                        ['s2_home_telephone_number3']: result['business_personell_contacts'][2]?.home_phone || null,
                        ['s2_work_cell_number3']: result['business_personell_contacts'][2]?.work_phone || null,
                        ['s2_ownership_percentage3']: result['business_personell_contacts'][2]?.ownership_and_share_of_interest || null,
                        ['s2_annual_salary_draw3']: result['business_personell_contacts'][2]?.annual_salary || null,

                        ['s2_home_address3']: result['business_personell_contacts'][2]?.business_personell_addresses[0].address_line_1 || null,
                        // ['s2_title']: result['business_personell_contacts'][2]?.business_personell_addresses.address_line_2 || null,
                        ['s2_state3']: result['business_personell_contacts'][2]?.business_personell_addresses[0].state || null,
                        ['s2_zip3']: result['business_personell_contacts'][2]?.business_personell_addresses[0].zip || null,
                        // ['s2_home_telephone_number']: result['business_personell_contacts'][2]?.business_personell_addresses.country || null,
                        ['s2_city3']: result['business_personell_contacts'][2]?.business_personell_addresses[0].city || null,

                        ['s2_full_name4']: result['business_personell_contacts'][3]?.full_name || null,
                        ['s2_title4']: result['business_personell_contacts'][3]?.title || null,
                        ['s2_responsible4']: result['business_personell_contacts'][3]?.is_responsible_deposit_payroll_tax || null,
                        ['s2_taxpayer_number4']: result['business_personell_contacts'][3]?.taxpayer_identification_number || null,
                        ['s2_home_telephone_number4']: result['business_personell_contacts'][3]?.home_phone || null,
                        ['s2_work_cell_number4']: result['business_personell_contacts'][3]?.work_phone || null,
                        ['s2_ownership_percentage4']: result['business_personell_contacts'][3]?.ownership_and_share_of_interest || null,
                        ['s2_annual_salary_draw4']: result['business_personell_contacts'][3]?.annual_salary || null,
                        ['s2_home_address4']: result['business_personell_contacts'][3]?.business_personell_addresses[0].address_line_1 || null,
                        // ['s2_title']: result['business_personell_contacts'][3]?.business_personell_addresses.address_line_2 || null,
                        ['s2_state4']: result['business_personell_contacts'][3]?.business_personell_addresses[0].state || null,
                        ['s2_zip4']: result['business_personell_contacts'][3]?.business_personell_addresses[0].zip || null,
                        // ['s2_home_telephone_number']: result['business_personell_contacts'][3]?.business_personell_addresses.country || null,
                        ['s2_city4']: result['business_personell_contacts'][3]?.business_personell_addresses[0].city || null,

                        ['s3_business_use_payroll']: result.is_enabled_payroll_service_provider || null,
                        ['s3_business_party_lawsuit']: result.is_business_party_to_lawsuit || null,
                        ['s3_business_ever']: result.is_bankruptcy_ever_filed || null,
                        ['s3_do_any_related_parties']: result.is_outstanding_amount_owned_by_party || null,
                        ['s3_have_any_assets_been_transferred']: result.is_asset_transferred_for_less_value || null,
                        ['s3_have_other_business_affiliations']: result.is_affliated_other_business || null,
                        ['s3_any_increase_decrease_in_income']: result.is_increase_decrease_anticipated || null,
                        ['s3_federal_government_contractor']: result.is_federal_government_contractor || null,

                        ['s3_name_address']: result?.financial_informations?.payroll_details || null,
                        ['s3_effective_date']: result?.financial_informations?.payroll_effective_at || null,
                        ['s3_plaintiff_defendant']: result?.financial_informations?.lawsuit_type || null,
                        ['s3_location_of_filing']: result?.financial_informations?.lawsuit_location || null,
                        ['s3_represented_by']: result?.financial_informations?.lawsuit_represented_by || null,
                        ['s3_docket_case_no']: result?.financial_informations?.lawsuit_docket || null,
                        ['s3_amount_of_suit']: result?.financial_informations?.lawsuit_amount || null,
                        ['s3_possible_completion_date']: result?.financial_informations?.lawsuit_possible_completion_at || null,
                        ['s3_subject_of_suit']: result?.financial_informations?.lawsuit_subject || null,
                        ['s3_date_filed']: result?.financial_informations?.bankruptcy_filed_at || null,
                        ['s3_date_dismissed']: result?.financial_informations?.bankruptcy_dismissed_at || null,
                        ['s3_date_discharged']: result?.financial_informations?.bankruptcy_discharged_at || null,
                        ['s3_petition_No']: result?.financial_informations?.bankruptcy_petition_number || null,
                        ['s3_district_of_filing']: result?.financial_informations?.bankruptcy_district_of_filing || null,
                        ['s3_name_and_address']: result?.financial_informations?.related_party_details || null,
                        ['s3_date_of_loan']: result?.financial_informations?.related_party_date_of_loan || null,
                        ['s3_current_balance_as_of_amount']: result?.financial_informations?.related_party_current_balance_amount || null,
                        ['s3_current_balance_as_of_date']: result?.financial_informations?.related_party_current_balance_at || null,
                        ['s3_payment_date']: result?.financial_informations?.related_party_payment_amount || null,
                        ['s3_payment_amount']: result?.financial_informations?.asset_list || null,
                        ['s3_list_asset']: result?.financial_informations?.asset_list || null,
                        ['s3_value_at_time_of_transfer']: result?.financial_informations?.asset_value_at_transfer_time || null,
                        ['s3_date_transferred']: result?.financial_informations?.asset_transferred_at || null,
                        ['s3_to_Whom_or_where_transferred']: result?.financial_informations?.asset_transfer_details || null,
                        ['s3_related_business_name_and_address']: result?.financial_informations?.other_business_details || null,
                        ['s3_related_business_ein']: result?.financial_informations?.other_business_ein || null,
                        ['s3_explain']: result?.financial_informations?.anticipated_income_explain || null,
                        ['s3_how_much_will_it_increase_decrease']: result?.financial_informations?.anticipated_increase_or_decrease_amount || null,
                        ['s3_when_will_it_increase_decrease']: result?.financial_informations?.anticipated_increase_or_decrease_at || null,

                        ['s4_cash_on_hand']: result.total_cash_in_hand_amount || null,
                        ['s4_safe_business_premises']: result.is_safe_exist_in_premises || null,
                        ['s4_contents']: result.contents || null,
                        ['s4_business_bank_account_total_cash']: result.bank_accounts_total_cash_amount || null,
                        ['s4_accounts_notes_receivable_outstanding_balance']: result.notes_outstanding_total_cash_amount || null,
                        ['s4_investments_total_investments']: result.investment_total_amount || null,
                        ['s4_available_credit_total_credit_available']: result.total_credit_available_amount || null,
                        ['s4_real_property_total_equity']: result.properties_total_equity_amount || null,

                        ['s4_vehical_total_equity']: result.vehicles_total_equity_amount || null,
                        ['s4_business_equipment_total_equity']: result.equiments_total_equity_amount || null,
                        ['s4_business_liailities_total_payment']: result.business_liabilities_total_amount || null,
                        ['s4_s4_business_bank_account_as_of']: result.business_bank_accounts_account_balance_at || null,
                        ['s4_available_credit_as_of_amount_owed']: result.available_credits_owed_amount_at || null,
                        ['s4_available_credit_as_of_available_credit']: result.available_credits_credit_at || null,
                        ['s5_accounting_method']: result.accounting_method_used || null,
                        ['s5_income_expense_date_from']: result.income_from || null,
                        ['s5_income_expense_date_to']: result.income_to || null,
                        ['s5_total_income']: result.total_income_amount || 0,
                        ['s5_total_expenses']: result.total_expense_amount || 0,
                        ['s5_net_income']: result.net_income_amount || 0,


                        ['s4_business_bank_account_type_of_account1']: result['business_bank_accounts'][0]?.account_type || null,
                        ['s4_business_bank_account_name_addess1']: result['business_bank_accounts'][0]?.account_details || null,
                        ['s4_business_bank_account_ac_number1']: result['business_bank_accounts'][0]?.account_number || null,
                        ['s4_business_bank_account_amount1']: result['business_bank_accounts'][0]?.account_balance_amount || null,
                        ['s4_business_bank_account_type_of_account2']: result['business_bank_accounts'][1]?.account_type || null,
                        ['s4_business_bank_account_name_addess2']: result['business_bank_accounts'][1]?.account_details || null,
                        ['s4_business_bank_account_ac_number2']: result['business_bank_accounts'][1]?.account_number || null,
                        ['s4_business_bank_account_amount2']: result['business_bank_accounts'][1]?.account_balance_amount || null,
                        ['s4_business_bank_account_type_of_account3']: result['business_bank_accounts'][2]?.account_type || null,
                        ['s4_business_bank_account_name_addess3']: result['business_bank_accounts'][2]?.account_details || null,
                        ['s4_business_bank_account_ac_number3']: result['business_bank_accounts'][2]?.account_number || null,
                        ['s4_business_bank_account_amount3']: result['business_bank_accounts'][2]?.account_balance_amount || null,

                        ['s4_accounts_notes_receivable_address1']: result['accounts_notes_receivables'][0]?.details || null,
                        ['s4_accounts_notes_receivable_contact_name1']: result['accounts_notes_receivables'][0]?.name || null,
                        ['s4_accounts_notes_receivable_phone1']: result['accounts_notes_receivables'][0]?.phone || null,
                        ['s4_accounts_notes_receivable_status1']: result['accounts_notes_receivables'][0]?.status || null,
                        ['s4_accounts_notes_receivable_date_due1']: result['accounts_notes_receivables'][0]?.due_date_at || null,
                        ['s4_accounts_notes_receivable_invoice_number1']: result['accounts_notes_receivables'][0]?.invoice_number || null,
                        ['s4_accounts_notes_receivable_amount_due1']: result['accounts_notes_receivables'][0]?.due_amount || null,
                        ['s4_accounts_notes_receivable_address2']: result['accounts_notes_receivables'][1]?.details || null,
                        ['s4_accounts_notes_receivable_contact_name2']: result['accounts_notes_receivables'][1]?.name || null,
                        ['s4_accounts_notes_receivable_phone2']: result['accounts_notes_receivables'][1]?.phone || null,
                        ['s4_accounts_notes_receivable_status2']: result['accounts_notes_receivables'][1]?.status || null,
                        ['s4_accounts_notes_receivable_date_due2']: result['accounts_notes_receivables'][1]?.due_date_at || null,
                        ['s4_accounts_notes_receivable_invoice_number2']: result['accounts_notes_receivables'][1]?.invoice_number || null,
                        ['s4_accounts_notes_receivable_amount_due2']: result['accounts_notes_receivables'][1]?.due_amount || null,
                        ['s4_accounts_notes_receivable_address3']: result['accounts_notes_receivables'][2]?.details || null,
                        ['s4_accounts_notes_receivable_contact_name3']: result['accounts_notes_receivables'][2]?.name || null,
                        ['s4_accounts_notes_receivable_phone3']: result['accounts_notes_receivables'][2]?.phone || null,
                        ['s4_accounts_notes_receivable_status3']: result['accounts_notes_receivables'][2]?.status || null,
                        ['s4_accounts_notes_receivable_date_due3']: result['accounts_notes_receivables'][2]?.due_date_at || null,
                        ['s4_accounts_notes_receivable_invoice_number3']: result['accounts_notes_receivables'][2]?.invoice_number || null,
                        ['s4_accounts_notes_receivable_amount_due3']: result['accounts_notes_receivables'][2]?.due_amount || null,
                        ['s4_accounts_notes_receivable_address4']: result['accounts_notes_receivables'][3]?.details || null,
                        ['s4_accounts_notes_receivable_contact_name4']: result['accounts_notes_receivables'][3]?.name || null,
                        ['s4_accounts_notes_receivable_phone4']: result['accounts_notes_receivables'][3]?.phone || null,
                        ['s4_accounts_notes_receivable_status4']: result['accounts_notes_receivables'][3]?.status || null,
                        ['s4_accounts_notes_receivable_date_due4']: result['accounts_notes_receivables'][3]?.due_date_at || null,
                        ['s4_accounts_notes_receivable_invoice_number4']: result['accounts_notes_receivables'][3]?.invoice_number || null,
                        ['s4_accounts_notes_receivable_amount_due4']: result['accounts_notes_receivables'][3]?.due_amount || null,
                        ['s4_accounts_notes_receivable_address5']: result['accounts_notes_receivables'][4]?.details || null,
                        ['s4_accounts_notes_receivable_contact_name5']: result['accounts_notes_receivables'][4]?.name || null,
                        ['s4_accounts_notes_receivable_phone5']: result['accounts_notes_receivables'][4]?.phone || null,
                        ['s4_accounts_notes_receivable_status5']: result['accounts_notes_receivables'][4]?.status || null,
                        ['s4_accounts_notes_receivable_date_due5']: result['accounts_notes_receivables'][4]?.due_date_at || null,
                        ['s4_accounts_notes_receivable_invoice_number5']: result['accounts_notes_receivables'][4]?.invoice_number || null,
                        ['s4_accounts_notes_receivable_amount_due5']: result['accounts_notes_receivables'][4]?.due_amount || null,

                        ['s4_investments_company_address1']: result['investments'][0]?.investment_details || null,
                        ['s4_investments_used_as_collateral_on_loan1']: result['investments'][0]?.is_collateral_loan || null,
                        ['s4_investments_current_value1']: result['investments'][0]?.current_amount || null,
                        ['s4_investments_loan_balance1']: result['investments'][0]?.balance_amount || null,
                        ['s4_investments_company_name1']: result['investments'][0]?.phone || null,
                        ['s4_investments_equity1']: result['investments'][0]?.equity || null,
                        ['s4_investments_company_address2']: result['investments'][1]?.investment_details || null,
                        ['s4_investments_used_as_collateral_on_loan2']: result['investments'][1]?.is_collateral_loan || null,
                        ['s4_investments_current_value2']: result['investments'][1]?.current_amount || null,
                        ['s4_investments_loan_balance2']: result['investments'][1]?.balance_amount || null,
                        ['s4_investments_company_name2']: result['investments'][1]?.phone || null,
                        ['s4_investments_equity2']: result['investments'][1]?.equity || null,

                        ['s4_available_credit_name_address1']: result['available_credits'][0]?.account_details || null,
                        ['s4_available_credit_account_no1']: result['available_credits'][0]?.account_number || null,
                        ['s4_available_credit_credit_limit1']: result['available_credits'][0]?.credit_limit_amount || null,
                        ['s4_available_credit_amount_owed1']: result['available_credits'][0]?.owed_amount || null,
                        ['s4_available_credit_available_credit1']: result['available_credits'][0]?.available_credit_amount || null,
                        ['s4_available_credit_name_address2']: result['available_credits'][1]?.account_details || null,
                        ['s4_available_credit_account_no2']: result['available_credits'][1]?.account_number || null,
                        ['s4_available_credit_credit_limit2']: result['available_credits'][1]?.credit_limit_amount || null,
                        ['s4_available_credit_amount_owed2']: result['available_credits'][1]?.owed_amount || null,
                        ['s4_available_credit_available_credit2']: result['available_credits'][1]?.available_credit_amount || null,

                        ['s4_real_property_property_description1']: result['real_properties'][0]?.description || null,
                        ['s4_real_property_location1']: result['real_properties'][0]?.location_details || null,
                        ['s4_real_property_current_fair1']: result['real_properties'][0]?.current_fair_market_amount || null,
                        ['s4_real_property_current_loan_balance1']: result['real_properties'][0]?.current_loan_balance_amount || null,
                        ['s4_real_property_amount_of_monthly_payment1']: result['real_properties'][0]?.monthly_payment_amount || null,
                        ['s4_real_property_date_of_final1']: result['real_properties'][0]?.final_payment_at || null,
                        ['s4_real_property_equity_fmv_minus_loan1']: result['real_properties'][0]?.equity_amount || null,
                        ['s4_real_property_name_address1']: result['real_properties'][0]?.phone || null,
                        ['s4_real_property_property_description2']: result['real_properties'][1]?.description || null,
                        ['s4_real_property_location2']: result['real_properties'][1]?.location_details || null,
                        ['s4_real_property_current_fair2']: result['real_properties'][1]?.current_fair_market_amount || null,
                        ['s4_real_property_current_loan_balance2']: result['real_properties'][1]?.current_loan_balance_amount || null,
                        ['s4_real_property_amount_of_monthly_payment2']: result['real_properties'][1]?.monthly_payment_amount || null,
                        ['s4_real_property_date_of_final2']: result['real_properties'][1]?.final_payment_at || null,
                        ['s4_real_property_equity_fmv_minus_loan2']: result['real_properties'][1]?.equity_amount || null,
                        ['s4_real_property_name_address2']: result['real_properties'][1]?.phone || null,
                        ['s4_real_property_property_description3']: result['real_properties'][2]?.description || null,
                        ['s4_real_property_location3']: result['real_properties'][2]?.location_details || null,
                        ['s4_real_property_current_fair3']: result['real_properties'][2]?.current_fair_market_amount || null,
                        ['s4_real_property_current_loan_balance3']: result['real_properties'][2]?.current_loan_balance_amount || null,
                        ['s4_real_property_amount_of_monthly_payment3']: result['real_properties'][2]?.monthly_payment_amount || null,
                        ['s4_real_property_date_of_final3']: result['real_properties'][2]?.final_payment_at || null,
                        ['s4_real_property_equity_fmv_minus_loan3']: result['real_properties'][2]?.equity_amount || null,
                        ['s4_real_property_name_address3']: result['real_properties'][2]?.phone || null,
                        ['s4_real_property_property_description4']: result['real_properties'][3]?.description || null,
                        ['s4_real_property_location4']: result['real_properties'][3]?.location_details || null,
                        ['s4_real_property_current_fair4']: result['real_properties'][3]?.current_fair_market_amount || null,
                        ['s4_real_property_current_loan_balance4']: result['real_properties'][3]?.current_loan_balance_amount || null,
                        ['s4_real_property_amount_of_monthly_payment4']: result['real_properties'][3]?.monthly_payment_amount || null,
                        ['s4_real_property_date_of_final4']: result['real_properties'][3]?.final_payment_at || null,
                        ['s4_real_property_equity_fmv_minus_loan4']: result['real_properties'][3]?.equity_amount || null,
                        ['s4_real_property_name_address4']: result['real_properties'][3]?.phone || null,


                        ['s4_vehical_year1']: result['leased_purchased_vechiles'][0]?.year || null,
                        ['s4_vehical_mileage1']: result['leased_purchased_vechiles'][0]?.mileage || null,
                        ['s4_vehical_make_model1']: result['leased_purchased_vechiles'][0]?.model || null,
                        ['s4_vehical_license_tag_number1']: result['leased_purchased_vechiles'][0]?.lisence_number || null,
                        ['s4_vehical_vehicle_identification_number1']: result['leased_purchased_vechiles'][0]?.vin || null,
                        ['s4_vehical_name_address1']: result['leased_purchased_vechiles'][0]?.lender_details || null,
                        ['s4_vehical_purchase_date1']: result['leased_purchased_vechiles'][0]?.purchased_or_leased_at || null,
                        ['s4_vehical_current_fair1']: result['leased_purchased_vechiles'][0]?.s4_vehical_current_fair || null,
                        ['s4_vehical_current_loan1']: result['leased_purchased_vechiles'][0]?.current_loan_balance_amount || null,
                        ['s4_vehical_amount_of_monthly_payment1']: result['leased_purchased_vechiles'][0]?.monthly_payment_amount || null,
                        ['s4_vehical_date_of_final_payment1']: result['leased_purchased_vechiles'][0]?.final_payment_at || null,
                        ['s4_vehical_equityfmv_minus_loan1']: result['leased_purchased_vechiles'][0]?.equity_amount || null,
                        ['s4_vehical_phone1']: result['leased_purchased_vechiles'][0]?.phone || null,
                        ['s4_vehical_year2']: result['leased_purchased_vechiles'][1]?.year || null,
                        ['s4_vehical_mileage2']: result['leased_purchased_vechiles'][1]?.mileage || null,
                        ['s4_vehical_make_model2']: result['leased_purchased_vechiles'][1]?.model || null,
                        ['s4_vehical_license_tag_number2']: result['leased_purchased_vechiles'][1]?.lisence_number || null,
                        ['s4_vehical_vehicle_identification_number2']: result['leased_purchased_vechiles'][1]?.vin || null,
                        ['s4_vehical_name_address2']: result['leased_purchased_vechiles'][1]?.lender_details || null,
                        ['s4_vehical_purchase_date2']: result['leased_purchased_vechiles'][1]?.purchased_or_leased_at || null,
                        ['s4_vehical_current_fair2']: result['leased_purchased_vechiles'][1]?.s4_vehical_current_fair || null,
                        ['s4_vehical_current_loan2']: result['leased_purchased_vechiles'][1]?.current_loan_balance_amount || null,
                        ['s4_vehical_amount_of_monthly_payment2']: result['leased_purchased_vechiles'][1]?.monthly_payment_amount || null,
                        ['s4_vehical_date_of_final_payment2']: result['leased_purchased_vechiles'][1]?.final_payment_at || null,
                        ['s4_vehical_equityfmv_minus_loan2']: result['leased_purchased_vechiles'][1]?.equity_amount || null,
                        ['s4_vehical_phone2']: result['leased_purchased_vechiles'][1]?.phone || null,
                        ['s4_vehical_year3']: result['leased_purchased_vechiles'][2]?.year || null,
                        ['s4_vehical_mileage3']: result['leased_purchased_vechiles'][2]?.mileage || null,
                        ['s4_vehical_make_model3']: result['leased_purchased_vechiles'][2]?.model || null,
                        ['s4_vehical_license_tag_number3']: result['leased_purchased_vechiles'][2]?.lisence_number || null,
                        ['s4_vehical_vehicle_identification_number3']: result['leased_purchased_vechiles'][2]?.vin || null,
                        ['s4_vehical_name_address3']: result['leased_purchased_vechiles'][2]?.lender_details || null,
                        ['s4_vehical_purchase_date3']: result['leased_purchased_vechiles'][2]?.purchased_or_leased_at || null,
                        ['s4_vehical_current_fair3']: result['leased_purchased_vechiles'][2]?.s4_vehical_current_fair || null,
                        ['s4_vehical_current_loan3']: result['leased_purchased_vechiles'][2]?.current_loan_balance_amount || null,
                        ['s4_vehical_amount_of_monthly_payment3']: result['leased_purchased_vechiles'][2]?.monthly_payment_amount || null,
                        ['s4_vehical_date_of_final_payment3']: result['leased_purchased_vechiles'][2]?.final_payment_at || null,
                        ['s4_vehical_equityfmv_minus_loan3']: result['leased_purchased_vechiles'][2]?.equity_amount || null,
                        ['s4_vehical_phone3']: result['leased_purchased_vechiles'][2]?.phone || null,
                        ['s4_vehical_year4']: result['leased_purchased_vechiles'][3]?.year || null,
                        ['s4_vehical_mileage4']: result['leased_purchased_vechiles'][3]?.mileage || null,
                        ['s4_vehical_make_model4']: result['leased_purchased_vechiles'][3]?.model || null,
                        ['s4_vehical_license_tag_number4']: result['leased_purchased_vechiles'][3]?.lisence_number || null,
                        ['s4_vehical_vehicle_identification_number4']: result['leased_purchased_vechiles'][3]?.vin || null,
                        ['s4_vehical_name_address4']: result['leased_purchased_vechiles'][3]?.lender_details || null,
                        ['s4_vehical_purchase_date4']: result['leased_purchased_vechiles'][3]?.purchased_or_leased_at || null,
                        ['s4_vehical_current_fair4']: result['leased_purchased_vechiles'][3]?.s4_vehical_current_fair || null,
                        ['s4_vehical_current_loan4']: result['leased_purchased_vechiles'][3]?.current_loan_balance_amount || null,
                        ['s4_vehical_amount_of_monthly_payment4']: result['leased_purchased_vechiles'][3]?.monthly_payment_amount || null,
                        ['s4_vehical_date_of_final_payment4']: result['leased_purchased_vechiles'][3]?.final_payment_at || null,
                        ['s4_vehical_equityfmv_minus_loan4']: result['leased_purchased_vechiles'][3]?.equity_amount || null,
                        ['s4_vehical_phone4']: result['leased_purchased_vechiles'][3]?.phone || null,

                        ['s4_business_equipment_asset_description1']: result['business_equipments'][0]?.asset_description || null,
                        ['s4_business_equipment_location1']: result['business_equipments'][0]?.asset_location_details || null,
                        ['s4_business_equipment_name_address1']: result['business_equipments'][0]?.lender_details || null,
                        ['s4_business_equipment_purchase_date1']: result['business_equipments'][0]?.purchased_or_leased_at || null,
                        ['s4_business_equipment_current_fair1']: result['business_equipments'][0]?.current_fair_market_amount || null,
                        ['s4_business_equipment_current_loan1']: result['business_equipments'][0]?.current_loan_balance_amount || null,
                        ['s4_business_equipment_amount_of_monthly_payment1']: result['business_equipments'][0]?.monthly_payment_amount || null,
                        ['s4_business_equipment_date_of_final_payment1']: result['business_equipments'][0]?.final_payment_at || null,
                        ['s4_business_equipment_equityfmv_minus_loan1']: result['business_equipments'][0]?.equity_amount || null,
                        ['s4_business_equipment_phone1']: result['business_equipments'][0]?.phone || null,
                        ['s4_business_equipment_asset_description2']: result['business_equipments'][1]?.asset_description || null,
                        ['s4_business_equipment_location2']: result['business_equipments'][1]?.asset_location_details || null,
                        ['s4_business_equipment_name_address2']: result['business_equipments'][1]?.lender_details || null,
                        ['s4_business_equipment_purchase_date2']: result['business_equipments'][1]?.purchased_or_leased_at || null,
                        ['s4_business_equipment_current_fair2']: result['business_equipments'][1]?.current_fair_market_amount || null,
                        ['s4_business_equipment_current_loan2']: result['business_equipments'][1]?.current_loan_balance_amount || null,
                        ['s4_business_equipment_amount_of_monthly_payment2']: result['business_equipments'][1]?.monthly_payment_amount || null,
                        ['s4_business_equipment_date_of_final_payment2']: result['business_equipments'][1]?.final_payment_at || null,
                        ['s4_business_equipment_equityfmv_minus_loan2']: result['business_equipments'][1]?.equity_amount || null,
                        ['s4_business_equipment_phone2']: result['business_equipments'][1]?.phone || null,
                        ['s4_business_equipment_asset_description3']: result['business_equipments'][2]?.asset_description || null,
                        ['s4_business_equipment_location3']: result['business_equipments'][2]?.asset_location_details || null,
                        ['s4_business_equipment_name_address3']: result['business_equipments'][2]?.lender_details || null,
                        ['s4_business_equipment_purchase_date3']: result['business_equipments'][2]?.purchased_or_leased_at || null,
                        ['s4_business_equipment_current_fair3']: result['business_equipments'][2]?.current_fair_market_amount || null,
                        ['s4_business_equipment_current_loan3']: result['business_equipments'][2]?.current_loan_balance_amount || null,
                        ['s4_business_equipment_amount_of_monthly_payment3']: result['business_equipments'][2]?.monthly_payment_amount || null,
                        ['s4_business_equipment_date_of_final_payment3']: result['business_equipments'][2]?.final_payment_at || null,
                        ['s4_business_equipment_equityfmv_minus_loan3']: result['business_equipments'][2]?.equity_amount || null,
                        ['s4_business_equipment_phone3']: result['business_equipments'][2]?.phone || null,
                        ['s4_business_equipment_asset_description4']: result['business_equipments'][3]?.asset_description || null,
                        ['s4_business_equipment_location4']: result['business_equipments'][3]?.asset_location_details || null,
                        ['s4_business_equipment_name_address4']: result['business_equipments'][3]?.lender_details || null,
                        ['s4_business_equipment_purchase_date4']: result['business_equipments'][3]?.purchased_or_leased_at || null,
                        ['s4_business_equipment_current_fair4']: result['business_equipments'][3]?.current_fair_market_amount || null,
                        ['s4_business_equipment_current_loan4']: result['business_equipments'][3]?.current_loan_balance_amount || null,
                        ['s4_business_equipment_amount_of_monthly_payment4']: result['business_equipments'][3]?.monthly_payment_amount || null,
                        ['s4_business_equipment_date_of_final_payment4']: result['business_equipments'][3]?.final_payment_at || null,
                        ['s4_business_equipment_equityfmv_minus_loan4']: result['business_equipments'][3]?.equity_amount || null,
                        ['s4_business_equipment_phone4']: result['business_equipments'][3]?.phone || null,
                        ['s4_business_equipment_asset_description5']: result['business_equipments'][4]?.asset_description || null,
                        ['s4_business_equipment_equityfmv_minus_loan5']: result['business_equipments'][4]?.equity_amount || null,
                        ['s4_business_equipment_asset_description6']: result['business_equipments'][5]?.asset_description || null,
                        ['s4_business_equipment_equityfmv_minus_loan6']: result['business_equipments'][5]?.equity_amount || null,
                        ['s4_business_equipment_asset_description7']: result['business_equipments'][6]?.asset_description || null,
                        ['s4_business_equipment_equityfmv_minus_loan7']: result['business_equipments'][6]?.equity_amount || null,

                        ['s4_business_liailities_description1']: result['business_liabilities'][0]?.description || null,
                        ['s4_business_liailities_secured_unsecured1']: result['business_liabilities'][0]?.is_secured || null,
                        ['s4_business_liailities_date_pledged1']: result['business_liabilities'][0]?.pledged_at || null,
                        ['s4_business_liailities_balance_owed1']: result['business_liabilities'][0]?.owed_balance_amount || null,
                        ['s4_business_liailities_date_of_final_payment1']: result['business_liabilities'][0]?.final_payment_at || null,
                        ['s4_business_liailities_payment_amount1']: result['business_liabilities'][0]?.payment_amount || null,
                        ['s4_business_liailities_phone1']: result['business_liabilities'][0]?.phone || null,
                        ['s4_business_liailities_name1']: result['business_liabilities'][0]?.name || null,
                        ['s4_business_liailities_address1']: result['business_liabilities'][0]?.business_liability_addresses[0].address_line_1 || null,
                        ['s4_business_liailities_city1']: result['business_liabilities'][0]?.business_liability_addresses[0].address_line_2 || null,
                        ['s4_business_liailities_description2']: result['business_liabilities'][1]?.description || null,
                        ['s4_business_liailities_secured_unsecured2']: result['business_liabilities'][1]?.is_secured || null,
                        ['s4_business_liailities_date_pledged2']: result['business_liabilities'][1]?.pledged_at || null,
                        ['s4_business_liailities_balance_owed2']: result['business_liabilities'][1]?.owed_balance_amount || null,
                        ['s4_business_liailities_date_of_final_payment2']: result['business_liabilities'][1]?.final_payment_at || null,
                        ['s4_business_liailities_payment_amount2']: result['business_liabilities'][1]?.payment_amount || null,
                        ['s4_business_liailities_phone2']: result['business_liabilities'][1]?.phone || null,
                        ['s4_business_liailities_name2']: result['business_liabilities'][1]?.name || null,
                        ['s4_business_liailities_address2']: result['business_liabilities'][1]?.business_liability_addresses[0].address_line_1 || null,
                        ['s4_business_liailities_city2']: result['business_liabilities'][1]?.business_liability_addresses[0].address_line_2 || null,

                        ['s5_gross_receipts']: result['monthly_expenses'][0]?.amount || null,
                        ['s5_gross_rental_income']: result['monthly_expenses'][1]?.amount || null,
                        ['s5_interest_income']: result['monthly_expenses'][2]?.amount || null,
                        ['s5_dividends']: result['monthly_expenses'][3]?.amount || null,
                        ['s5_cash_receipts']: result['monthly_expenses'][4]?.amount || null,
                        ['s5_text_other1']: result['monthly_expenses'][5]?.title || null,
                        ['s5_other1']: result['monthly_expenses'][5]?.amount || null,
                        ['s5_text_other2']: result['monthly_expenses'][6]?.title || null,
                        ['s5_other2']: result['monthly_expenses'][6]?.amount || null,
                        ['s5_text_other3']: result['monthly_expenses'][7]?.title || null,
                        ['s5_other3']: result['monthly_expenses'][7]?.amount || null,
                        ['s5_text_other4']: result['monthly_expenses'][8]?.title || null,
                        ['s5_other4']: result['monthly_expenses'][8]?.amount || null,
                        ['s5_text_other5']: result['monthly_expenses'][9]?.title || null,
                        ['s5_other5']: result['monthly_expenses'][9]?.amount || null,
                        ['s5_materials_purchased']: result['monthly_expenses'][10]?.amount || null,
                        ['s5_inventory_purchased']: result['monthly_expenses'][11]?.amount || null,
                        ['s5_gross_wages']: result['monthly_expenses'][12]?.amount || null,
                        ['s5_rent']: result['monthly_expenses'][13]?.amount || null,
                        ['s5_supplies']: result['monthly_expenses'][14]?.amount || null,
                        ['s5_utilities_telephone']: result['monthly_expenses'][15]?.amount || null,
                        ['s5_vehicle_gasoline_Oil']: result['monthly_expenses'][16]?.amount || null,
                        ['s5_repairs_maintenance']: result['monthly_expenses'][17]?.amount || null,
                        ['s5_insurance']: result['monthly_expenses'][18]?.amount || null,
                        ['s5_current_taxes']: result['monthly_expenses'][19]?.amount || null,
                        ['s5_other_expense_specify']: result['monthly_expenses'][20]?.amount || null,
                        ['s5_irs_installment_payments']: result['monthly_expenses'][21]?.amount || null,

                    }));

                }
            } else {
                setRecord([]);
            }

        }, (message: string, resp: any) => {
            setRecord([]);
            setIsLoading(false);
            //toast.error(message);
        })
    }

    const submitButton = () => {
        setShowLoader(true);
        const creditCard: any[] = [];
        const paymentProcessor: any[] = [];
        const businessPersonellContact: any[] = [];
        const businessBankAccount: any[] = [];
        for (var i = 1; i <= 3; i++) {
            const rec1 = {
                card_type: inputField[`s1_credit_card_type${i}`] || null,
                merchant_account_number: inputField[`s1_credit_card_merchant_account${i}`] || null,
                issuing_bank_details: inputField[`s1_credit_card_issue_bank${i}`] || null,
                phone: inputField[`s1_credit_card_issue_bank_phone${i}`] || null,
            }
            creditCard.push(rec1);

        }
        for (var i = 1; i <= 2; i++) {
            const rec = {
                processor_details: inputField[`s1_payment_name_address${i}`] || null,
                payment_account_number: inputField[`s1_payment_account_no${i}`] || null,
            }
            paymentProcessor.push(rec);

        }
        for (var i = 1; i <= 4; i++) {
            const rec = {
                full_name: inputField[`s2_full_name${i}`] || null,
                title: inputField[`s2_title${i}`] || null,
                is_responsible_deposit_payroll_tax: inputField[`s2_responsible${i}`] || null,
                taxpayer_identification_number: inputField[`s2_taxpayer_number${i}`] || null,
                home_phone: inputField[`s2_home_telephone_code${i}`] + inputField[`s2_home_telephone_number${i}`] || null,
                work_phone: inputField[`s2_work_cell_code${i}`] + inputField[`s2_work_cell_number${i}`] || null,
                ownership_and_share_of_interest: inputField[`s2_ownership_percentage${i}`] || null,
                annual_salary: inputField[`s2_annual_salary_draw${i}`] || null,
                business_personell_address: {
                    address_line_1: inputField[`s2_home_address${i}`] || null,
                    entity_type: 2,
                    address_line_2: null,
                    state: inputField[`s2_state${i}`] || null,
                    zip: inputField[`s2_zip${i}`] || null,
                    country: null,
                    city: inputField[`s2_city${i}`] || null,
                }
            }
            businessPersonellContact.push(rec);

        }
        for (var i = 1; i <= 3; i++) {
            const rec = {
                account_type: inputField[`s4_business_bank_account_type_of_account${i}`] || null,
                account_details: inputField[`s4_business_bank_account_name_addess${i}`] || null,
                account_number: inputField[`s4_business_bank_account_ac_number${i}`] || null,
                account_balance_amount: inputField[`s4_business_bank_account_amount${i}`] || null,
                //account_balance_at : inputField[`s4_s4_business_bank_account_as_of`] || null,

            }
            businessBankAccount.push(rec);
        }
        const accountsNotesReceivable: any[] = [];
        for (var i = 1; i <= 5; i++) {
            const rec = {
                details: inputField[`s4_accounts_notes_receivable_address${i}`] || null,
                name: inputField[`s4_accounts_notes_receivable_contact_name${i}`] || null,
                phone: inputField[`s4_accounts_notes_receivable_phone${i}`] || null,
                status: inputField[`s4_accounts_notes_receivable_status${i}`] || null,
                due_date_at: inputField[`s4_accounts_notes_receivable_date_due${i}`] || null,
                invoice_number: inputField[`s4_accounts_notes_receivable_invoice_number${i}`] || null,
                due_amount: inputField[`s4_accounts_notes_receivable_amount_due${i}`] || null,

            }
            accountsNotesReceivable.push(rec);
        }

        const investment: any[] = [];
        for (var i = 1; i <= 2; i++) {
            const rec = {
                investment_details: inputField[`s4_investments_company_address${i}`] || null,
                is_collateral_loan: inputField[`s4_investments_used_as_collateral_on_loan${i}`] || null,
                current_amount: inputField[`s4_investments_current_value${i}`] || null,
                balance_amount: inputField[`s4_investments_loan_balance${i}`] || null,
                phone: inputField[`s4_investments_company_name${i}`] || null,
                equity: inputField[`s4_investments_equity${i}`] || null,

            }
            investment.push(rec);
        }

        const availableCredit: any[] = [];
        for (var i = 1; i <= 2; i++) {
            const rec = {
                account_details: inputField[`s4_available_credit_name_address${i}`] || null,
                account_number: inputField[`s4_available_credit_account_no${i}`] || null,
                credit_limit_amount: inputField[`s4_available_credit_credit_limit${i}`] || null,
                owed_amount: inputField[`s4_available_credit_amount_owed${i}`] || null,
                available_credit_amount: inputField[`s4_available_credit_available_credit${i}`] || null,
                //owed_amount_at : inputField[`s4_available_credit_as_of_amount_owed`] || null,
                //available_credit_at : inputField[`s4_available_credit_as_of_available_credit`] || null,

            }
            availableCredit.push(rec);
        }

        const realPropertie: any[] = [];
        for (var i = 1; i <= 4; i++) {
            const rec = {
                description: inputField[`s4_real_property_property_description${i}`] || null,
                location_details: inputField[`s4_real_property_location${i}`] || null,
                purchase_leased_at: inputField[`s4_business_equipment_purchase_date${i}`] || null,
                current_fair_market_amount: inputField[`s4_real_property_current_fair${i}`] || null,
                current_loan_balance_amount: inputField[`s4_real_property_current_loan_balance${i}`] || null,
                monthly_payment_amount: inputField[`s4_real_property_amount_of_monthly_payment${i}`] || null,
                final_payment_at: inputField[`s4_real_property_date_of_final${i}`] || null,
                equity_amount: inputField[`s4_real_property_equity_fmv_minus_loan${i}`] || null,
                phone: inputField[`s4_real_property_name_address${i}`] || null,

            }
            realPropertie.push(rec);
        }

        const leasedPurchasedVechile: any[] = [];
        for (var i = 1; i <= 4; i++) {
            const rec = {
                year: inputField[`s4_vehical_year${i}`] || null,
                mileage: inputField[`s4_vehical_mileage${i}`] || null,
                model: inputField[`s4_vehical_make_model${i}`] || null,
                lisence_number: inputField[`s4_vehical_license_tag_number${i}`] || null,
                vin: inputField[`s4_vehical_vehicle_identification_number${i}`] || null,
                lender_details: inputField[`s4_vehical_name_address${i}`] || null,
                purchased_or_leased_at: inputField[`s4_vehical_purchase_date${i}`] || null,
                current_fair_market_amount: inputField[`s4_vehical_current_fair${i}`] || null,
                current_loan_balance_amount: inputField[`s4_vehical_current_loan${i}`] || null,
                monthly_payment_amount: inputField[`s4_vehical_amount_of_monthly_payment${i}`] || null,
                final_payment_at: inputField[`s4_vehical_date_of_final_payment${i}`] || null,
                equity_amount: inputField[`s4_vehical_equityfmv_minus_loan${i}`] || null,
                phone: inputField[`s4_vehical_phone${i}`] || null,

            }
            leasedPurchasedVechile.push(rec);
        }
        const businessLiabilitie: any[] = [];
        for (var i = 1; i <= 2; i++) {
            const rec = {
                description: inputField[`s4_business_liailities_description${i}`] || null,
                is_secured: inputField[`s4_business_liailities_secured_unsecured${i}`] || null,

                pledged_at: inputField[`s4_business_liailities_date_pledged${i}`] || null,
                owed_balance_amount: inputField[`s4_business_liailities_balance_owed${i}`] || null,
                final_payment_at: inputField[`s4_business_liailities_date_of_final_payment${i}`] || null,
                payment_amount: inputField[`s4_business_liailities_payment_amount${i}`] || null,
                //equity_amount : inputField[`s4_business_liailities_name${i}`] || null,
                phone: inputField[`s4_business_liailities_phone${i}`] || null,
                name: inputField[`s4_business_liailities_name${i}`] || null,
                business_liability_address: {
                    address_line_1: inputField[`s4_business_liailities_address${i}`] || null,
                    entity_type: 3,
                    address_line_2: inputField[`s4_business_liailities_city${i}`] || null,
                    state: null,
                    zip: null,
                    country: null,
                    city: null,
                },

            }
            businessLiabilitie.push(rec);
        }
        const businessEquiment: any[] = [];
        for (var i = 1; i <= 7; i++) {
            const rec = {
                asset_description: inputField[`s4_business_equipment_asset_description${i}`] || null,
                asset_location_details: inputField[`s4_business_equipment_location${i}`] || null,
                lender_details: inputField[`s4_business_equipment_name_address${i}`] || null,
                purchased_or_leased_at: inputField[`s4_business_equipment_purchase_date${i}`] || null,
                current_fair_market_amount: inputField[`s4_business_equipment_current_fair${i}`] || null,
                current_loan_balance_amount: inputField[`s4_business_equipment_current_loan${i}`] || null,
                monthly_payment_amount: inputField[`s4_business_equipment_amount_of_monthly_payment${i}`] || null,
                final_payment_at: inputField[`s4_business_equipment_date_of_final_payment${i}`] || null,
                equity_amount: inputField[`s4_business_equipment_equityfmv_minus_loan${i}`] || null,
                phone: inputField[`s4_business_equipment_phone${i}`] || null,
            }
            businessEquiment.push(rec);
        }



        // const monthlyExpense: any[] = [];
        const monthlyExpense = [
            // Total Monthly Business Income
            {
                type: 1,
                title: "Gross Receipts from Sales/Services",
                amount: inputField[`s5_gross_receipts`] || null,
            },
            {
                type: 1,
                title: "Gross Rental Income",
                amount: inputField[`s5_gross_rental_income`] || null,
            },
            {
                type: 1,
                title: "Interest Income",
                amount: inputField[`s5_interest_income`] || null,
            },
            {
                type: 1,
                title: "Dividends",
                amount: inputField[`s5_dividends`] || null,
            },
            {
                type: 1,
                title: "Cash Receipts (Not included in lines 25-28)",
                amount: inputField[`s5_cash_receipts`] || null,
            },
            {
                type: 1,
                title: inputField[`s5_text_other1`] || null,
                amount: inputField[`s5_other1`] || null,
            },
            {
                type: 1,
                title: inputField[`s5_text_other2`] || null,
                amount: inputField[`s5_other2`] || null,
            },
            {
                type: 1,
                title: inputField[`s5_text_other3`] || null,
                amount: inputField[`s5_other3`] || null,
            },
            {
                type: 1,
                title: inputField[`s5_text_other4`] || null,
                amount: inputField[`s5_other4`] || null,
            },
            {
                type: 1,
                title: inputField[`s5_text_other5`] || null,
                amount: inputField[`s5_other5`] || null,
            },
            // Total Monthly Business Expenses
            {
                type: 2,
                title: "Materials Purchased",
                amount: inputField[`s5_materials_purchased`] || null,
            },
            {
                type: 2,
                title: "Inventory Purchased",
                amount: inputField[`s5_inventory_purchased`] || null,
            },

            {
                type: 2,
                title: "Gross Wages & Salaries",
                amount: inputField[`s5_gross_wages`] || null,
            },
            {
                type: 2,
                title: "Rent",
                amount: inputField[`s5_rent`] || null,
            },
            {
                type: 2,
                title: "Supplies",
                amount: inputField[`s5_supplies`] || null,
            },
            {
                type: 2,
                title: "Utilities/Telephone",
                amount: inputField[`s5_utilities_telephone`] || null,
            },
            {
                type: 2,
                title: "Vehicle Gasoline/Oil",
                amount: inputField[`s5_vehicle_gasoline_Oil`] || null,
            },
            {
                type: 2,
                title: "Repairs & Maintenance",
                amount: inputField[`s5_repairs_maintenance`] || null,
            },
            {
                type: 2,
                title: "Insurance",
                amount: inputField[`s5_insurance`] || null,
            },
            {
                type: 2,
                title: "Current Taxes5",
                amount: inputField[`s5_current_taxes`] || null,
            },
            {
                type: 2,
                title: "Other Expenses (Specify)",
                amount: inputField[`s5_other_expense_specify`] || null,
            },
            {
                type: 2,
                title: "IRS Use Only-Allowable Installment Payments",
                amount: inputField[`s5_irs_installment_payments`] || null,
            },
        ]
        let entityOther = '';
        if (inputField.s1_type_of_entity == 3) {
            let entityOther = inputField.s1_type_of_entity_other;
        } else if (inputField.s1_type_of_entity == 5) {
            let entityOther = inputField.s1_type_of_entity_other_llc;
        }

    
        const params: any = {
            year: searchParams.year['value'],
            month: searchParams.month['value'],
            // Section 1 Start
            business_name: inputField.s1_bussiness_name || null,
            business_telephone: inputField.s1_business_telephone_code && inputField.s1_business_telephone_number ? `${inputField.s1_business_telephone_code}${inputField.s1_business_telephone_number}`: null,
            type_of_business: inputField.s1_business_type || null,
            business_website: inputField.s1_bussiness_website || null,
            ein: inputField.s1_employer_identification_no || null,
            business_entity_type: inputField.s1_type_of_entity || null,
            entity_other: entityOther || null,
            incorporated_at: inputField.s1_date_incorporated || null,
            employee_count: inputField.s1_number_of_employees || null,
            monthly_gross_payroll: inputField.s1_monthly_gross_payroll || null,
            tax_deposit_frequency: inputField.s1_frequency_of_tax_deposits || null,
            is_enrolled_eftps: inputField.s1_federal_tax_payment_system || null,
            is_engage_ecommerce: inputField.s1_business_engage || null,
            credit_cards: creditCard || null,
            payment_processors: paymentProcessor || null,
            business_address: {
                address_line_1: inputField.s1_bussiness_street_address || null,
                entity_type: 1,
                address_line_2: inputField.s1_mailing_address || null,
                state: inputField.s1_state || null,
                zip: inputField.s1_zip || null,
                country: inputField.s1_county || null,
                city: inputField.s1_city || null,
            },
            // Section 1 end
            // Section 2 start
            business_personell_contacts: businessPersonellContact,
            // Section 2 end

            // Section 3 Start
            is_enabled_payroll_service_provider: inputField.s3_business_use_payroll || null,
            is_business_party_to_lawsuit: inputField.s3_business_party_lawsuit || null,
            is_bankruptcy_ever_filed: inputField.s3_business_ever || null,
            is_outstanding_amount_owned_by_party: inputField.s3_do_any_related_parties || null,
            is_asset_transferred_for_less_value: inputField.s3_have_any_assets_been_transferred || null,
            is_affliated_other_business: inputField.s3_have_other_business_affiliations || null,
            is_increase_decrease_anticipated: inputField.s3_any_increase_decrease_in_income || null,
            is_federal_government_contractor: inputField.s3_federal_government_contractor || null,
            financial_informations: {
                payroll_details: inputField.s3_name_address || null,
                payroll_effective_at: inputField.s3_effective_date || null,
                lawsuit_type: inputField.s3_plaintiff_defendant || null,
                lawsuit_location: inputField.s3_location_of_filing || null,
                lawsuit_represented_by: inputField.s3_represented_by || null,
                lawsuit_docket: inputField.s3_docket_case_no || null,
                lawsuit_amount: inputField.s3_amount_of_suit || null,
                lawsuit_possible_completion_at: inputField.s3_possible_completion_date || null,
                lawsuit_subject: inputField.s3_subject_of_suit || null,
                bankruptcy_filed_at: inputField.s3_date_filed || null,
                bankruptcy_dismissed_at: inputField.s3_date_dismissed || null,
                bankruptcy_discharged_at: inputField.s3_date_discharged || null,
                bankruptcy_petition_number: inputField.s3_petition_No || null,
                bankruptcy_district_of_filing: inputField.s3_district_of_filing || null,
                related_party_details: inputField.s3_name_and_address || null,
                related_party_date_of_loan: inputField.s3_date_of_loan || null,
                related_party_current_balance_amount: inputField.s3_current_balance_as_of_amount || null,
                related_party_current_balance_at: inputField.s3_current_balance_as_of_date || null,
                related_party_payment_at: inputField.s3_payment_date || null,
                related_party_payment_amount: inputField.s3_payment_amount || null,
                asset_list: inputField.s3_list_asset || null,
                asset_value_at_transfer_time: inputField.s3_value_at_time_of_transfer || null,
                asset_transferred_at: inputField.s3_date_transferred || null,
                asset_transfer_details: inputField.s3_to_Whom_or_where_transferred || null,
                other_business_details: inputField.s3_related_business_name_and_address || null,
                other_business_ein: inputField.s3_related_business_ein || null,
                anticipated_income_explain: inputField.s3_explain || null,
                anticipated_increase_or_decrease_amount: inputField.s3_how_much_will_it_increase_decrease || null,
                anticipated_increase_or_decrease_at: inputField.s3_when_will_it_increase_decrease || null,
            },
            // Section 3 End

            // Section 4 Start
            total_cash_in_hand_amount: inputField.s4_cash_on_hand || null,
            is_safe_exist_in_premises: inputField.s4_safe_business_premises || null,
            contents: inputField.s4_contents || null,

            bank_accounts_total_cash_amount: inputField.s4_business_bank_account_total_cash || null,

            notes_outstanding_total_cash_amount: inputField.s4_accounts_notes_receivable_outstanding_balance || null,
            investment_total_amount: inputField.s4_investments_total_investments || null,
            total_credit_available_amount: inputField.s4_available_credit_total_credit_available || null,
            properties_total_equity_amount: inputField.s4_real_property_total_equity || null,
            vehicles_total_equity_amount: inputField.s4_vehical_total_equity || null,
            equiments_total_equity_amount: inputField.s4_business_equipment_total_equity || null,
            business_liabilities_total_amount: inputField.s4_business_liailities_total_payment || null,

            business_bank_accounts_account_balance_at: inputField.s4_s4_business_bank_account_as_of || null,
            available_credits_owed_amount_at: inputField.s4_available_credit_as_of_amount_owed || null,
            available_credits_credit_at: inputField.s4_available_credit_as_of_available_credit || null,

            accounting_method_used: inputField.s5_accounting_method || null,
            income_from: inputField.s5_income_expense_date_from || null,
            income_to: inputField.s5_income_expense_date_to || null,
            total_income_amount: inputField.s5_total_income || null,
            total_expense_amount: inputField.s5_total_expenses || null,
            net_income_amount: inputField.s5_net_income || null,
            // // array data

            monthly_expenses: monthlyExpense || null,
            business_liabilities: businessLiabilitie || null,
            leased_purchased_vechiles: leasedPurchasedVechile || null,
            available_credits: availableCredit || null,
            business_bank_accounts: businessBankAccount || null,
            accounts_notes_receivables: accountsNotesReceivable || null,
            investments: investment || null,
            real_properties: realPropertie || null,
            business_equipments: businessEquiment || null,


        }



        // const params: any = {
        //     year: searchParams.year['value'],
        //     month: searchParams.month['value']
        // }

        if (isNotEmpty(record)) {
            // setShowLoader(true);
            // params['id'] = record['id'];
            updateFTTB(params, (message: string, resp: any) => {
                setShowLoader(false);
                toast.success(message)
                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        } else {
            setShowLoader(true);
            //console.log(params);
            createFTTB({params, branch_id: +branchData['id']}, (message: string, resp: any) => {


                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
                toast.success(message);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        }

        console.log(params);
        captureContentAndGeneratePDF();
        setShowLoader(false);
    }



    const fetchProfitLossList = () => {
        const params: any = {
            year: searchParams.year['value'],
            month: searchParams.month['value']
        }

        const MonthName = months[searchParams.month['value'] - 1];
        setSelectedMonthName(MonthName);
        setYear(searchParams.year['value']);
        setMonth(searchParams.month['value']);

        setIsLoading(true);
        getFTTB(params, (message: string, resp: any) => {
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data'] && resp['data']['data']['result']) {
                setRecord(resp.data.data.result);
            } else {
                setRecord([]);
            }

        }, (message: string, resp: any) => {
            setRecord([]);
            setIsLoading(false);
            toast.error(message);
        })
    }





    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'month') {
            setSearchParams({
                ...searchParams,
                'month': e
            })
        } else if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }
    const isNotEmpty = (arr: any) => {
        return Array.isArray(arr) && arr.length > 0;
    };
    const getLastDayOfMonth = (month: any, year: any) => {
        return new Date(year, month, 0).getDate();
    }

    const processData = (values: any) => {
        console.log(values);
        const params: any = {

        }
        if (isNotEmpty(record)) {
            // setShowLoader(true);
            // params['id'] = record['id'];
            updateFTTB(params, (message: string, resp: any) => {
                setShowLoader(false);
                toast.success(message)
                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        } else {
            setShowLoader(true);
            //console.log(params);
            createFTTB({params, branch_id: +branchData['id']}, (message: string, resp: any) => {


                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        }
    }
    function getStateNameByAbbreviation(abbreviation: any) {
        const state = STATES.find(state => state.abbreviation === abbreviation);
        return state ? state.state : 'State Not Found';
    }


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">FORM 433-B</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="filter-wrap">
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'month')}
                                        options={MONTHS}
                                        defaultValue={searchParams['month']}
                                        className={'zindex-10'}
                                    />
                                </div>
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchMonthlyIncome} className="generate-btn">Generate Form</button>
                                </div>
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <div style={{ padding: 0, margin: 0, textAlign: 'center' }}>


                        <form>
                            <div id="yourElementId">
                                {/* ref={captureRef} */}
                                <table cellPadding={0} cellSpacing={0} style={{ border: 0, margin: '0 auto', width: '980px', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '11px', lineHeight: '16px', color: '#000' }}>
                                    <tbody><tr>
                                        <td>
                                            <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                <tbody>
                                                    {/* page 1 start */}
                                                    <div ref={ref => pagesRefs.current[0] = ref} style={{ padding: '10px' }}>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ maxWidth: '150px', borderBottom: '2px solid #000', borderRight: '2px solid #000', textAlign: 'left', paddingBottom: '5px' }}>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Form <span style={{ fontSize: '24px', fontWeight: 'bold', color: '#000' }}>433-B</span>&nbsp;&nbsp;
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    ({selectedMonthName} {year})
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Department of the Treasury
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Internal Revenue Service
                                                                                </p>
                                                                            </td>
                                                                            <td style={{ fontSize: '18px', color: '#000', fontWeight: 600, textAlign: 'center', borderBottom: '2px solid #000' }}>
                                                                                Collection Information Statement for Businesses
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '11px', color: '#000', textAlign: 'left', padding: '5px 0' }}>
                                                                <strong>Note:</strong> <span style={{ fontStyle: 'italic' }}>Complete all entry spaces with the current data available or "N/A" (not applicable). Failure to complete all entry spaces may result in rejection of
                                                                    your request or significant delay in account resolution.</span>&nbsp;&nbsp; <strong>Include attachments if additional space is needed to respond completely to any question.</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#fff', textAlign: 'left', padding: '3px 0 3px 15px', background: '#000' }}>
                                                                Section 1: Business Information
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                            1a
                                                                        </td>
                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                            <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                Business Name
                                                                            </p>
                                                                            <input type="text" name="s1_bussiness_name" value={inputField.s1_bussiness_name || ''} onChange={inputsHandler} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                1b
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Business Street Address
                                                                                </p>
                                                                                <input type="text" name="s1_bussiness_street_address" onChange={inputsHandler} value={inputField.s1_bussiness_street_address || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Mailing Address</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_mailing_address" onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>City</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_city" value={inputField.s1_city || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>State</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_state" value={inputField.s1_state || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Zip</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_zip" value={inputField.s1_zip || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                1c
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Country</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_county" value={inputField.s1_county || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                1d
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Business Telephone (</span>
                                                                                    <input type="text" name="s1_business_telephone_code" value={inputField.s1_business_telephone_code || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_business_telephone_number" value={inputField.s1_business_telephone_number || ''} onChange={inputsHandler} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                1e
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Type of Business
                                                                                </p>
                                                                                <input type="text" name="s1_business_type" onChange={inputsHandler} value={inputField.s1_business_type || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                1f
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0, borderRight: '1px solid #000' }}>
                                                                                <p style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000' }}>
                                                                                    Business Website (web address)
                                                                                </p>
                                                                                <input type="text" name="s1_bussiness_website" onChange={inputsHandler} value={inputField.s1_bussiness_website || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                            2a
                                                                        </td>
                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>a Employer Identification No. (EIN)</span>&nbsp;&nbsp;
                                                                                <input type="text" name="s1_employer_identification_no" onChange={inputsHandler} value={inputField.s1_employer_identification_no || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                2b
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Type of entity <span style={{ fontStyle: 'italic' }}>(Check appropriate box below)</span>&nbsp;&nbsp;</span>&nbsp;&nbsp;
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>

                                                                                    <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s1_type_of_entity == 1} name="s1_type_of_entity" />
                                                                                    <span>Partnership</span>&nbsp;&nbsp;
                                                                                    <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s1_type_of_entity == 2} name="s1_type_of_entity" />
                                                                                    <span>Corporation</span>&nbsp;&nbsp;
                                                                                    <input type="radio" value="3" onChange={inputsHandler} checked={inputField.s1_type_of_entity === "3"} name="s1_type_of_entity" />
                                                                                    <span>Other</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_type_of_entity_other" onChange={inputsHandler} value={inputField.s1_type_of_entity_other || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="radio" onChange={inputsHandler} checked={inputField.s1_type_of_entity === "4"} value="4" name="s1_type_of_entity" />
                                                                                    <span>Limited Liability Company (LLC) classified as a corporation</span>&nbsp;&nbsp;
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="radio" onChange={inputsHandler} checked={inputField.s1_type_of_entity === "5"} value="5" name="s1_type_of_entity" />
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Other LLC - Include number of members</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_type_of_entity_other_llc" onChange={inputsHandler} value={inputField.s1_type_of_entity_other_llc || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '60%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                2c
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Date Incorporated/Established </span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_date_incorporated" onChange={inputsHandler} value={inputField.s1_date_incorporated || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ paddingLeft: '50%', fontStyle: 'italic' }}>mmddyyyy</span>&nbsp;&nbsp;
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} style={{ borderBottom: '1px solid #000' }} />
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                3a
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>a Number of Employees</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_number_of_employees" onChange={inputsHandler} value={inputField.s1_number_of_employees || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                3b
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Monthly Gross Payroll</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_monthly_gross_payroll" onChange={inputsHandler} value={inputField.s1_monthly_gross_payroll || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                3c
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Frequency of Tax Deposits</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s1_frequency_of_tax_deposits" onChange={inputsHandler} value={inputField.s1_frequency_of_tax_deposits || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                3d
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    Is the business enrolled in Electronic
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span>
                                                                                        Federal Tax Payment System (EFTPS)
                                                                                    </span>&nbsp;&nbsp;
                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                        <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s1_federal_tax_payment_system == 2} name="s1_federal_tax_payment_system" /> <strong> Yes</strong>
                                                                                    </span>&nbsp;&nbsp;
                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                        <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s1_federal_tax_payment_system == 1} name="s1_federal_tax_payment_system" /> <strong> No</strong>
                                                                                    </span>&nbsp;&nbsp;
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            4
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}>Does the business engage in e-Commerce (Internet sales) If yes, complete 5a and 5b.</span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s1_business_engage == 2} name="s1_business_engage" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s1_business_engage == 1} name="s1_business_engage" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                <strong>PAYMENT PROCESSOR</strong> <span style={{ fontStyle: 'italic' }}>(e.g., PayPal, Authorize.net, Google Checkout, etc.)</span>&nbsp;&nbsp; Include virtual currency wallet, exchange or digital currency exchange.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000' }}>&nbsp;</td>
                                                                        <td style={{ width: '70%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '27%', paddingLeft: '10px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            Payment Processor Account Number
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                                <strong>5a</strong>
                                                                            </td>
                                                                            <td style={{ width: '70%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s1_payment_name_address1" onChange={inputsHandler} value={inputField.s1_payment_name_address1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '27%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s1_payment_account_no1" onChange={inputsHandler} value={inputField.s1_payment_account_no1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'center' }} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                                <strong>5b</strong>
                                                                            </td>
                                                                            <td style={{ width: '70%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s1_payment_name_address2" onChange={inputsHandler} value={inputField.s1_payment_name_address2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '27%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s1_payment_account_no2" onChange={inputsHandler} value={inputField.s1_payment_account_no2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'center' }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', paddingLeft: '10px', paddingTop: '5px', borderBottom: '1px solid #000', textAlign: 'left', fontSize: '14px', fontWeight: 'bold', color: '#000', textTransform: 'uppercase' }}>
                                                                CREDIT CARDS ACCEPTED BY THE BUSINESS
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            <p style={{ padding: 0, margin: 0 }}> Type of Credit Card</p>
                                                                            <p style={{ padding: 0, margin: 0, fontStyle: 'italic' }}> (e.g., Visa, Mastercard, etc.) </p>
                                                                        </td>
                                                                        <td style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Merchant Account Number</td>
                                                                        <td style={{ width: '57%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Issuing Bank Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>6a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_type1" onChange={inputsHandler} value={inputField.s1_credit_card_type1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_merchant_account1" onChange={inputsHandler} value={inputField.s1_credit_card_merchant_account1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '57%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_issue_bank1" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Phone&nbsp;&nbsp;<input type="text" name="s1_credit_card_issue_bank_phone1" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank_phone1 || ''} style={{ width: '93%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>6b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_type2" onChange={inputsHandler} value={inputField.s1_credit_card_type2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_merchant_account2" onChange={inputsHandler} value={inputField.s1_credit_card_merchant_account2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '57%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_issue_bank2" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Phone&nbsp;&nbsp;<input type="text" name="s1_credit_card_issue_bank_phone2" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank_phone2 || ''} style={{ width: '93%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>6c</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_type3" onChange={inputsHandler} value={inputField.s1_credit_card_type3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_merchant_account3" onChange={inputsHandler} value={inputField.s1_credit_card_merchant_account3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '57%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s1_credit_card_issue_bank3" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Phone&nbsp;&nbsp;<input type="text" name="s1_credit_card_issue_bank_phone3" onChange={inputsHandler} value={inputField.s1_credit_card_issue_bank_phone3 || ''} style={{ width: '93%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#fff', textAlign: 'left', padding: '3px 0 3px 15px', background: '#000' }}>
                                                                Section 2: Business Personnel and Contacts
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#000', textAlign: 'left', padding: '3px 0 3px 15px', borderBottom: '1px solid #000' }}>
                                                                PARTNERS, OFFICERS, LLC MEMBERS, MAJOR SHAREHOLDERS (Foreign and Domestic), ETC.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} valign="top" style={{ width: '100%', borderBottom: '1px solid #000' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingRight: '10px' }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                                7a
                                                                                            </td>
                                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Full Name</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_full_name1" onChange={inputsHandler} value={inputField.s2_full_name1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Title</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_title1" onChange={inputsHandler} value={inputField.s2_title1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Address</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_home_address1" onChange={inputsHandler} value={inputField.s2_home_address1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>City</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_city1" onChange={inputsHandler} value={inputField.s2_city1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>State</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_state1" onChange={inputsHandler} value={inputField.s2_state1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Zip</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_zip1" onChange={inputsHandler} value={inputField.s2_zip1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span>
                                                                                                        Responsible for Depositing Payroll Taxes
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s2_responsible1 == 2} name="s2_responsible1" /> <strong> Yes</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s2_responsible1 == 1} name="s2_responsible1" /> <strong> No</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingLeft: '10px' }}>
                                                                                    <tbody><tr>
                                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Taxpayer Identification Number</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_taxpayer_number1" onChange={inputsHandler} value={inputField.s2_taxpayer_number1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Telephone (</span>
                                                                                                <input type="text" name="s2_home_telephone_code1" onChange={inputsHandler} value={inputField.s2_home_telephone_code1 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_home_telephone_number1" onChange={inputsHandler} value={inputField.s2_home_telephone_number1 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Work/Cell Phone (</span>
                                                                                                <input type="text" name="s2_work_cell_code1" onChange={inputsHandler} value={inputField.s2_work_cell_code1 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_work_cell_number1" onChange={inputsHandler} value={inputField.s2_work_cell_number1 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Ownership Percentage &amp; Shares or Interest</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_ownership_percentage1" onChange={inputsHandler} value={inputField.s2_ownership_percentage1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Annual Salary/Draw</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_annual_salary_draw1" onChange={inputsHandler} value={inputField.s2_annual_salary_draw1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingRight: '10px' }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                                7b
                                                                                            </td>
                                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Full Name</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_full_name2" onChange={inputsHandler} value={inputField.s2_full_name2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Title</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_title2" onChange={inputsHandler} value={inputField.s2_title2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Address</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_home_address2" onChange={inputsHandler} value={inputField.s2_home_address2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>City</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_city2" onChange={inputsHandler} value={inputField.s2_city2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>State</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_state2" onChange={inputsHandler} value={inputField.s2_state2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Zip</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_zip2" onChange={inputsHandler} value={inputField.s2_zip2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span>
                                                                                                        Responsible for Depositing Payroll Taxes
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s2_responsible2 == 2} name="s2_responsible2" /> <strong> Yes</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s2_responsible2 == 1} name="s2_responsible2" /> <strong> No</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingLeft: '10px' }}>
                                                                                    <tbody><tr>
                                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Taxpayer Identification Number</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_taxpayer_number2" onChange={inputsHandler} value={inputField.s2_taxpayer_number2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Telephone (</span>
                                                                                                <input type="text" name="s2_home_telephone_code2" onChange={inputsHandler} value={inputField.s2_home_telephone_code2 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_home_telephone_number2" onChange={inputsHandler} value={inputField.s2_home_telephone_number2 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Work/Cell Phone (</span>
                                                                                                <input type="text" name="s2_work_cell_code2" onChange={inputsHandler} value={inputField.s2_work_cell_code2 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_work_cell_number2" onChange={inputsHandler} value={inputField.s2_work_cell_number2 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Ownership Percentage &amp; Shares or Interest</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_ownership_percentage2" onChange={inputsHandler} value={inputField.s2_ownership_percentage2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Annual Salary/Draw</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_annual_salary_draw2" onChange={inputsHandler} value={inputField.s2_annual_salary_draw2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingRight: '10px' }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                                7c
                                                                                            </td>
                                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Full Name</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_full_name3" onChange={inputsHandler} value={inputField.s2_full_name3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Title</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_title3" onChange={inputsHandler} value={inputField.s2_title3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Address</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_home_address3" onChange={inputsHandler} value={inputField.s2_home_address3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>City</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_city3" onChange={inputsHandler} value={inputField.s2_city3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>State</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_state3" onChange={inputsHandler} value={inputField.s2_state3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Zip</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_zip3" onChange={inputsHandler} value={inputField.s2_zip3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span>
                                                                                                        Responsible for Depositing Payroll Taxes
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s2_responsible3 == 2} name="s2_responsible3" /> <strong> Yes</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s2_responsible3 == 1} name="s2_responsible3" /> <strong> No</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingLeft: '10px' }}>
                                                                                    <tbody><tr>
                                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Taxpayer Identification Number</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_taxpayer_number3" onChange={inputsHandler} value={inputField.s2_taxpayer_number3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Telephone (</span>
                                                                                                <input type="text" name="s2_home_telephone_code3" onChange={inputsHandler} value={inputField.s2_home_telephone_code3 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_home_telephone_number3" onChange={inputsHandler} value={inputField.s2_home_telephone_number3 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Work/Cell Phone (</span>
                                                                                                <input type="text" name="s2_work_cell_code3" onChange={inputsHandler} value={inputField.s2_work_cell_code3 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_work_cell_number3" onChange={inputsHandler} value={inputField.s2_work_cell_number3 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Ownership Percentage &amp; Shares or Interest</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_ownership_percentage3" onChange={inputsHandler} value={inputField.s2_ownership_percentage3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Annual Salary/Draw</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_annual_salary_draw3" onChange={inputsHandler} value={inputField.s2_annual_salary_draw3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingRight: '10px' }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td valign="top" style={{ width: '30px', fontWeight: 'bold', fontSize: '12px' }}>
                                                                                                7d
                                                                                            </td>
                                                                                            <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Full Name</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_full_name4" onChange={inputsHandler} value={inputField.s2_full_name4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Title</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_title4" onChange={inputsHandler} value={inputField.s2_title4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Address</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_home_address4" onChange={inputsHandler} value={inputField.s2_home_address4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>City</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_city4" onChange={inputsHandler} value={inputField.s2_city4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>State</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_state4" onChange={inputsHandler} value={inputField.s2_state4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Zip</span>&nbsp;&nbsp;
                                                                                                    <input type="text" name="s2_zip4" onChange={inputsHandler} value={inputField.s2_zip4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '30%' }} />
                                                                                                </p>
                                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                    <span>
                                                                                                        Responsible for Depositing Payroll Taxes
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s2_responsible4 == 2} name="s2_responsible4" /> <strong> Yes</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                    <span style={{ width: '100px', textAlign: 'center' }}>
                                                                                                        <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s2_responsible4 == 1} name="s2_responsible4" /> <strong> No</strong>
                                                                                                    </span>&nbsp;&nbsp;
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000' }}>
                                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0, paddingLeft: '10px' }}>
                                                                                    <tbody><tr>
                                                                                        <td style={{ textAlign: 'left', padding: 0, margin: 0 }}>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Taxpayer Identification Number</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_taxpayer_number4" onChange={inputsHandler} value={inputField.s2_taxpayer_number4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Home Telephone (</span>
                                                                                                <input type="text" name="s2_home_telephone_code4" onChange={inputsHandler} value={inputField.s2_home_telephone_code4 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_home_telephone_number4" onChange={inputsHandler} value={inputField.s2_home_telephone_number4 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Work/Cell Phone (</span>
                                                                                                <input type="text" name="s2_work_cell_code4" onChange={inputsHandler} value={inputField.s2_work_cell_code4 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '20%' }} />
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', whiteSpace: 'nowrap' }}>)</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_work_cell_number4" onChange={inputsHandler} value={inputField.s2_work_cell_number4 || ''} style={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '70%' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Ownership Percentage &amp; Shares or Interest</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_ownership_percentage4" onChange={inputsHandler} value={inputField.s2_ownership_percentage4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                                <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>Annual Salary/Draw</span>&nbsp;&nbsp;
                                                                                                <input type="text" name="s2_annual_salary_draw4" onChange={inputsHandler} value={inputField.s2_annual_salary_draw4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000' }} />
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>



                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                    </div>
                                                    {/* page 1 end*/}
                                                    {/* page 2 start*/}
                                                    <div ref={ref => pagesRefs.current[1] = ref} style={{ padding: '10px' }}>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#fff', textAlign: 'left', padding: '3px 0 3px 15px', background: '#000' }}>
                                                                Section 3: Other Financial Information <span style={{ fontStyle: 'italic' }}>(Attach copies of all applicable documents)</span>&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            8
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Does the business use a Payroll Service Provider or Reporting Agent</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_business_use_payroll == 2} name="s3_business_use_payroll" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_business_use_payroll == 1} name="s3_business_use_payroll" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px' }}>&nbsp;</td>
                                                                        <td style={{ width: '70%', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '27%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                            Effective dates <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '70%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_name_address" onChange={inputsHandler} value={inputField.s3_name_address || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '27%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_effective_date" onChange={inputsHandler} value={inputField.s3_effective_date || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'center' }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            9
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Is the business a party to a lawsuit</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_business_party_lawsuit == 2} name="s3_business_party_lawsuit" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_business_party_lawsuit == 1} name="s3_business_party_lawsuit" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                &nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Location of Filing
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Represented by
                                                                            </td>
                                                                            <td style={{ width: '22%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                Docket/Case No.
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <span style={{ width: '150px' }}>
                                                                                    <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_plaintiff_defendant == 1} name="s3_plaintiff_defendant" /> Plaintiff
                                                                                </span>&nbsp;&nbsp;
                                                                                <span>
                                                                                    <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_plaintiff_defendant == 2} name="s3_plaintiff_defendant" /> Defendant
                                                                                </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_location_of_filing" onChange={inputsHandler} value={inputField.s3_location_of_filing || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_represented_by" onChange={inputsHandler} value={inputField.s3_represented_by || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '22%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_docket_case_no" onChange={inputsHandler} value={inputField.s3_docket_case_no || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Amount of Suit
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Possible Completion Date (mmddyyyy)
                                                                            </td>
                                                                            <td colSpan={2} style={{ width: '47%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                Subject of Suit
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s3_amount_of_suit" onChange={inputsHandler} value={inputField.s3_amount_of_suit || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_possible_completion_date" onChange={inputsHandler} value={inputField.s3_possible_completion_date || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td colSpan={2} style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_subject_of_suit" onChange={inputsHandler} value={inputField.s3_subject_of_suit || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            10
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Has the business ever filed bankruptcy</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_business_ever == 2} name="s3_business_ever" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_business_ever == 1} name="s3_business_ever" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Date Filed <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Date Dismissed <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Date Discharged <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '10%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Petition No.
                                                                            </td>
                                                                            <td style={{ width: '12%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                District of Filing
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_date_filed" onChange={inputsHandler} value={inputField.s3_date_filed || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_date_dismissed" onChange={inputsHandler} value={inputField.s3_date_dismissed || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_date_discharged" onChange={inputsHandler} value={inputField.s3_date_discharged || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '10%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_petition_No" onChange={inputsHandler} value={inputField.s3_petition_No || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '12%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_district_of_filing" onChange={inputsHandler} value={inputField.s3_district_of_filing || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            11
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Do any related parties (e.g., officers, partners, employees) have outstanding amounts owed to the business</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_do_any_related_parties == 2} name="s3_do_any_related_parties" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_do_any_related_parties == 1} name="s3_do_any_related_parties" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '35%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '10%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Date of Loan
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Current Balance As of
                                                                            </td>
                                                                            <td style={{ width: '10%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Payment Date
                                                                            </td>
                                                                            <td style={{ width: '15%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                Payment Amount
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '35%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_name_and_address" onChange={inputsHandler} value={inputField.s3_name_and_address || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '10%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_date_of_loan" onChange={inputsHandler} value={inputField.s3_date_of_loan || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s3_current_balance_as_of_date" onChange={inputsHandler} value={inputField.s3_current_balance_as_of_date || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap', paddingRight: '10px' }}>mmddyyyy</span>&nbsp;&nbsp;
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap', paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s3_current_balance_as_of_amount" onChange={inputsHandler} value={inputField.s3_current_balance_as_of_amount || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                            <td style={{ width: '10%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_payment_date" onChange={inputsHandler} value={inputField.s3_payment_date || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_payment_amount" onChange={inputsHandler} value={inputField.s3_payment_amount || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            12
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Have any assets been transferred, in the last 10 years, from this business for less than full value</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_have_any_assets_been_transferred == 2} name="s3_have_any_assets_been_transferred" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_have_any_assets_been_transferred == 1} name="s3_have_any_assets_been_transferred" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '35%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                List Asset
                                                                            </td>
                                                                            <td style={{ width: '20%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Value at Time of Transfer
                                                                            </td>
                                                                            <td style={{ width: '20%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Date Transferred <span style={{ fontStyle: 'italic' }}>(mmddyyyy) </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '20%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                To Whom or Where Transferred
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '35%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_list_asset" onChange={inputsHandler} value={inputField.s3_list_asset || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '20%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap', paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s3_value_at_time_of_transfer" onChange={inputsHandler} value={inputField.s3_value_at_time_of_transfer || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                            <td style={{ width: '20%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_date_transferred" onChange={inputsHandler} value={inputField.s3_date_transferred || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '20%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_to_Whom_or_where_transferred" onChange={inputsHandler} value={inputField.s3_to_Whom_or_where_transferred || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            13
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Does this business have other business affiliations (e.g., subsidiary or parent companies)</strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_have_other_business_affiliations == 2} name="s3_have_other_business_affiliations" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_have_other_business_affiliations == 1} name="s3_have_other_business_affiliations" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '75%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Related Business Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '20%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                Related Business EIN:
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '75%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_related_business_name_and_address" onChange={inputsHandler} value={inputField.s3_related_business_name_and_address || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '20%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_related_business_ein" onChange={inputsHandler} value={inputField.s3_related_business_ein || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            14
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Any increase/decrease in income anticipated </strong> <span style={{ fontStyle: 'italic' }}>(If yes, answer the following)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_any_increase_decrease_in_income == 2} name="s3_any_increase_decrease_in_income" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_any_increase_decrease_in_income == 1} name="s3_any_increase_decrease_in_income" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px' }}>&nbsp;</td>
                                                                            <td style={{ width: '50%', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                Explain  <span style={{ fontStyle: 'italic' }}>(Use attachment if needed)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', paddingLeft: '10px', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                How much will it increase/decrease
                                                                            </td>
                                                                            <td style={{ width: '20%', paddingLeft: '10px', textAlign: 'left' }}>
                                                                                When will it increase/decrease
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                            </td>
                                                                            <td style={{ width: '50%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_explain" onChange={inputsHandler} value={inputField.s3_explain || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap', paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s3_how_much_will_it_increase_decrease" onChange={inputsHandler} value={inputField.s3_how_much_will_it_increase_decrease || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                            <td style={{ width: '20%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <input type="text" name="s3_when_will_it_increase_decrease" onChange={inputsHandler} value={inputField.s3_when_will_it_increase_decrease || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', fontWeight: 'bold', fontSize: '12px', borderBottom: '1px solid #000' }}>
                                                                            15
                                                                        </td>
                                                                        <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left', display: 'inline-flex' }}>
                                                                            <span style={{ fontSize: '11px', color: '#000', width: '72%' }}><strong>Is the business a Federal Government Contractor</strong> <span style={{ fontStyle: 'italic' }}>(Include Federal Government contracts in #18, Accounts/Notes Receivable)</span>&nbsp;&nbsp; </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}>
                                                                                <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s3_federal_government_contractor == 2} name="s3_federal_government_contractor" /> <strong>Yes</strong>
                                                                            </span>&nbsp;&nbsp;
                                                                            <span style={{ width: '10%', textAlign: 'center' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s3_federal_government_contractor == 1} name="s3_federal_government_contractor" /> <strong>No</strong> </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#fff', textAlign: 'left', padding: '3px 0 3px 15px', background: '#000' }}>
                                                                Section 4: Business Asset and Liability Information (Foreign and Domestic)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                                <strong>16a</strong>
                                                                            </td>
                                                                            <td style={{ width: '52%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>CASH ON HAND</strong> <span style={{ fontStyle: 'italic' }}>Include cash that is not in the bank</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '25%', borderRight: '1px solid #000', paddingRight: '10px', borderBottom: '1px solid #000', textAlign: 'right' }}>
                                                                                <strong>Total Cash on Hand</strong>
                                                                            </td>
                                                                            <td style={{ width: '20%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap', paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_cash_on_hand" onChange={inputsHandler} value={inputField.s4_cash_on_hand || ''} style={{ width: '100%', padding: 0, textAlign: 'left', margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                                <strong>16b</strong>
                                                                            </td>
                                                                            <td style={{ width: '47%', borderRight: '1px solid #000', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Is there a safe on the business premises </strong> <span style={{ width: '80px', textAlign: 'center' }}>
                                                                                    <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s4_safe_business_premises == 2} name="s4_safe_business_premises" /> <strong>Yes</strong>
                                                                                </span>&nbsp;&nbsp;
                                                                                <span style={{ width: '80px', textAlign: 'center' }}>
                                                                                    <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s4_safe_business_premises == 1} name="s4_safe_business_premises" /> <strong>No</strong>
                                                                                </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '40%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Contents
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s4_contents" onChange={inputsHandler} value={inputField.s4_contents || ''} style={{ width: '100%', padding: 0, textAlign: 'left', margin: 0, backgroundColor: '#f1f4ff', border: 0 }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '30px', height: '25px', borderBottom: '1px solid #000' }}>
                                                                                <strong />
                                                                            </td>
                                                                            <td style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>BUSINESS BANK ACOUNTS</strong> Include online and mobile accounts <span style={{ fontStyle: 'italic' }}>(e.g., PayPal)</span>&nbsp;&nbsp;, money market accounts, savings accounts, checking accounts
                                                                                and stored value cards <span style={{ fontStyle: 'italic' }}>(e.g., payroll cards, government benefit cards, etc.)</span>&nbsp;&nbsp;
                                                                                <p style={{ padding: 0, margin: 0 }}>
                                                                                    List safe deposit boxes including location, box number and value of contents. Attach list of contents.
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            <p style={{ padding: 0, margin: 0 }}> Type of</p>
                                                                            <p style={{ padding: 0, margin: 0 }}> Account </p>
                                                                        </td>
                                                                        <td style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Full Name and Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; of
                                                                            Bank, Savings &amp; Loan, Credit Union or Financial Institution
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Account Number
                                                                        </td>
                                                                        <td style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Account Balance
                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                <span style={{ padding: '0 0 0 10px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>As of</span>&nbsp;&nbsp;
                                                                                <input type="text" name="s4_s4_business_bank_account_as_of" onChange={inputsHandler} value={inputField.s4_s4_business_bank_account_as_of || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                            </p>
                                                                            <p style={{ textAlign: 'left', padding: '0 0 0 40px', margin: 0, display: 'inline-flex', width: '100%', fontStyle: 'italic' }}>mmddyyyy</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>17a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_type_of_account1" onChange={inputsHandler} value={inputField.s4_business_bank_account_type_of_account1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_name_addess1" onChange={inputsHandler} value={inputField.s4_business_bank_account_name_addess1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_ac_number1" onChange={inputsHandler} value={inputField.s4_business_bank_account_ac_number1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_bank_account_amount1" onChange={inputsHandler} value={inputField.s4_business_bank_account_amount1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>17b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_type_of_account2" onChange={inputsHandler} value={inputField.s4_business_bank_account_type_of_account2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_name_addess2" onChange={inputsHandler} value={inputField.s4_business_bank_account_name_addess2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_ac_number2" onChange={inputsHandler} value={inputField.s4_business_bank_account_ac_number2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_bank_account_amount2" onChange={inputsHandler} value={inputField.s4_business_bank_account_amount2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>17c</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '20%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_type_of_account3" onChange={inputsHandler} value={inputField.s4_business_bank_account_type_of_account3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_name_addess3" onChange={inputsHandler} value={inputField.s4_business_bank_account_name_addess3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_bank_account_ac_number3" onChange={inputsHandler} value={inputField.s4_business_bank_account_ac_number3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_bank_account_amount3" onChange={inputsHandler} value={inputField.s4_business_bank_account_amount3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>17d</strong>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '80%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Total Cash in Banks</strong> <span className="font-style:italic;">(Add lines 17a through 17c and amounts from any attachments)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_bank_account_total_cash" onChange={inputsHandler} value={inputField.s4_business_bank_account_total_cash || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    {/* page 2 end */}
                                                    {/* page 3 start*/}
                                                    <div ref={ref => pagesRefs.current[2] = ref} style={{ padding: '10px' }}>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>ACCOUNTS/NOTES RECEIVABLE</strong> Include e-payment accounts receivable and factoring companies, and any bartering or online auction accounts.
                                                                <span style={{ fontStyle: 'italic' }}>(List all contracts separately including contracts awarded, but not started)</span>&nbsp;&nbsp;. <strong>Include Federal, state and local government grants and contracts.</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Name &amp; Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Status <span style={{ fontStyle: 'italic' }}>(e.g., age,factored, other)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            <p style={{ padding: 0, margin: 0 }}>Date Due</p>
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Invoice Number or Government
                                                                            Grant or Contract Number
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Amount Due
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '37%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_accounts_notes_receivable_address1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_address1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Contact Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_contact_name1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_contact_name1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_phone1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_phone1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_status1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_status1 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_date_due1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_date_due1 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_invoice_number1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_invoice_number1 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_amount_due1" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_amount_due1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '75px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '37%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_accounts_notes_receivable_address2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_address2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Contact Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_contact_name2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_contact_name2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_phone2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_phone2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_status2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_status2 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_date_due2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_date_due2 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_invoice_number2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_invoice_number2 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_amount_due2" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_amount_due2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '75px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18c</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '37%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_accounts_notes_receivable_address3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_address3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Contact Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_contact_name3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_contact_name3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_phone3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_phone3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_status3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_status3 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_date_due3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_date_due3 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_invoice_number3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_invoice_number3 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_amount_due3" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_amount_due3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '75px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18d</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '37%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_accounts_notes_receivable_address4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_address4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Contact Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_contact_name4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_contact_name4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_phone4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_phone4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_status4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_status4 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_date_due4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_date_due4 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_invoice_number4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_invoice_number4 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_amount_due4" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_amount_due4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '75px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18e</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '37%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_accounts_notes_receivable_address5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_address5 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Contact Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_contact_name5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_contact_name5 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_phone5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_phone5 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_status5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_status5 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} /></td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_date_due5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_date_due5 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_accounts_notes_receivable_invoice_number5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_invoice_number5 || ''} style={{ width: '100%', height: '75px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_amount_due5" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_amount_due5 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '75px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>18f</strong>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '82%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Outstanding Balance</strong> <span style={{ fontStyle: 'italic' }}>(Add lines 18a through 18e and amounts from any attachments) </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_accounts_notes_receivable_outstanding_balance" onChange={inputsHandler} value={inputField.s4_accounts_notes_receivable_outstanding_balance || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>INVESTMENTS</strong> List all investment assets below. Include stocks, bonds, mutual funds, stock options, certificates of deposit, commodities (e.g.,
                                                                gold, silver, copper, etc.) and virtual currency (e.g., Bitcoin, Ripple and Litecoin).
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '40%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Name of Company &amp; Address<span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Used as collateral on loan
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Value
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Loan Balance
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            <p style={{ textAlign: 'center', padding: 0, margin: 0, width: '100%' }}><strong>Equity</strong></p>
                                                                            Value Minus Loan
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>19a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '40%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_investments_company_address1" onChange={inputsHandler} value={inputField.s4_investments_company_address1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_company_name1" onChange={inputsHandler} value={inputField.s4_investments_company_name1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <span style={{ width: '50px' }}><input type="radio" value="2" onChange={inputsHandler} checked={inputField.s4_investments_used_as_collateral_on_loan1 == 2} name="s4_investments_used_as_collateral_on_loan1" /> <strong>Yes</strong></span>&nbsp;&nbsp;
                                                                                <span style={{ width: '50px' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s4_investments_used_as_collateral_on_loan1 == 1} name="s4_investments_used_as_collateral_on_loan1" /> <strong>No</strong></span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_current_value1" onChange={inputsHandler} value={inputField.s4_investments_current_value1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_loan_balance1" onChange={inputsHandler} value={inputField.s4_investments_loan_balance1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_equity1" onChange={inputsHandler} value={inputField.s4_investments_equity1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>19b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '40%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_investments_company_address2" onChange={inputsHandler} value={inputField.s4_investments_company_address2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_company_name2" onChange={inputsHandler} value={inputField.s4_investments_company_name2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <span style={{ width: '50px' }}><input type="radio" value="2" onChange={inputsHandler} checked={inputField.s4_investments_used_as_collateral_on_loan2 == 2} name="s4_investments_used_as_collateral_on_loan2" /> <strong>Yes</strong></span>&nbsp;&nbsp;
                                                                                <span style={{ width: '50px' }}><input type="radio" value="1" onChange={inputsHandler} checked={inputField.s4_investments_used_as_collateral_on_loan2 == 1} name="s4_investments_used_as_collateral_on_loan2" /> <strong>No</strong></span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_current_value2" onChange={inputsHandler} value={inputField.s4_investments_current_value2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_loan_balance2" onChange={inputsHandler} value={inputField.s4_investments_loan_balance2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_equity2" onChange={inputsHandler} value={inputField.s4_investments_equity2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>19c</strong>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '82%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Total Investments</strong> <span style={{ fontStyle: 'italic' }}> (Add lines 19a, 19b, and amounts from any attachments)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_investments_total_investments" onChange={inputsHandler} value={inputField.s4_investments_total_investments || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>AVAILABLE CREDIT</strong> Include all lines of credit and credit cards.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Full Name &amp; Address <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Credit Limit
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Amount Owed
                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                <span style={{ padding: '0 0 0 10px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>As of</span>&nbsp;&nbsp;
                                                                                <input type="text" name="s4_available_credit_as_of_amount_owed" onChange={inputsHandler} value={inputField.s4_available_credit_as_of_amount_owed || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                            </p>
                                                                            <p style={{ textAlign: 'left', padding: '0 0 0 40px', margin: 0, display: 'inline-flex', width: '100%', fontStyle: 'italic' }}>mmddyyyy</p>
                                                                        </td>
                                                                        <td style={{ width: '17%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Available Credit
                                                                            <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                <span style={{ padding: '0 0 0 10px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>As of</span>&nbsp;&nbsp;
                                                                                <input type="text" name="s4_available_credit_as_of_available_credit" onChange={inputsHandler} value={inputField.s4_available_credit_as_of_available_credit || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, borderBottom: '1px solid #000', width: '100%' }} />
                                                                            </p>
                                                                            <p style={{ textAlign: 'left', padding: '0 0 0 40px', margin: 0, display: 'inline-flex', width: '100%', fontStyle: 'italic' }}>mmddyyyy</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>20a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_available_credit_name_address1" onChange={inputsHandler} value={inputField.s4_available_credit_name_address1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Account No.</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_account_no1" onChange={inputsHandler} value={inputField.s4_available_credit_account_no1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_credit_limit1" onChange={inputsHandler} value={inputField.s4_available_credit_credit_limit1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_amount_owed1" onChange={inputsHandler} value={inputField.s4_available_credit_amount_owed1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_available_credit1" onChange={inputsHandler} value={inputField.s4_available_credit_available_credit1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>20b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_available_credit_name_address2" onChange={inputsHandler} value={inputField.s4_available_credit_name_address2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span>Account No.</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_account_no2" onChange={inputsHandler} value={inputField.s4_available_credit_account_no2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_credit_limit2" onChange={inputsHandler} value={inputField.s4_available_credit_credit_limit2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_amount_owed2" onChange={inputsHandler} value={inputField.s4_available_credit_amount_owed2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_available_credit2" onChange={inputsHandler} value={inputField.s4_available_credit_available_credit2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>20c</strong>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '80%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Total Credit Available</strong> <span className="font-style:italic;"> (Add lines 20a, 20b, and amounts from any attachments)</span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_available_credit_total_credit_available" onChange={inputsHandler} value={inputField.s4_available_credit_total_credit_available || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    {/* page 3 end*/}
                                                    {/* page 4 start*/}
                                                    <div ref={ref => pagesRefs.current[3] = ref} style={{ padding: '10px' }}>

                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>REAL PROPERTY</strong> Include all real property and land contracts the business owns/leases/rents.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        {/* <td style="width:30px;  border-bottom: 1px solid #000; text-align: left;"></td> */}
                                                                        <td colSpan={2} style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            &nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Purchase/Lease Date
                                                                            (mmddyyyy)
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Fair
                                                                            Market Value
                                                                            (FMV)
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Loan
                                                                            Balance
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Amount of
                                                                            Monthly
                                                                            Payment
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Date of Final
                                                                            Payment
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            <strong>Equity</strong>
                                                                            FMV Minus Loan
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>21a </strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>Property Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_property_description1" onChange={inputsHandler} value={inputField.s4_real_property_property_description1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_current_value1" onChange={inputsHandler} value={inputField.s4_real_property_current_value1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_fair1" onChange={inputsHandler} value={inputField.s4_real_property_current_fair1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_loan_balance1" onChange={inputsHandler} value={inputField.s4_real_property_current_loan_balance1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_amount_of_monthly_payment1" onChange={inputsHandler} value={inputField.s4_real_property_amount_of_monthly_payment1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_date_of_final1" onChange={inputsHandler} value={inputField.s4_real_property_date_of_final1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_equity_fmv_minus_loan1" onChange={inputsHandler} value={inputField.s4_real_property_equity_fmv_minus_loan1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_location1" onChange={inputsHandler} value={inputField.s4_real_property_location1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor/Landlord Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_name_address1" onChange={inputsHandler} value={inputField.s4_real_property_name_address1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>21b </strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>Property Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_property_description2" onChange={inputsHandler} value={inputField.s4_real_property_property_description2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_current_value2" onChange={inputsHandler} value={inputField.s4_real_property_current_value2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_fair2" onChange={inputsHandler} value={inputField.s4_real_property_current_fair2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_loan_balance2" onChange={inputsHandler} value={inputField.s4_real_property_current_loan_balance2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_amount_of_monthly_payment2" onChange={inputsHandler} value={inputField.s4_real_property_amount_of_monthly_payment2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_date_of_final2" onChange={inputsHandler} value={inputField.s4_real_property_date_of_final2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_equity_fmv_minus_loan2" onChange={inputsHandler} value={inputField.s4_real_property_equity_fmv_minus_loan2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_location2" onChange={inputsHandler} value={inputField.s4_real_property_location2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor/Landlord Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_name_address2" onChange={inputsHandler} value={inputField.s4_real_property_name_address2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>21c </strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>Property Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_property_description3" onChange={inputsHandler} value={inputField.s4_real_property_property_description3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_current_value3" onChange={inputsHandler} value={inputField.s4_real_property_current_value3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_fair3" onChange={inputsHandler} value={inputField.s4_real_property_current_fair3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_loan_balance3" onChange={inputsHandler} value={inputField.s4_real_property_current_loan_balance3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_amount_of_monthly_payment3" onChange={inputsHandler} value={inputField.s4_real_property_amount_of_monthly_payment3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_date_of_final3" onChange={inputsHandler} value={inputField.s4_real_property_date_of_final3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_equity_fmv_minus_loan3" onChange={inputsHandler} value={inputField.s4_real_property_equity_fmv_minus_loan3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_location3" onChange={inputsHandler} value={inputField.s4_real_property_location3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor/Landlord Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_name_address3" onChange={inputsHandler} value={inputField.s4_real_property_name_address3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>21d </strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>Property Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_property_description4" onChange={inputsHandler} value={inputField.s4_real_property_property_description4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_current_value4" onChange={inputsHandler} value={inputField.s4_real_property_current_value4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_fair4" onChange={inputsHandler} value={inputField.s4_real_property_current_fair4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_current_loan_balance4" onChange={inputsHandler} value={inputField.s4_real_property_current_loan_balance4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_amount_of_monthly_payment4" onChange={inputsHandler} value={inputField.s4_real_property_amount_of_monthly_payment4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_real_property_date_of_final4" onChange={inputsHandler} value={inputField.s4_real_property_date_of_final4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_equity_fmv_minus_loan4" onChange={inputsHandler} value={inputField.s4_real_property_equity_fmv_minus_loan4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={3} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_real_property_location4" onChange={inputsHandler} value={inputField.s4_real_property_location4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={4} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor/Landlord Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_name_address4" onChange={inputsHandler} value={inputField.s4_real_property_name_address4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>21e</strong>
                                                                            </td>
                                                                            <td colSpan={5} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    <strong> Total Equity</strong><span style={{ fontStyle: 'italic' }}>(Add lines 21a through 21d and amounts from any attachments)</span>&nbsp;&nbsp;
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_real_property_total_equity" onChange={inputsHandler} value={inputField.s4_real_property_total_equity || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>VEHICLES, LEASED AND PURCHASED </strong> Include boats, RVs, motorcycles, all-terrain and off-road vehicles, trailers, mobile homes, etc.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td colSpan={2} style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            &nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Purchase/
                                                                            Lease Date
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Fair
                                                                            Market Value
                                                                            (FMV)
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Loan
                                                                            Balance
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Amount of
                                                                            Monthly
                                                                            Payment
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Date of Final
                                                                            Payment
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            <strong>Equity</strong>
                                                                            FMV Minus Loan
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>22a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Year</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_year1" onChange={inputsHandler} value={inputField.s4_vehical_year1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Make/Model</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_make_model1" onChange={inputsHandler} value={inputField.s4_vehical_make_model1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_purchase_date1" onChange={inputsHandler} value={inputField.s4_vehical_purchase_date1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_fair1" onChange={inputsHandler} value={inputField.s4_vehical_current_fair1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_loan1" onChange={inputsHandler} value={inputField.s4_vehical_current_loan1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_amount_of_monthly_payment1" onChange={inputsHandler} value={inputField.s4_vehical_amount_of_monthly_payment1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_date_of_final_payment1" onChange={inputsHandler} value={inputField.s4_vehical_date_of_final_payment1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_equityfmv_minus_loan1" onChange={inputsHandler} value={inputField.s4_vehical_equityfmv_minus_loan1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Mileage
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_mileage1" onChange={inputsHandler} value={inputField.s4_vehical_mileage1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    License/Tag Number
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_license_tag_number1" onChange={inputsHandler} value={inputField.s4_vehical_license_tag_number1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} rowSpan={2} valign="top" style={{ width: '65%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_name_address1" onChange={inputsHandler} value={inputField.s4_vehical_name_address1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_phone1" onChange={inputsHandler} value={inputField.s4_vehical_phone1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>

                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Year</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_year2" onChange={inputsHandler} value={inputField.s4_vehical_year2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Make/Model</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_make_model2" onChange={inputsHandler} value={inputField.s4_vehical_make_model2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_purchase_date2" onChange={inputsHandler} value={inputField.s4_vehical_purchase_date2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_fair2" onChange={inputsHandler} value={inputField.s4_vehical_current_fair2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_loan2" onChange={inputsHandler} value={inputField.s4_vehical_current_loan2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_amount_of_monthly_payment2" onChange={inputsHandler} value={inputField.s4_vehical_amount_of_monthly_payment2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_date_of_final_payment2" onChange={inputsHandler} value={inputField.s4_vehical_date_of_final_payment2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_equityfmv_minus_loan2" onChange={inputsHandler} value={inputField.s4_vehical_equityfmv_minus_loan2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Mileage
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_mileage2" onChange={inputsHandler} value={inputField.s4_vehical_mileage2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    License/Tag Number
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_license_tag_number2" onChange={inputsHandler} value={inputField.s4_vehical_license_tag_number2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} rowSpan={2} valign="top" style={{ width: '65%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_name_address2" onChange={inputsHandler} value={inputField.s4_vehical_name_address2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_phone2" onChange={inputsHandler} value={inputField.s4_vehical_phone2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Vehicle Identification Number (VIN)
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_vehicle_identification_number2" onChange={inputsHandler} value={inputField.s4_vehical_vehicle_identification_number2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23c</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Year</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_year3" onChange={inputsHandler} value={inputField.s4_vehical_year3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Make/Model</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_make_model3" onChange={inputsHandler} value={inputField.s4_vehical_make_model3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_purchase_date3" onChange={inputsHandler} value={inputField.s4_vehical_purchase_date3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_fair3" onChange={inputsHandler} value={inputField.s4_vehical_current_fair3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_loan3" onChange={inputsHandler} value={inputField.s4_vehical_current_loan3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_amount_of_monthly_payment3" onChange={inputsHandler} value={inputField.s4_vehical_amount_of_monthly_payment3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_date_of_final_payment3" onChange={inputsHandler} value={inputField.s4_vehical_date_of_final_payment3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_equityfmv_minus_loan3" onChange={inputsHandler} value={inputField.s4_vehical_equityfmv_minus_loan3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Mileage
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_mileage3" onChange={inputsHandler} value={inputField.s4_vehical_mileage3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    License/Tag Number
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_license_tag_number3" onChange={inputsHandler} value={inputField.s4_vehical_license_tag_number3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} rowSpan={2} valign="top" style={{ width: '65%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_name_address3" onChange={inputsHandler} value={inputField.s4_vehical_name_address3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_phone3" onChange={inputsHandler} value={inputField.s4_vehical_phone3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Vehicle Identification Number (VIN)
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_vehicle_identification_number3" onChange={inputsHandler} value={inputField.s4_vehical_vehicle_identification_number3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23d</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Year</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_year4" onChange={inputsHandler} value={inputField.s4_vehical_year4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Make/Model</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_make_model4" onChange={inputsHandler} value={inputField.s4_vehical_make_model4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_purchase_date4" onChange={inputsHandler} value={inputField.s4_vehical_purchase_date4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_fair4" onChange={inputsHandler} value={inputField.s4_vehical_current_fair4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_current_loan4" onChange={inputsHandler} value={inputField.s4_vehical_current_loan4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_amount_of_monthly_payment4" onChange={inputsHandler} value={inputField.s4_vehical_amount_of_monthly_payment4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_vehical_date_of_final_payment4" onChange={inputsHandler} value={inputField.s4_vehical_date_of_final_payment4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_equityfmv_minus_loan4" onChange={inputsHandler} value={inputField.s4_vehical_equityfmv_minus_loan4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Mileage
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_mileage4" onChange={inputsHandler} value={inputField.s4_vehical_mileage4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    License/Tag Number
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_license_tag_number4" onChange={inputsHandler} value={inputField.s4_vehical_license_tag_number4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} rowSpan={2} valign="top" style={{ width: '65%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_name_address4" onChange={inputsHandler} value={inputField.s4_vehical_name_address4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_phone4" onChange={inputsHandler} value={inputField.s4_vehical_phone4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Vehicle Identification Number (VIN)
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_vehical_vehicle_identification_number4" onChange={inputsHandler} value={inputField.s4_vehical_vehicle_identification_number4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>22e</strong>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    <strong> Total Equity</strong><span style={{ fontStyle: 'italic' }}> (Add lines 22a through 22d and amounts from any attachments)</span>&nbsp;&nbsp;
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '47%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_vehical_total_equity" onChange={inputsHandler} value={inputField.s4_vehical_total_equity || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    {/* page 4 end*/}
                                                    {/* page 5 start*/}
                                                    <div ref={ref => pagesRefs.current[4] = ref} style={{ padding: '10px' }}>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>BUSINESS EQUIPMENT AND INTANGIBLE ASSETS</strong> Include all machinery, equipment, merchandise inventory, and other assets in 23a through 23d. List
                                                                intangible assets in 23e through 23g <span style={{ fontStyle: 'italic' }}>(licenses, patents, logos, domain names, trademarks, copyrights, software, mining claims, goodwill and trade secrets.)</span>&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            &nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Purchase/
                                                                            Lease Date
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Fair
                                                                            Market Value
                                                                            (FMV)
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Current Loan
                                                                            Balance
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Amount of
                                                                            Monthly
                                                                            Payment
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Date of Final
                                                                            Payment
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            <strong>Equity</strong>
                                                                            FMV Minus Loan
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description1" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description1 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_purchase_date1" onChange={inputsHandler} value={inputField.s4_business_equipment_purchase_date1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_fair1" onChange={inputsHandler} value={inputField.s4_business_equipment_current_fair1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_loan1" onChange={inputsHandler} value={inputField.s4_business_equipment_current_loan1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_amount_of_monthly_payment1" onChange={inputsHandler} value={inputField.s4_business_equipment_amount_of_monthly_payment1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_date_of_final_payment1" onChange={inputsHandler} value={inputField.s4_business_equipment_date_of_final_payment1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan1" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location of asset <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_location1" onChange={inputsHandler} value={inputField.s4_business_equipment_location1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_name_address1" onChange={inputsHandler} value={inputField.s4_business_equipment_name_address1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_phone1" onChange={inputsHandler} value={inputField.s4_business_equipment_phone1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description2" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description2 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_purchase_date2" onChange={inputsHandler} value={inputField.s4_business_equipment_purchase_date2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_fair2" onChange={inputsHandler} value={inputField.s4_business_equipment_current_fair2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_loan2" onChange={inputsHandler} value={inputField.s4_business_equipment_current_loan2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_amount_of_monthly_payment2" onChange={inputsHandler} value={inputField.s4_business_equipment_amount_of_monthly_payment2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_date_of_final_payment2" onChange={inputsHandler} value={inputField.s4_business_equipment_date_of_final_payment2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan2" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location of asset <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_location2" onChange={inputsHandler} value={inputField.s4_business_equipment_location2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_name_address2" onChange={inputsHandler} value={inputField.s4_business_equipment_name_address2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_phone2" onChange={inputsHandler} value={inputField.s4_business_equipment_phone2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23c</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description3" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description3 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_purchase_date3" onChange={inputsHandler} value={inputField.s4_business_equipment_purchase_date3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_fair3" onChange={inputsHandler} value={inputField.s4_business_equipment_current_fair3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_loan3" onChange={inputsHandler} value={inputField.s4_business_equipment_current_loan3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_amount_of_monthly_payment3" onChange={inputsHandler} value={inputField.s4_business_equipment_amount_of_monthly_payment3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_date_of_final_payment3" onChange={inputsHandler} value={inputField.s4_business_equipment_date_of_final_payment3 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan3" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location of asset <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_location3" onChange={inputsHandler} value={inputField.s4_business_equipment_location3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_name_address3" onChange={inputsHandler} value={inputField.s4_business_equipment_name_address3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_phone3" onChange={inputsHandler} value={inputField.s4_business_equipment_phone3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23d</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '30%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description4" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description4 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_purchase_date4" onChange={inputsHandler} value={inputField.s4_business_equipment_purchase_date4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_fair4" onChange={inputsHandler} value={inputField.s4_business_equipment_current_fair4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_current_loan4" onChange={inputsHandler} value={inputField.s4_business_equipment_current_loan4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_amount_of_monthly_payment4" onChange={inputsHandler} value={inputField.s4_business_equipment_amount_of_monthly_payment4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '12%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <input type="text" name="s4_business_equipment_date_of_final_payment4" onChange={inputsHandler} value={inputField.s4_business_equipment_date_of_final_payment4 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan4" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={2} valign="top" style={{ width: '45%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Location of asset <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Country
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_location4" onChange={inputsHandler} value={inputField.s4_business_equipment_location4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: '0 0 0 10px', margin: 0, width: '100%' }}>
                                                                                    Lender/Lessor Name, Address, <span style={{ fontStyle: 'italic' }}>(Street, City, State, ZIP code)</span>&nbsp;&nbsp; and Phone
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_name_address4" onChange={inputsHandler} value={inputField.s4_business_equipment_name_address4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ paddingLeft: '10px' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_phone4" onChange={inputsHandler} value={inputField.s4_business_equipment_phone4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23e</strong>
                                                                            </td>
                                                                            <td valign="top" colSpan={6} style={{ width: '80%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Intangible Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description5" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description5 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan5" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan5 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23f</strong>
                                                                            </td>
                                                                            <td valign="top" colSpan={6} style={{ width: '80%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Intangible Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description6" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description6 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan6" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan6 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23g</strong>
                                                                            </td>
                                                                            <td valign="top" colSpan={6} style={{ width: '80%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}>Intangible Asset Description</p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    <input type="text" name="s4_business_equipment_asset_description7" onChange={inputsHandler} value={inputField.s4_business_equipment_asset_description7 || ''} style={{ width: '100%', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_equityfmv_minus_loan7" onChange={inputsHandler} value={inputField.s4_business_equipment_equityfmv_minus_loan7 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>23h</strong>
                                                                            </td>
                                                                            <td valign="top" colSpan={6} style={{ width: '70%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <p style={{ padding: '0 0 0 10px', margin: 0, width: '100%' }}><strong>Total Equity</strong> <span style={{ fontStyle: 'italic' }}>(Add lines 23a through 23g and amounts from any attachments)</span>&nbsp;&nbsp;</p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_equipment_total_equity" onChange={inputsHandler} value={inputField.s4_business_equipment_total_equity || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left', borderBottom: '1px solid #000', paddingLeft: '30px' }}>
                                                                <strong>BUSINESS LIABILITIES</strong> Include notes and judgements not listed previously on this form.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }} />
                                                                        <td style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Business Liabilities
                                                                        </td>
                                                                        <td style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Secured/
                                                                            Unsecured
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Date Pledged
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Balance Owed
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            Date of Final
                                                                            Payment
                                                                            <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            Payment
                                                                            Amount
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>24a</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    Description:
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <input type="text" name="s4_business_liailities_description1" onChange={inputsHandler} value={inputField.s4_business_liailities_description1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left', paddingLeft: '10px' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}><input type="radio" value="1" name="s4_business_liailities_secured_unsecured1" onChange={inputsHandler} checked={inputField.s4_business_liailities_secured_unsecured1 == 1} /> <strong>Secured
                                                                                </strong></p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}><input type="radio" value="2" name="s4_business_liailities_secured_unsecured1" onChange={inputsHandler} checked={inputField.s4_business_liailities_secured_unsecured1 == 2} /> <strong>Unsecured</strong></p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s4_business_liailities_date_pledged1" onChange={inputsHandler} value={inputField.s4_business_liailities_date_pledged1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_balance_owed1" onChange={inputsHandler} value={inputField.s4_business_liailities_balance_owed1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s4_business_liailities_date_of_final_payment1" onChange={inputsHandler} value={inputField.s4_business_liailities_date_of_final_payment1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_payment_amount1" onChange={inputsHandler} value={inputField.s4_business_liailities_payment_amount1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '95%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_name1" onChange={inputsHandler} value={inputField.s4_business_liailities_name1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Street Address</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_address1" onChange={inputsHandler} value={inputField.s4_business_liailities_address1 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', textAlign: 'left', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>City/State/ZIP code</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_city1" onChange={inputsHandler} value={inputField.s4_business_liailities_city1 || ''} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_phone1" onChange={inputsHandler} value={inputField.s4_business_liailities_phone1 || ''} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>24b</strong>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}>
                                                                                    Description:
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <input type="text" name="s4_business_liailities_description2" onChange={inputsHandler} value={inputField.s4_business_liailities_description2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '10%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left', paddingLeft: '10px' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}><input type="radio" value="1" name="s4_business_liailities_secured_unsecured2" onChange={inputsHandler} checked={inputField.s4_business_liailities_secured_unsecured2 == 1} /> <strong>Secured
                                                                                </strong></p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%' }}><input type="radio" value="2" name="s4_business_liailities_secured_unsecured2" onChange={inputsHandler} checked={inputField.s4_business_liailities_secured_unsecured2 == 2} /> <strong>Unsecured</strong></p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s4_business_liailities_date_pledged2" onChange={inputsHandler} value={inputField.s4_business_liailities_date_pledged2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_balance_owed2" onChange={inputsHandler} value={inputField.s4_business_liailities_balance_owed2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <input type="text" name="s4_business_liailities_date_of_final_payment2" onChange={inputsHandler} value={inputField.s4_business_liailities_date_of_final_payment2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_payment_amount2" onChange={inputsHandler} value={inputField.s4_business_liailities_payment_amount2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '50px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                            </td>
                                                                            <td colSpan={6} valign="top" style={{ width: '95%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Name</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_name2" onChange={inputsHandler} value={inputField.s4_business_liailities_name2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Street Address</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_address2" onChange={inputsHandler} value={inputField.s4_business_liailities_address2 || ''} style={{ width: '100%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                                <p style={{ padding: 0, margin: 0, width: '100%', textAlign: 'left', display: 'inline-flex' }}>
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>City/State/ZIP code</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_city2" onChange={inputsHandler} value={inputField.s4_business_liailities_city2 || ''} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                    <span style={{ whiteSpace: 'nowrap' }}>Phone</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_phone2" onChange={inputsHandler} value={inputField.s4_business_liailities_phone2 || ''} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '30px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>24c</strong>
                                                                            </td>
                                                                            <td colSpan={5} valign="top" style={{ width: '70%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'left' }}>
                                                                                <strong>Total Payments</strong> <span style={{ fontStyle: 'italic' }}> (Add lines 24a and 24b and amounts from any attachments) </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td valign="top" style={{ width: '25%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: 0, margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="text" name="s4_business_liailities_total_payment" onChange={inputsHandler} value={inputField.s4_business_liailities_total_payment || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%', height: '25px' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    {/* page 5 end*/}
                                                    {/* page 6 start*/}
                                                    <div ref={ref => pagesRefs.current[5] = ref} style={{ padding: '10px' }}>
                                                        <tr>
                                                            <td colSpan={2} style={{ fontSize: '12px', fontWeight: 'bold', color: '#fff', textAlign: 'left', padding: '3px 0 3px 15px', background: '#000' }}>
                                                                Section 5: Monthly Income/Expenses Statement for Business
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                <strong>Accounting Method Used: <span /></strong> <input type="radio" value="1" onChange={inputsHandler} checked={inputField.s5_accounting_method == 1} name="s5_accounting_method" /> Cash <span> <input type="radio" value="2" onChange={inputsHandler} checked={inputField.s5_accounting_method == 2} name="s5_accounting_method" /> Accrual</span>&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                Use the prior 3, 6, 9 or 12 month period to determine your typical business income and expenses.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                <p style={{ padding: 0, margin: 0, width: '100%', textAlign: 'left', display: 'inline-flex' }}>
                                                                    <strong style={{ whiteSpace: 'nowrap' }}>Income and Expenses during the period</strong> <span style={{ fontStyle: 'italic' }}>(mmddyyyy)</span>&nbsp;&nbsp;
                                                                    <input type="text" name="s5_income_expense_date_from" value={`${month}/01/${year}`} readOnly onChange={inputsHandler} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                    <span style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
                                                                        to (mmddyyyy)
                                                                    </span>&nbsp;&nbsp;
                                                                    <input type="text" name="s5_income_expense_date_to" value={`${month}/${getLastDayOfMonth(month, year)}/${year}`} readOnly onChange={inputsHandler} style={{ width: '40%', height: '25px', padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, textAlign: 'left' }} />
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                Provide a breakdown below of your average monthly income and expenses, based on the period of time used above.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td colSpan={2} style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                            <strong>Total Monthly Business Income</strong>
                                                                        </td>
                                                                        <td colSpan={2} style={{ width: '50%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                            <strong>Total Monthly Business Expenses</strong>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                Income Source
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                Gross Monthly
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                Expense items
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                Actual Monthly
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>25</strong>
                                                                                    Gross Receipts from Sales/Services
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_gross_receipts" onChange={inputsHandler} value={inputField.s5_gross_receipts || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>36</strong>
                                                                                    Materials Purchased <sup>1</sup>
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_materials_purchased" onChange={inputsHandler} value={inputField.s5_materials_purchased || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>26</strong>
                                                                                    Gross Rental Income
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_gross_rental_income" onChange={inputsHandler} value={inputField.s5_gross_rental_income || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>37</strong>
                                                                                    Inventory Purchased  <sup>2</sup>
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_inventory_purchased" onChange={inputsHandler} value={inputField.s5_inventory_purchased || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>27</strong>
                                                                                    Interest Income
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_interest_income" onChange={inputsHandler} value={inputField.s5_interest_income || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>38</strong>
                                                                                    Gross Wages &amp; Salaries
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_gross_wages" onChange={inputsHandler} value={inputField.s5_gross_wages || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>28</strong>
                                                                                    Dividends
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_dividends" onChange={inputsHandler} value={inputField.s5_dividends || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>39</strong>
                                                                                    Rent
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_rent" onChange={inputsHandler} value={inputField.s5_rent || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>29</strong>
                                                                                    Cash Receipts (Not included in lines 25-28)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_cash_receipts" onChange={inputsHandler} value={inputField.s5_cash_receipts || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>40</strong>
                                                                                    Supplies <sup>3</sup>
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_supplies" onChange={inputsHandler} value={inputField.s5_supplies || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>&nbsp;</strong>
                                                                                    Other Income (Specify below)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}></span>&nbsp;&nbsp;

                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>41</strong>
                                                                                    Utilities/Telephone  <sup>4</sup>
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_utilities_telephone" onChange={inputsHandler} value={inputField.s5_utilities_telephone || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>30</strong>
                                                                                    <input type="text" name="s5_text_other1" onChange={inputsHandler} value={inputField.s5_text_other1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other1" onChange={inputsHandler} value={inputField.s5_other1 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>42</strong>
                                                                                    Vehicle Gasoline/Oil
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_vehicle_gasoline_Oil" onChange={inputsHandler} value={inputField.s5_vehicle_gasoline_Oil || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>31</strong>
                                                                                    <input type="text" name="s5_text_other2" onChange={inputsHandler} value={inputField.s5_text_other2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other2" onChange={inputsHandler} value={inputField.s5_other2 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>43</strong>
                                                                                    Repairs &amp; Maintenance
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_repairs_maintenance" onChange={inputsHandler} value={inputField.s5_repairs_maintenance || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>32</strong>
                                                                                    <input type="text" name="s5_text_other3" onChange={inputsHandler} value={inputField.s5_text_other3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other3" onChange={inputsHandler} value={inputField.s5_other3 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>44</strong>
                                                                                    Insurance
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_insurance" onChange={inputsHandler} value={inputField.s5_insurance || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>33</strong>
                                                                                    <input type="text" name="s5_text_other4" onChange={inputsHandler} value={inputField.s5_text_other4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other4" onChange={inputsHandler} value={inputField.s5_other4 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>45</strong>
                                                                                    Current Taxes <span>5</span>&nbsp;&nbsp;
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_current_taxes" onChange={inputsHandler} value={inputField.s5_current_taxes || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>34</strong>
                                                                                    <input type="text" name="s5_text_other5" onChange={inputsHandler} value={inputField.s5_text_other5 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other5" onChange={inputsHandler} value={inputField.s5_other5 || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>46</strong>
                                                                                    Other Expenses (Specify)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_other_expense_specify" onChange={inputsHandler} value={inputField.s5_other_expense_specify || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>35</strong>
                                                                                    <span> Total Income</span>&nbsp;&nbsp; (Add lines 25 through 34)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_total_income" onChange={inputsHandler} value={inputField.s5_total_income || 0} readOnly style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>47</strong>
                                                                                    IRS Use Only-Allowable Installment Payments
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_irs_installment_payments" onChange={inputsHandler} value={inputField.s5_irs_installment_payments || ''} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    &nbsp;
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>48</strong>
                                                                                    <strong>Total Expenses</strong> (Add lines 36 through 47)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_total_expenses" value={inputField.s5_total_expenses || 0} readOnly onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} valign="top" style={{ width: '50%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    &nbsp;
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '35%', borderBottom: '1px solid #000', borderRight: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <strong style={{ padding: '0 5px' }}>49</strong>
                                                                                    <strong>Net Income</strong> (Line 35 minus Line 48)
                                                                                </p>
                                                                            </td>
                                                                            <td valign="top" style={{ width: '15%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                                                                                <p style={{ textAlign: 'left', padding: 0, margin: 0, display: 'inline-flex', width: '100%' }}>
                                                                                    <span style={{ padding: '0 5px', margin: 0, fontSize: '11px', color: '#000', whiteSpace: 'nowrap' }}>$</span>&nbsp;&nbsp;
                                                                                    <input type="number" name="s5_net_income" value={inputField.s5_net_income || 0} readOnly onChange={inputsHandler} style={{ padding: 0, margin: 0, backgroundColor: '#f1f4ff', border: 0, width: '100%' }} />
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '50%', padding: '3px 0' }}>
                                                                            <span>1</span>&nbsp;&nbsp;
                                                                            <span>
                                                                                <strong>Materials Purchased:</strong> Materials are items directly related to the
                                                                                production of a product or service.
                                                                            </span>&nbsp;&nbsp;
                                                                        </td>
                                                                        <td style={{ width: '50%', padding: '3px 0' }}>
                                                                            <span>4</span>&nbsp;&nbsp;
                                                                            <span>
                                                                                <strong>Utilities/Telephone:</strong> : Utilities include gas, electricity, water, oil, other
                                                                                fuels, trash collection, telephone, cell phone and business internet.
                                                                            </span>&nbsp;&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td style={{ width: '50%', padding: '3px 0' }}>
                                                                                <span>2</span>&nbsp;&nbsp;
                                                                                <span>
                                                                                    <strong>Inventory Purchased:</strong>   Goods bought for resale.
                                                                                </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '50%', padding: '3px 0' }}>
                                                                                <span>5</span>&nbsp;&nbsp;
                                                                                <span>
                                                                                    <strong>Current Taxes: </strong> Real estate, state, and local income tax, excise,
                                                                                    franchise, occupational, personal property, sales and the employer's
                                                                                    portion of employment taxes.
                                                                                </span>&nbsp;&nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '50%', padding: '3px 0' }}>
                                                                                <span>3</span>&nbsp;&nbsp;
                                                                                <span>
                                                                                    <strong>Supplies:</strong>    Supplies are items used to conduct business and are
                                                                                    consumed or used up within one year. This could be the cost of
                                                                                    books, office supplies, professional equipment, etc.
                                                                                </span>&nbsp;&nbsp;
                                                                            </td>
                                                                            <td style={{ width: '50%', padding: '3px 0' }}>
                                                                                &nbsp;
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                                                <strong>Certification:</strong>
                                                                <span style={{ fontStyle: 'italic' }}>Under penalties of perjury, I declare that to the best of my knowledge and belief this statement of assets, liabilities, and other
                                                                    information is true, correct, and complete.</span>&nbsp;&nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: '100%', textAlign: 'left' }}>
                                                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 0 }}>
                                                                    <tbody><tr>
                                                                        <td style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                            <strong>Signature</strong>
                                                                        </td>
                                                                        <td style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                                                                            <strong>Title</strong>
                                                                        </td>
                                                                        <td style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000' }}>
                                                                            <strong>Date</strong>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td colSpan={3} style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000' }}>
                                                                                <strong>Print Name of Officer, Partner or LLC Member</strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3} style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000' }}>
                                                                                <strong>After we review the completed Form 433-B, you may be asked to provide verification for the assets, encumbrances, income and expenses
                                                                                    reported. Documentation may include previously filed income tax returns, profit and loss statements, bank and investment statements, loan
                                                                                    statements, financing statements, bills or statements for recurring expenses, etc.</strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3} style={{ width: '33%', padding: '0 10px 20px 10px' }}>
                                                                                <strong>IRS USE ONLY</strong><span style={{ fontStyle: 'italic' }}>(Notes) </span>&nbsp;&nbsp;
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3} style={{ width: '33%', padding: '0 10px 20px 10px', borderBottom: '1px solid #000' }}>
                                                                                <strong>Privacy Act:</strong>The information requested on this Form is covered under Privacy Acts and Paperwork Reduction Notices which have already been provided to the taxpayer.


                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </div>
                                                    {/* page 6 end */}
                                                </tbody></table>
                                        </td></tr></tbody>
                                </table>

                            </div>
                            {/* <button type="button" className="btn btn-secondary" onClick={submitButton}>
                                Save
                                </button>
                                <br/> */}
                            <button type="button" className="btn btn-secondary" onClick={submitButton}>
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save and Generate PDF'}
                            </button>

                        </form>



                    </div>

                </div>
            </div>
        </div>

    )
}

export default FourThreeThreeB;