
import React, { FC, useState, useEffect, useRef, ReactNode, ReactComponentElement, ReactElement } from 'react'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux'

import BankAccSync from './BankAccSync'
import SaleAccSync from './SaleAccSync'
import { RevRexBankAccSyncGlI } from '../../../resources/form-props';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'
import closeBtn from '../../../assets/images/close-btn.svg';

interface Props {
    syncBankAcc: boolean,
    syncSalesAcc: boolean,
    _handleCloseModal: () => void
}
const RevrexSyncGLAcc: FC<Props> = ({ syncBankAcc, syncSalesAcc, _handleCloseModal }) => {

    const { glAccountsList, getBankSaleList, updateBankSale } = useChartAccountApi();
    const branchData = useSelector(getBranch);
    const bankFormRef = useRef<any>()
    const saleFormRef = useRef<any>()

    const [bankRecords, setBankRecords] = useState<any>([]);
    const [saleRecords, setSaleRecords] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fechAccountList = (searchText: string = '', excludeVP: number[] = [], includeVP: number[] = []): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            if (excludeVP.length) {
                params['parent_account_not_in'] = excludeVP.join(',')
            }
            if (includeVP.length) {
                params['parent_account_in'] = includeVP.join(',')
            }

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id'],
                    account: e['account']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    useEffect(() => {
        if (syncBankAcc) {
            fetchBankRecords();
        }
    }, [syncBankAcc])
    useEffect(() => {
        if (syncSalesAcc) {
            fetchSalesRecords()
        }
    }, [syncSalesAcc])

    const fetchBankRecords = () => {
        const params = {
            branch_id: +branchData['id'],
            type: 1
        }
        setIsLoading(true)
        getBankSaleList(params, (message: string, resp: any) => {
            setIsLoading(false)
            setBankRecords(resp.data.data.bankAcc)
        }, (message: string, error: any) => {
            setIsLoading(false)
            toast.error(message)
        })
    }
    const fetchSalesRecords = () => {
        const params = {
            branch_id: +branchData['id'],
            type: 2
        }
        setIsLoading(false)
        getBankSaleList(params, (message: string, resp: any) => {
            setIsLoading(false)
            setSaleRecords(resp.data.data.salesAcc)
        }, (message: string, error: any) => {
            setIsLoading(false)
            toast.error(message)
        })
    }

    const processData = ( data: any ) => {
        console.log('data: ', data)
        /*
        "requestBankAcc": [
            {
                "bank_account_id": 217,
                "gl_account": 861,
                "gl_account_number": "1005",
                "vp_name": "Test GL"
            },
            {
                "bank_account_id": 216,
                "gl_account": 861,
                "gl_account_number": "1005",
                "vp_name": "Test GL"
            }
        ],
        "requestSalesAcc": [
            {
                "id": 81,
                "gl_account": 996,
                "gl_account_number": "6002",
                "vp_name": "Test GL"
            }
        ]
        */
        const formData = {
            requestBankAcc: [],
            requestSalesAcc: []
        };
        if ( syncBankAcc ) {
            formData['requestBankAcc'] = data['banks'].map((bank: any, index: number) => ({
                bank_account_id: +bank['bank_account_id']['value'],
                gl_account: +bank['gl_account']['value'],
                gl_account_number: +bank['gl_account']['account'],
                vp_name: bank['bank_account_id']['label']
            }))
        } else {
            formData['requestSalesAcc'] = data['sales'].map((sale: any, index: number) => ({
                id: sale['sale_account_id']['value'],
                gl_account: sale['gl_account']['value'],
                gl_account_number: sale['gl_account']['account'],
                vp_name: sale['sale_account_id']['label'],
            }))
        }

        const queryString = {
            branch_id: +branchData['id'],
            type: syncBankAcc ? 1 : 2
        }

        setIsLoading(true);
        updateBankSale({formData, queryString}, (message: string, resp: any) => {
            setIsLoading(false);
            toast.success(message)
            _handleCloseModal();
        }, (message: string, error: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    const _triggerSubmitHandler = () => { 
        if ( syncBankAcc ) {
            bankFormRef.current.requestSubmit()
        } else if ( syncSalesAcc ) {
            saleFormRef.current.requestSubmit()
        }
    }

    return (
        <Modal show={true} onHide={_handleCloseModal} backdrop="static" animation={false} size="lg">
            <div>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {syncBankAcc ? 'Sync Bank Accounts' : 'Sync Sales Accounts'}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={_handleCloseModal}>
                        <img src={closeBtn} alt="" />
                    </button>
                </div>
                <div className="modal-body pb-0">
                    {
                        syncBankAcc &&
                        <BankAccSync 
                            formRef={bankFormRef}
                            banks={bankRecords} 
                            fechAccountList={fechAccountList}
                            processData={processData}
                        /> || <></>
                    }

                    {
                        syncSalesAcc &&
                        <SaleAccSync 
                            formRef={saleFormRef}
                            sales={saleRecords} 
                            fechAccountList={fechAccountList}
                            processData={processData}
                        /> || <></>
                    }
                </div>
                <div className="d-flex justify-content-center modal-footer mt-4">
                    <button type="button" className="btn btn-cancel" onClick={_handleCloseModal}>Close</button>
                    <button type="button" className="btn btn-secondary" onClick={_triggerSubmitHandler}>
                        {isLoading && (<Spinner animation="border" size="sm" />) || 'Save changes'}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default RevrexSyncGLAcc;