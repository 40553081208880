import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Toaster, ToastPosition } from 'react-hot-toast';
import ScrollToTop from './ScrollToTop'

import Router from './router'

const App: React.FC = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Router />
    <Toaster position={'top-right'} />
  </BrowserRouter>
)

export default App
