import { Outlet, Navigate } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { useAuthStatus } from '../../resources/hooks/authHook'
import { openingBalanceExixts } from '../../resources/hooks/openingBalanceExixtsHook'

const VersionUpgrade = () => {
    const isOpeningBalanceExixts = openingBalanceExixts();

    return(
        isOpeningBalanceExixts ? <Navigate to={URLS.DASHBOARD} /> : <Outlet/>
    )
}

export default VersionUpgrade