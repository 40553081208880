import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useUserApi() {

    const callApi = useApiCall();

    const login = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
    }

    const fetchUserData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.DETAILS, data, onSuccess, onError);
    }

    const userAccess = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.USER_ACCESS, data, onSuccess, onError);
    }

    /*const userAllowance = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.ACCOUNT_ALLOWANCE, data, onSuccess, onError);
    }

    const userStepStatus = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.USER_STEP_STATUS, data, onSuccess, onError);
    }*/
    

    return {
        login,
        fetchUserData,
        userAccess,
        // userAllowance,
        // userStepStatus
    }

    
    
}