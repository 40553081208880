import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUser } from '../../store/user/selectors'
import { getAccessToken } from '../../store/user/selectors'
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { CustomTable } from '../../components'
import SideNavbar from '../../layouts/SideNavbar'
import { Link } from 'react-router-dom'
import pencilIcon from '../../assets/images/pencil-icon.svg';
import SettingsAccountingModal from '../../components/Modal/Settings/Accounting'
import { getAccountSettings, getBudgetSettings } from '../../store/settings/selectors'
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook'

import {getBranch} from '../../store/user/selectors'

interface Props {
  
}

const Settings: React.FC<Props> = () => {

  const [showAccountingModal, setShowAccountingModal] = useState<boolean>(false);

  const budgetSettings = useSelector(getBudgetSettings)

  const { fetchAccountSettingsData } = useSettingsApi();

  const branchData = useSelector(getBranch)

  useEffect(() => {
    if ( !showAccountingModal ) {
        getSettingsData()
    }
  }, [showAccountingModal])

  const getSettingsData = () => {
    fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

    }, (msg: string, resp: any) => {

    })
  }
  const closeModal = () => {
    setShowAccountingModal(false)
  }

  return (
        <div className="main-container flex-grow-1">
            <div className="two-col-wrap">
                <SideNavbar/>
                <div className="right-side-wrap">
                    <div className="page-title pb-4">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <h1 className="h3 font-weight-700  mb-0">Settings</h1>
                            </div>
                        </div>
                    </div>
                    <div className="white-body-wrap">
                        <div className="profile-box">
                            <h3 className="profile-title">
                                <em>Accounting</em> 
                                <span onClick={() => setShowAccountingModal(true)}><img src={pencilIcon} alt=""/></span>
                            </h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Budget Based On</h4>
                                        <p>{budgetSettings?.budget_based_on && budgetSettings?.budget_based_on?.label || 'NA'}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>First month of calendar year</h4>
                                        <p>{budgetSettings?.first_month_of_fiscal_year && budgetSettings?.first_month_of_fiscal_year?.label || 'NA'}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>First month of fiscal year</h4>
                                        <p>{budgetSettings?.first_month_of_income_tax_year && budgetSettings?.first_month_of_income_tax_year?.label || 'NA'}</p>
                                        {/* <p>{budgetSettings?.first_month_of_income_tax_year && budgetSettings?.first_month_of_income_tax_year?.label || 'NA'}</p> */}
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Close the books</h4>
                                        <p>Off</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Company type</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Tax form</h4>
                                        <p>Sole proprietor (Form 1040)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Chart of accounts</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Enable account numbers</h4>
                                        <p>Off</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Tips account</h4>
                                        <p>Accounts Receivable (A/R)</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Company phone</h4>
                                        <p>+1 (480) 329-5661</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Automation</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Pre-fill forms with previously entered content</h4>
                                        <p>On</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Automatically apply credits</h4>
                                        <p>Off</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Automatically apply bill payments</h4>
                                        <p>Off</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Currency</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Home Currency</h4>
                                        <p>USD - United States Dollar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Business Network</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Allow members to find me</h4>
                                        <p>On</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box mb-0">
                            <h3 className="profile-title"><em>Other preferences</em> <span><img src={pencilIcon} alt=""/></span></h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Date format</h4>
                                        <p>DD/MM/YYYY</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Number format</h4>
                                        <p>$123,456.00</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Customer label</h4>
                                        <p>Customers</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Warn if duplicate check number is used</h4>
                                        <p>On</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Warn if duplicate journal number is used</h4>
                                        <p>Off</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Warn me when I enter a used bill number for that vendor</h4>
                                        <p>Off</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Sign me out if inactive for</h4>
                                        <p>1 Hour</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showAccountingModal && 
                <SettingsAccountingModal record={budgetSettings} closeModal={closeModal} /> || <></>
            }
        </div>
  )
}

export default Settings;