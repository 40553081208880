import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom'

import sortIcon from '../../assets/images/sort-icon.svg';
import plusIcon from '../../assets/images/plus-icon-w.svg';
import expandIcon from '../../assets/images/expand-svgrepo-com-w.svg';
import collapseIcon from '../../assets/images/collapse-alt-svgrepo-com-w.svg';
import minusIcon from '../../assets/images/minus.png';
import ChartAccountModal from '../../components/Modal/ChartOfAccount/ChartAccountModal'
import Pagination from '../../components/Pagination'
import { ChartAccountFormI } from '../../resources/form-props';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import CaRecod from './caRecord';
import { getBranch } from '../../store/user/selectors'


interface Props {

}

const ChartOfAccounts: React.FC<Props> = () => {

  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedRef, setSelectedRef] = useState<any>(null);

  const branchData = useSelector(getBranch)

  const { getList, getDetails } = useChartAccountApi();
  // const options = [
  //     { value: 'Created by You', label: 'Created by You' },
  //     { value: 'Low to High', label: 'Low to High' },
  //     { value: 'High to Low', label: 'High to Low' },
  //   ];
  // const [selectedOption, setSelectedOption] = useState<any>(null);
  const [recordList, setRecordList] = useState<any>([]);

  const { control, formState: { errors } } = useForm<ChartAccountFormI>({
    defaultValues: {
      name: '',
      created_by: '',
    }
  });


  useEffect(() => {
    fetchList();
  }, [])
  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    if (selectedRef) {
      selectedRef.current.click()
    }
    if (selectedRecord) {
      setSelectedRecord(null);
    } else {
      setRecordList([])
    }

    fetchList();
    setShowModal(false);
    setSelectedRecord(null);
    setSelectedRef(null);
  };

  const expandCollapseToggle = () => {
    setIsExpandAll(!isExpandAll)
  } 

  const handleEditRecord = async (id: number) => {
    const data = await fetchAccountDetails(id);
    if (data) {
      setSelectedRecord(data);
      showModal();
    }
  }

  const fetchAccountDetails = (id = 0): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const params = {
        id,
        branch_id: +branchData['id']
      }
      getDetails(params, (message: string, resp: any) => {
        return resolve(resp.data.data)
      }, (message: string, resp: any) => {
        toast.error(message);
        return resolve(null);
      })
    })
  }

  const fetchList = () => {
    setIsLoading(true);
    getList({ "page": 1, "per_page": 1000, "id": 0, branch_id: +branchData['id'], all: 1 }, (message: string, resp: any) => {
      setIsLoading(false);
      setRecordList(resp.data.data);
    }, (message: string, resp: any) => {
      setIsLoading(false);
      toast.error(message);
    })
  }

  // DeBounce Function
  const [search, setSearch] = useState('');
  const [filteredTitle, setFilteredTitle] = useState([]);

  //  useDebounce(() => {
  //       if(search!=='')
  //       setRecordList(
  //           recordList.filter((r:any) => r.description.toLowerCase().includes(search.toLowerCase()))
  //       );
  //       else
  //       {
  //           getList({"page":1,"per_page":10,"id":0}, (message: string, resp: any) => {
  //               setRecordList(resp.data.data.result);
  //             }, (message: string, resp: any) => {

  //             })
  //       }
  //  }, [recordList, search], 3000
  //  );

  const handleSearch = (e: any) => setSearch(e.target.value);




  return (

    <>
      <div className="main-container flex-grow-1">
        <div className="container-fluid">
          <div className="page-title pb-4 pt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 align-items-center d-flex">
                <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Chart of Accounts</h1>
                {/* <div className="two-btn-wrap ms-2">
                  <Link to="#"><img src={commentEditIcon} alt="" /></Link>
                  <Link to="#"><img src={playReviewIcon} alt="" /></Link>

                </div> */}
              </div>
              <div className="col-sm-6 align-items-center d-flex justify-content-end">
                <div className="dropdown add-new-dropdown d-flex">
                  <Link to="javascript:void(0)" className="d-flex gap-2 align-items-center new-dropdown" onClick={expandCollapseToggle}>
                  { isExpandAll ?  <span> <img src={collapseIcon} alt="" style={{ height: "20px"}}/> Collapse All </span> : <span> <img src={expandIcon} alt="" style={{ height: "20px"}}/> Expand All </span> }
                   
      
                  </Link> &nbsp;&nbsp;
                  <Link to="javascript:void(0)" className="d-flex gap-2 align-items-center new-dropdown" onClick={showModal}>
                    <span><img src={plusIcon} alt="" />New Account</span>
                  </Link>
                  {/* <ul className="dropdown-menu dropdown-menu-end add-new-dropdown-box">
                    <li><Link className="dropdown-item" to="javascript:void(0)" onClick={showModal}>Add New Account</Link></li>
                    <li><Link className="dropdown-item" to="javascript:void(0)">New Account List</Link></li>
                  </ul> */}
                </div>
              </div>

            </div>
            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
              <div className="loader"></div>
            </div>
          </div>

          <div className=" executive-dashboard">
            <div className="dashboard-top-part">

              <div className="table-responsive list-table">
                <div className="module-permission-table-wrapper">
                  <div className="module-permission-table-row th d-flex">
                    <div className="module-permission-col">
                      Module
                    </div>
                    <div className="module-permission-col">
                      Account Number
                    </div>
                    <div className="module-permission-col text-start">
                      Description
                    </div>
                    <div className="module-permission-col text-end">
                      Action
                    </div>
                    {/* <div className="module-permission-col">
                                    View
                                  </div>
                                  <div className="module-permission-col">
                                    Create
                                  </div>
                                  <div className="module-permission-col">
                                    Edit
                                  </div>
                                  <div className="module-permission-col">
                                    Delete
                                  </div>
                                  <div className="module-permission-col">
                                    Import
                                  </div>
                                  <div className="module-permission-col">
                                    Export
                                  </div> */}
                  </div>
                  <div className="tree">
                    {
                      recordList.length && recordList.map((d: any, i: number) => (
                        <CaRecod record={d} key={i} handleEditRecord={handleEditRecord} isExpandAll={isExpandAll} />
                      )) || <></>
                    }
                  </div>
                  {/* {record.length !== 0 ? (

                    record.map((d: any, i: number) => (
                      <div className="role_multiclopasable" key={i}>
                        <div className="module-permission-table-row td d-flex" >
                          <div className="module-permission-col" data-bs-toggle="collapse" data-bs-target={`#collapseExample_${d.id}`} onClick={getSubVal} id={d.id} role="button" aria-expanded="false" aria-controls="collapseExample">
                            {d.description}
                          </div>

                        </div>
                        {d.id == clickid ? (<SubList subRecord={subRecord} sl_id={d.id} getSubVal={getSubVal}></SubList>) : ""}

                      </div>

                    ))

                  ) : (<p className='data-not-found'><b>DATA NOT FOUND</b></p>)
                  } */}

                </div>
              </div>




            </div>


          </div>
        </div>
      </div>
      {
        isShowModal && <ChartAccountModal closeModal={hideModal} addRecord={selectedRecord ? false : true} record={selectedRecord} /> || <></>
      }
    </>
  )
}

export default ChartOfAccounts;