
export const capitalizeFirstLetter = (string: string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
};
 
export const currencyFormat=(price: any)=> {
  price=String(price);
  if(price == null)
  {
    price = '00';
  }
  if (price) {
    return '$ ' + price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return "";
  }
}