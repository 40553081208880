import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import filterSolidIcon from '../../../assets/images/filter-solid-icon.svg';
import toast from 'react-hot-toast';
import { SalesSearchFormI, SalesGlClassificationSingleFormI } from '../../../resources/form-props';
import { currencyFormat } from '../../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import { SalesGlClassificationFormI } from '../../../resources/form-props';
import SAlert from '../../../components/SAlert';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import useDebounce from '../../../resources/hooks/UseDebounce';
import { Box, FormControlLabel, Checkbox } from '@mui/material'
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, SalesExpenseValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

import '../GlClassification.css'
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { calculateMaxHeightContent, lastYears, usdCurrencyFormat } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';

import { SelectInstance } from 'react-select'; // Import the correct type
import SplitModal from 'components/Modal/SalesClassification/SplitModal';
import { Button } from 'react-bootstrap';

interface Props {

}

const SalesGlClassification: React.FC<Props> = () => {

    const { glAccountsList } = useChartAccountApi();
    const { glSaleslist, saleGlUpdate, revenueGlUpdate } = glClassificationApi();
    const urlParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [saleRecords, setSaleRecords] = useState<any>({
        type: null,
        data: []
    });
    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [glSalesAccount, setGlSalesAccount] = useState<any[]>([]);
    const [glExcludeSaleAccount, setGlExcludeSaleAccount] = useState<any[]>([]);
    const [checked, setChecked] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const checkAllRef = useRef<HTMLInputElement>(null);
    const [overShortLabel, setOverShortLabel] = useState<string>('GL Account');
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));

    const { register, trigger, control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<SalesGlClassificationFormI>({
        defaultValues: {
            sales: [],
        },
        resolver: yupResolver(SalesExpenseValidate)
    });

    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "sales"
    });
    const [showSplitModal, setShowSplitModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<SalesSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });


    useEffect(() => {
        fetchTransactionList();
        fechAccountList('', [], []).then(r => {
            setGlAllAccount(r);
        })
        fechAccountList('', [], GL_VP.SALES_ACTIVITY.account).then(r => {
            setGlSalesAccount(r);
        })
        fechAccountList('', GL_VP.SALES_ACTIVITY.account, []).then(r => {
            setGlExcludeSaleAccount(r);
        })
    }, [])

    useEffect(() => {
        // Filter out the unwanted record by id

        let indexCounter = 0;
        saleRecords['data'].map((record: any, index: number) => {

            // Initialize formData as an empty object
            let formData: any = {};

            if (saleRecords['type'] === 'sales_with_channel') {
                const grossSaleData = record['breakdowns'].filter((e: any) => e['account_type'] == "Gross_Sale");
                const stateTaxData = record['breakdowns'].filter((e: any) => e['account_type'] == "State_Tax");
                const overseeData = record['breakdowns'].filter((e: any) => e['account_type'] == "Overshort");

                record['breakdowns'].forEach((breakdown: any) => {
                    formData = {
                        formIndex: indexCounter,
                        checked: false,
                        record_id: record['id'],
                        parent_id: record['parent_id'],
                        description: record['description'],
                        account_name: record['account_name'],
                        amount: breakdown['account_value'] || 0,
                        transaction_at: record['transaction_at'],
                        gross_sale: breakdown['account_type'] === 'Gross_Sale' ? breakdown['account_value'] : 0,
                        state_tax: breakdown['account_type'] === 'State_Tax' ? breakdown['account_value'] : 0,
                        overshort: breakdown['account_type'] === 'Overshort'
                            ? (breakdown['txn_type'] === 1 ? breakdown['account_value'] : breakdown['account_value'] * -1)
                            : 0,

                        gross_sale_master_account_id: breakdown['account_type'] === 'Gross_Sale' && breakdown['account_chart'] ? {
                            label: `${breakdown['account_chart']['name']} (${breakdown['account_chart']['account']})`,
                            value: breakdown['account_chart']['id']
                        } : null,

                        state_tax_master_account_id: breakdown['account_type'] === 'State_Tax' && breakdown['account_chart'] ? {
                            label: `${breakdown['account_chart']['name']} (${breakdown['account_chart']['account']})`,
                            value: breakdown['account_chart']['id']
                        } : null,

                        overshort_master_account_id: null,
                        split: [],
                        account_type: breakdown['account_type'],
                        id: `${record['id']}-${breakdown['account_type']}`
                    };

                    formAppend(formData);
                    indexCounter++;
                });


            } else if (saleRecords['type'] === 'sales_without_channel') {

                formData = {
                    formIndex: indexCounter,
                    checked: false,
                    record_id: +record['transaction_account_id'],
                    parent_id: record['transaction'] && record['transaction']['parent_id'] || null,
                    description: record['transaction'] && record['transaction']['description'] || '',
                    account_name: record['transaction'] && record['transaction']['account_name'] || '',
                    transaction_at: record['transaction'] && record['transaction']['transaction_at'] || '',
                    gross_sale: record['account_value'] || 0,
                    amount: record['account_value'] || 0,
                    account_type: "Gross_Sale",
                    gross_sale_master_account_id: null,
                    split: []
                };

                formAppend(formData);
                indexCounter++;
            }
        });
    }, [saleRecords['data']]);

    // useEffect(() => {
    //     fetchTransactionList();
    // }, [selectedCalendar])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])

    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: urlParams['year'],
            month: urlParams['month'],
            branch_id: +branchData['id'],
            is_updated: 0
        }

        setIsLoading(true);
        glSaleslist(params, (message: string, resp: any) => {
            reset();
            setSaleRecords({
                type: resp.data.data.type,
                data: resp.data.data.items.result
            });
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }
    const fechAccountList = (searchText: string = '', excludeVP: number[] = [], includeVP: number[] = []): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            if (excludeVP.length) {
                params['parent_account_not_in'] = excludeVP.join(',')
            }
            if (includeVP.length) {
                params['parent_account_in'] = includeVP.join(',')
            }

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            fields.map((record: any, index: number) => {
                setValue(`sales.${index}.checked`, e.target.checked)
            })

        }
    };

    // const handleCheckAll = (e: any, index: number, row_id: number) => {
    //     setValue(`sales.${index}.checked`, e.target.checked);
    //     if (e.target.checked) {
    //         const checkedRecords = fields.filter((record: any, index: number) => getValues(`sales.${index}.checked`) === true);

    //         if (checkedRecords.length == fields.length) {
    //             if (checkAllRef.current) {
    //                 checkAllRef.current.checked = true;
    //             }
    //         }
    //     } else {
    //         const uncheckedRecords = fields.filter((record: any, index: number) => getValues(`sales.${index}.checked`) === false);

    //         if (uncheckedRecords.length) {
    //             if (checkAllRef.current) {
    //                 checkAllRef.current.checked = false;
    //             }
    //         }
    //     }
    // }

    const handleCheckAll = (e: any, index: number, row_id: number) => {
        const isChecked = e.target.checked;

        // Check or uncheck the current checkbox
        setValue(`sales.${index}.checked`, isChecked);

        fields.forEach((record: any, i: number) => {
            if (record.record_id === row_id) {
                setValue(`sales.${i}.checked`, isChecked);
            }
        });

        // Update the check all box based on the state of individual checkboxes
        const allChecked = fields.every((record: any, i: number) => getValues(`sales.${i}.checked`) === true);
        const anyUnchecked = fields.some((record: any, i: number) => getValues(`sales.${i}.checked`) === false);

        if (checkAllRef.current) {
            checkAllRef.current.checked = allChecked;
            checkAllRef.current.indeterminate = !allChecked && !anyUnchecked;
        }
    };

    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, [], [])
        return data;
    };
    const filterSalesAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, [], GL_VP.SALES_ACTIVITY.account)
        return data;
    };
    const filterExceptSalesAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, GL_VP.SALES_ACTIVITY.account, [])
        return data;
    };

    const handleCheckboxChange = (e: any, c: any) => {
        // alert();
        setChecked((prevChecked: any) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item: any) => item !== c.id)
        );
    };

    const [selectedRowID, setSelectedRowID] = useState<number | null>(null);
    const [indexNo, setIndexNo] = useState<number | null>(null);
    const handleSplitClick = (data: any, id: number) => {


        // Optionally find the specific item in the array and add the index if needed
        // For simplicity, we'll just push the index to the end of the array

        setSelectedRecord(data);
        setShowSplitModal(true);
    };
    const [splitData, setSplitData] = React.useState<any[]>([]);
    const handleSplitSave = (rowID: number, indexNo: number, splits: any[], account_type: string) => {
        // Create new splits with the rowID added
        const newSplits = splits.map(split => ({ ...split }));

        setSplitData(prevData => {
            // Filter out existing splits with the same rowID
            const updatedSplits = prevData.filter(split => !(split.rowID === rowID && split.account_type === account_type));
            // Merge new splits with the updated data
            return [...updatedSplits, ...newSplits];
        });

        // Remove rowID from newSplits
        const finalSplits = newSplits.map(split => {
            const { rowID, account_type, ...rest } = split;

            return rest;
        });



        // Ensure setValue is called with the latest splitData
        setValue(`sales.${indexNo}.split`, finalSplits);


    };

    const processData = (data: any) => {

        const toRemoveItems: number[] = [];
        const records = data['sales']
            // .filter((e: any) => e['checked'])
            .map((d: any, index: number) => {
                if (d['checked']) {
                    toRemoveItems.push(index)
                    var data: any = {};
                    let isFirstRow = true; // Track if it's the first row for setting id

                    let split = d['split']
                        .map((split: any) => {
                            if (isFirstRow) {
                                isFirstRow = false;
                                return {
                                    ...split,
                                    id: split.id, // Only first item retains id
                                    master_chart_of_account_id: split.master_chart_of_account_id
                                        ? split.master_chart_of_account_id.value
                                        : null,
                                };
                            } else {
                                return {
                                    ...split,
                                    id: null, // Set id to null for subsequent entries
                                    master_chart_of_account_id: split.master_chart_of_account_id
                                        ? split.master_chart_of_account_id.value
                                        : null,
                                };
                            }
                        })
                        .filter((item: any) => item.master_chart_of_account_id !== null); // Filter out splits with null gross_sale_master_account_id

                    // If no split data found, add a default split entry
                    if (split.length === 0 && d.account_type == 'Gross_Sale') {
                        split = [
                            {
                                id: d.record_id,
                                amount: d.amount,
                                master_chart_of_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            },
                        ];
                    }
                    if (split.length === 0 && d.account_type == 'State_Tax') {
                        split = [
                            {
                                id: d.record_id,
                                amount: d.amount,
                                master_chart_of_account_id: d['state_tax_master_account_id'] && d['state_tax_master_account_id']['value'] && +d['state_tax_master_account_id']['value'] || null,
                            },
                        ];
                    }
                    if (split.length === 0 && d.account_type == 'Overshort') {
                        split = [
                            {
                                id: d.record_id,
                                amount: d.amount,
                                master_chart_of_account_id: d['overshort_master_account_id'] && d['overshort_master_account_id']['value'] && +d['overshort_master_account_id']['value'] || null,
                            },
                        ];
                    }


                    if (saleRecords['type'] == 'sales_with_channel') {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            account_type: d.account_type,

                            // state_tax: d.state_tax,
                            // overshort: Math.abs(d.overshort),

                            // state_tax_master_account_id: d['state_tax_master_account_id'] && d['state_tax_master_account_id']['value'] && +d['state_tax_master_account_id']['value'] || null,
                            // overshort_master_account_id: d['overshort_master_account_id'] && d['overshort_master_account_id']['value'] && +d['overshort_master_account_id']['value'] || null,
                            split: split // Add filtered split_data array here
                        }
                    } else {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            account_type: d.account_type,
                            split: split // Add filtered split_data array here
                        }
                    }
                    return data;
                } else {
                    return null
                }
            }).filter((e: any) => e);
        // const filtered = records.filter((item: any) => item !== null)

        // console.log(records);

        postData(records, toRemoveItems);

    }
    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);

            //if ( saleRecords['type'] == 'sales_with_channel' ) {
            saleGlUpdate({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchTransactionList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })


        } else {
            toast.error("Please select at least one record");
        }
    }
    const handleSinglePost = (index: number) => {
        // processData(data);
        const data = getValues();
        const toRemoveItems: number[] = [];
        if (data['sales'][index]['checked']) {
            const records = [data['sales'][index]]
                .map((d: any) => {
                    var data: any = {};

                    if (saleRecords['type'] == 'sales_with_channel') {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            gross_sale: d.gross_sale,
                            state_tax: d.state_tax,
                            overshort: d.overshort,
                            gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            state_tax_master_account_id: d['state_tax_master_account_id'] && d['state_tax_master_account_id']['value'] && +d['state_tax_master_account_id']['value'] || null,
                            overshort_master_account_id: d['overshort_master_account_id'] && d['overshort_master_account_id']['value'] && +d['overshort_master_account_id']['value'] || null,
                        }
                    } else {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            gross_sale: d.gross_sale,
                            gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                        }
                    }
                    return data;
                });
            toRemoveItems.push(index)

            postData(records, toRemoveItems)
        } else {
            toast.error("Please select the record");
        }
    }
    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }
    const handleFieldValidation = async (index: number) => {
        // Trigger validation for the specified field within the array
        const isValidated = await trigger(`sales.${index}`);
        if (isValidated) {
            handleSinglePost(index)
        }
    };

    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
                const nextSelect = selectRefs.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };



    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Revenue Classification</h1>
                        </div>
                        <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.SALES_GL_CLASSIFICATION_STATUS} className="btn btn-link">Back To Classification Status</Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>

                        <Form >
                            {
                                fields.length > 0 && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>Post</button>
                                    </div> || <></>
                                )
                            }
                            <br></br>
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>

                                            <th className="text-center">Date</th>
                                            <th className="text-start">Description </th>
                                            <th className="text-end">Amount </th>
                                            <th className="text-start">GL Account </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <>{console.log(fields)}</> */}
                                        {

                                            fields.length > 0 ?


                                                fields.map((data: any, index: any) => {
                                                    // Find split data for the current row
                                                    if (!fields[index].account_type) {
                                                        return null; // Skip rendering this row if account_type is not set
                                                    }
                                                    const hasSplitData = splitData.some((split: any) => split.id === data.record_id && split.account_type === data.account_type);

                                                    // Extract split debit account IDs
                                                    const splitCreditedAccounts = splitData
                                                        .filter((split) => split.id === data.record_id && split.account_type === data.account_type)
                                                        .map((split) => split.master_chart_of_account_id.label)
                                                        .join(', ');

                                                    return (
                                                        <tr className="align-middle h-60" key={data['id']}>
                                                            <td className="text-center">
                                                                <div className="form-check ">
                                                                    <label>
                                                                        <input className="form-check-input wh-20 checkbox" {...register(`sales.${index}.checked`)}
                                                                            name={`sales.${index}.checked`}
                                                                            defaultChecked={fields[index]['checked']}
                                                                            type="checkbox" onChange={(e: any) => handleCheckAll(e, index, data.record_id)} />
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY h:mm:ss')}</strong></td>
                                                            <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>

                                                            <td className="text-end">{usdCurrencyFormat(fields[index].amount)}</td>
                                                            {

                                                                hasSplitData ? (

                                                                    <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitCreditedAccounts || 'NA'}>
                                                                        {splitCreditedAccounts}
                                                                    </td>
                                                                ) : (


                                                                    fields[index].account_type === 'Gross_Sale' ?
                                                                        (
                                                                            <td className="text-start">
                                                                                <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                    <Controller
                                                                                        control={control}

                                                                                        name={`sales.${index}.gross_sale_master_account_id`}
                                                                                        render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                            <AsyncSelect
                                                                                                isClearable={true}
                                                                                                placeholder={'Please select GL acc'}
                                                                                                defaultOptions={glSalesAccount}
                                                                                                loadOptions={filterSalesAccountList}

                                                                                                ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                                onChange={(e: any) => {
                                                                                                    onChange(e);
                                                                                                    handleSelectChange(index, e);
                                                                                                }}
                                                                                                className='vp-dropdown'
                                                                                                menuPosition='fixed'
                                                                                                value={value}
                                                                                                defaultValue={value}
                                                                                                isDisabled={fields[index].amount == 0 || !!fields[index].gross_sale_master_account_id}
                                                                                                styles={selectCustomStyle}
                                                                                                components={{ Option: SelectCustomOption }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Form.Group>
                                                                                {
                                                                                    errors && errors.sales && errors.sales[index] && errors.sales[index]?.gross_sale_master_account_id && errors.sales[index]?.gross_sale_master_account_id?.message && (
                                                                                        <Form.Text className="d-flex text-danger">
                                                                                            <strong>
                                                                                                {errors.sales[index]?.gross_sale_master_account_id?.message as ReactNode || ''}
                                                                                            </strong>
                                                                                        </Form.Text>
                                                                                    ) || ''
                                                                                }

                                                                            </td>
                                                                        ) : fields[index].account_type === 'State_Tax' ?
                                                                            (
                                                                                <td className="text-start">
                                                                                    <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                        <Controller
                                                                                            control={control}
                                                                                            //disabled={fields[index].overshort != 0 || fields[index].state_tax == 0 || !!fields[index].state_tax_master_account_id}
                                                                                            name={`sales.${index}.state_tax_master_account_id`}
                                                                                            render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                                <AsyncSelect
                                                                                                    isClearable={true}
                                                                                                    placeholder={'Please select GL acc'}
                                                                                                    defaultOptions={glExcludeSaleAccount}
                                                                                                    loadOptions={filterExceptSalesAccountList}

                                                                                                    ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                                    onChange={(e: any) => {
                                                                                                        onChange(e);
                                                                                                        handleSelectChange(index, e);
                                                                                                    }}
                                                                                                    className='vp-dropdown'
                                                                                                    menuPosition='fixed'
                                                                                                    defaultValue={value}
                                                                                                    isDisabled={fields[index].overshort != 0 || fields[index].state_tax == 0 || !!fields[index].state_tax_master_account_id}
                                                                                                    styles={selectCustomStyle}
                                                                                                    components={{ Option: SelectCustomOption }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    {
                                                                                        errors && errors.sales && errors.sales[index] && errors.sales[index]?.state_tax_master_account_id && errors.sales[index]?.state_tax_master_account_id?.message && (
                                                                                            <Form.Text className="text-danger d-flex">
                                                                                                <strong>
                                                                                                    {errors.sales[index]?.state_tax_master_account_id?.message as ReactNode || ''}
                                                                                                </strong>
                                                                                            </Form.Text>
                                                                                        ) || ''
                                                                                    }
                                                                                </td>
                                                                            ) : fields[index].account_type === 'Overshort' ?
                                                                                (
                                                                                    <td className="text-start">
                                                                                        <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                            <Controller
                                                                                                control={control}
                                                                                                //disabled={fields[index].overshort == 0 || !!fields[index].overshort_master_account_id}
                                                                                                name={`sales.${index}.overshort_master_account_id`}
                                                                                                render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                                    <AsyncSelect
                                                                                                        isClearable={true}
                                                                                                        placeholder={'Please select GL acc'}
                                                                                                        defaultOptions={glExcludeSaleAccount}
                                                                                                        loadOptions={filterExceptSalesAccountList}

                                                                                                        ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                                        onChange={(e: any) => {
                                                                                                            onChange(e);
                                                                                                            handleSelectChange(index, e);
                                                                                                        }}
                                                                                                        className='vp-dropdown'
                                                                                                        menuPosition='fixed'
                                                                                                        isDisabled={fields[index].overshort == 0 || !!fields[index].overshort_master_account_id}
                                                                                                        styles={selectCustomStyle}
                                                                                                        components={{ Option: SelectCustomOption }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </Form.Group>
                                                                                        {
                                                                                            errors && errors.sales && errors.sales[index] && errors.sales[index]?.overshort_master_account_id && errors.sales[index]?.overshort_master_account_id?.message && (
                                                                                                <Form.Text className="text-danger d-flex">
                                                                                                    <strong>
                                                                                                        {errors.sales[index]?.overshort_master_account_id?.message as ReactNode || ''}
                                                                                                    </strong>
                                                                                                </Form.Text>
                                                                                            ) || ''
                                                                                        }
                                                                                    </td>
                                                                                ) : ''




                                                                )
                                                            }
                                                            {
                                                                fields[index].account_type === 'Overshort' ? (

                                                                    <td></td>
                                                                ) : (
                                                                    <td>
                                                                        <Button variant="primary" disabled={fields[index].amount === 0} onClick={() => handleSplitClick(data, fields[index].record_id)}>
                                                                            {hasSplitData ? 'Modify Split' : 'Split Classification'}
                                                                        </Button>
                                                                    </td>
                                                                )
                                                            }



                                                        </tr>
                                                    );
                                                }) : <tr>
                                                    <td colSpan={5} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </Form>
                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={npage} /> */}
                    </div>
                </div>
            </div>
            <SplitModal
                rowID={selectedRecord?.record_id || 0}
                indexNo={selectedRecord?.formIndex || 0}
                show={showSplitModal}
                onHide={() => setShowSplitModal(false)}
                onSave={handleSplitSave}
                originalAmount={selectedRecord?.amount || 0}
                glSalesAccount={selectedRecord?.account_type === 'Gross_Sale' ? glSalesAccount : glExcludeSaleAccount}
                transactionAt={selectedRecord?.transaction_at || ''}
                description={selectedRecord?.description || ''}
                splitData={splitData}
                account_type={selectedRecord?.account_type || ''}
                filterSalesAccountList={selectedRecord?.account_type === 'Gross_Sale' ? filterSalesAccountList : filterExceptSalesAccountList}

            />
        </div>

    )
}

export default SalesGlClassification;