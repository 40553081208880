import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useSettingsApi() {

    const callApi = useApiCall();

    /*const fetchSettingsData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.FETCH_DETAILS, data, onSuccess, onError);
    }*/
    const updateAccountingSettings = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.UPDATE_ACCOUNTING, data, onSuccess, onError);
    }
    const updateSalesSettings = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.UPDATE_SALES, data, onSuccess, onError);
    }
    const fetchAccountSettingsData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.FETCH_ACCOUNT_DETAILS, data, onSuccess, onError);
    }
    const upgradeToLatestGL = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SETTINGS.UPGRADE_TO_LATEST_GL, data, onSuccess, onError);
    }


    return {
        // fetchSettingsData,
        updateAccountingSettings,
        updateSalesSettings,
        fetchAccountSettingsData,
        upgradeToLatestGL
    }
}