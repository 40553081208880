import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { getMappedAcc } from '../../store/user/selectors'
import { getOpeningBalanceExistsSettings } from '../../store/settings/selectors'

export function openingBalanceExixts() {

  const openingBalance = useSelector(getOpeningBalanceExistsSettings);

  const balanceChecker = () => {
    return openingBalance;
  }
  const [openingBalanceExists, setOpeningBalanceExists] = useState(balanceChecker())

  useEffect(() => {
    setOpeningBalanceExists(balanceChecker())
  }, [openingBalance]);

  return openingBalanceExists
}