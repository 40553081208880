

import { FC, useEffect, useState, useRef, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './ChartAccountModal.css'

import { CAFormValidate } from '../../../resources/form-validator';
import { CA_REPORT, CA_ACC_TYPE } from '../../../resources/constants';
import closeBtn from '../../../assets/images/close-btn.svg';
import { CreateChartAccountFormI } from '../../../resources/form-props';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

interface Props {
    closeModal: () => void,
    addRecord: boolean,
    record?: any
}

interface Props1 {
    options: any,
    name: any
}
const RecursiveSelect: FC<Props1> = ({ options, name }) => {
    //console.log(options);

    return (

        options.map((option: any) => (

            <>
                <option value={option.id}>{option.description}</option>
                {option.child && option.child.length > 0 && (
                    <RecursiveSelect options={option.child} name={name} />
                )}
            </>
        ))

    );
};
const ChartAccountModal: FC<Props> = ({ closeModal, addRecord, record = null }) => {

    const { chartAvailableAccounts, createChart, updateChart, getAccountLebel } = useChartAccountApi();

    const branchData = useSelector(getBranch)

    const { control, register, watch, handleSubmit, getValues, resetField, reset, setValue, formState: { errors } } = useForm<CreateChartAccountFormI>({
        defaultValues: {
            //groupHeader: null,
            //subHeader: null,
            //parent_id: null,
            id: null,
            revrex_account_id: null,
            account: "",
            report_code: "VP",
            parenthisys_control: CA_ACC_TYPE[0],
            financial_statement: CA_REPORT[0],
            debit: '+',
            credit: '-',
            name: '',
            description: ''
        },
        resolver: yupResolver(CAFormValidate)
    });

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showPageLoader, setShowPageLoader] = useState<boolean>(false);
    const [accountNumbers, setAccountNumbers] = useState<any[]>([]);
    const [accountDetails, setAccountDetails] = useState<any>({
        label: null,
        accountType: CA_ACC_TYPE,
        report: CA_REPORT
    });

    useEffect(() => {
        fetchAvailableAccounts();
    }, [])

    useEffect(() => {
        if (record) {
            const selectedReport = CA_REPORT.filter(e => e['value'] == record['financial_statement']);
            const selectedAccType = CA_ACC_TYPE.filter(e => e['value'] == record['parenthisys_control'])

            if (record['account']) {
                fetcAccountLebel(record['account'])
            }

            reset({
                id: record['id'],
                revrex_account_id: record['revrex_account_id'],
                account: record['account'] && {
                    label: record['account'],
                    value: record['account']
                } || null,
                report_code: record['report_code'] || 'VP',
                parenthisys_control: selectedAccType.length && selectedAccType[0] || CA_ACC_TYPE[0],
                financial_statement: selectedReport.length && selectedReport[0] || CA_REPORT[0],
                debit: record['debit'],
                credit: record['credit'],
                name: record['name'],
                description: record['description']
            })
        }
    }, [record])

    const fetchAvailableAccounts = (): Promise<any[]> => {
        return new Promise<any[]>((resolve, reject) => {
            const params = {
                branch_id: +branchData['id'],
                available_account: addRecord ? false : true
            };
            chartAvailableAccounts(params, (message: string, resp: any) => {
                const data = resp.data.data.map((e: any) => ({
                    label: e,
                    value: e
                }));
                setAccountNumbers(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                // return resolve([])
            })

        })
    }

    const fetcAccountLebel = (account: any): Promise<any[]> => {
        return new Promise<any[]>((resolve, reject) => {
            const params = {
                account: account,
                branch_id: +branchData['id']
            };

            getAccountLebel(params, (message: string, resp: any) => {
                if (resp.data.data) {
                    const label = `${resp.data.data.label} > ${account}`;
                    const accounType = CA_ACC_TYPE.filter((e: any) => e['value'] === resp.data.data['account_type']);
                    const fStatement = CA_REPORT.filter((e: any) => e['value'] === resp.data.data['report_code']);
                    // const report_code = resp.data.data['account_chart']['report_code'];

                    if (fStatement.length) {
                        setValue("financial_statement", fStatement && fStatement[0] || null)
                    }
                    if (accounType.length) {
                        setValue("parenthisys_control", accounType && accounType[0] || null)
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['name'] ) {
                        setValue("name", resp.data.data['account_chart']['name'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['description'] ) {
                        setValue("description", resp.data.data['account_chart']['description'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['revrex_account_id'] ) {
                        setValue("revrex_account_id", resp.data.data['account_chart']['revrex_account_id'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['debit'] ) {
                        setValue("debit", resp.data.data['account_chart']['debit'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['credit'] ) {
                        setValue("credit", resp.data.data['account_chart']['credit'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['id'] ) {
                        setValue("id", resp.data.data['account_chart']['id'])
                    }
                    if ( resp.data.data['account_chart'] && resp.data.data['account_chart']['report_code'] ) {
                        setValue("report_code", resp.data.data['account_chart']['report_code'])
                    }

                    setAccountDetails({
                        label,
                        accountType: accounType.length ?  accounType : CA_ACC_TYPE,
                        report: fStatement.length ? fStatement : CA_REPORT
                        
                    });
                }

                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.available_list);
            }, (message: string, resp: any) => {
                toast.error(message);
                // return resolve([])
            })
        })
    }

    const processData = (values: any) => {
        const params: any = {
            //parent_id: values['parent_id'] && +values['parent_id']['value'] || null,
            account: values['account'] && +values['account']['value'] || null,
            report_code: values['report_code'],
            parenthisys_control: values['parenthisys_control'] && values['parenthisys_control']['value'] || null,
            financial_statement: values['financial_statement'] && values['financial_statement']['value'] || null,
            name: values['name'],
            description: values['description'],
            branch_id: +branchData['id']
        }

        if ( !addRecord ) {
            setShowLoader(true);
            params['id'] = values['id'];
            updateChart(params, (message: string, resp: any) => {
                setShowLoader(false);
                toast.success(message)
                _handleCloseModal();
                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        } else {
            setShowLoader(true);
            //console.log(params);
            createChart(params, (message: string, resp: any) => {
                setShowLoader(false);
                toast.success(message)
                _handleCloseModal();
                // setGHList(resp.data.data.result);
                // return resolve(resp.data.data.result);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
                // return resolve([])
            })
        }
    }

    const _handleCloseModal = () => {
        reset();
        closeModal();
    }

    return (
        <Modal show={true} onHide={_handleCloseModal} backdrop="static" animation={false} size="lg">
            {/* // <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
            <div className="">
                <div className={`page-loader ${showPageLoader ? 'visible' : ''}`}>
                    <div className="loader"></div>
                </div>
                <div className="">
                    <Form onSubmit={handleSubmit(processData)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{addRecord ? 'Create New Account' : 'Update Account'}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={_handleCloseModal}><img src={closeBtn} alt="" /></button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row align-items-center">

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">{addRecord ? 'Available Account Numbers' : 'Select COA Number'}<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                // <AsyncSelect
                                                //     {...field}
                                                //     loadOptions={loadAccNumberOptions}
                                                //     defaultOptions={accountNumbers}
                                                // />
                                                <Select
                                                    placeholder={'ACC Number'}
                                                    onChange={(e: any) => {
                                                        const param = {
                                                            target: {
                                                                name: 'account',
                                                                value: e
                                                            }
                                                        }
                                                        onChange(param)
                                                        fetcAccountLebel(e.value)
                                                        setAccountDetails({
                                                            label: null,
                                                            accountType: CA_ACC_TYPE,
                                                            report: CA_REPORT
                                                        });
                                                    }}
                                                    //onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                    options={accountNumbers}
                                                    isDisabled={getValues('revrex_account_id') != null}
                                                />
                                            )}
                                            control={control}
                                            name="account"
                                        />
                                        {
                                            errors && errors['account'] && errors['account'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['account'].message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group mb-0">
                                        <p className="lebel  mb-0"><b>{accountDetails['label']}</b></p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Report<strong className='text-danger'>*</strong></label>
                                        <input type='text' value={accountDetails['report'][0]['label']} disabled className='form-control' />
                                        {/* <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder={'Report'}
                                                    options={accountDetails['report']}
                                                    defaultValue={accountDetails['report']}
                                                />
                                            )}
                                            control={control}
                                            name="financial_statement"
                                        /> */}
                                        {/* {
                                            errors && errors['financial_statement'] && errors['financial_statement'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors?.financial_statement?.message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        } */}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Account Type<strong className='text-danger'>*</strong></label>
                                        <input type='text' value={accountDetails['accountType'][0]['label']} disabled className='form-control' />
                                        {/* <Controller
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder={'Account Type'}
                                                    defaultValue={accountDetails['accountType']}
                                                    options={accountDetails['accountType']}
                                                />
                                            )}
                                            control={control}
                                            name="parenthisys_control"
                                        /> */}
                                        {/* {
                                            errors && errors['parenthisys_control'] && errors['parenthisys_control'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors.parenthisys_control.message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        } */}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Name<strong className='text-danger'>*</strong></label>
                                        <input {...register("name")} placeholder='Name' className='form-control' />
                                        {
                                            errors && errors['name'] && errors['name'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors?.name?.message}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="info-box">
                                <p>Use <strong>Checking</strong> accounts to track all your checking activity, including debit card transactions.</p>
                                <p>Each checking account your company has at a bank or other financial institution should have its own Checking type account in RevRex Online Simple Start.</p>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <Controller
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    className="form-control"
                                                    placeholder="Enter Descriptions"
                                                />
                                            )}
                                            control={control}
                                            name="description"
                                        />
                                        {
                                            errors && errors['description'] && errors['description'].message && (
                                                <Form.Text className="text-danger">
                                                    {errors['description'].message}
                                                </Form.Text>
                                            ) || ''
                                        }
                                        {/* <textarea name="" id="" className="form-control" placeholder="Enter Descriptions"></textarea> */}
                                    </div>
                                </div>
                                {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="container-checkbox">Is sub-account
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <select name="" id="" className="form-select">
                                                <option value="">Enter Parent Account</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="">Choose Option</label>
                                            <select name="" id="" className="form-select">
                                                <option value="">Please Select</option>
                                            </select>
                                        </div>
                                    </div> */}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-cancel" onClick={_handleCloseModal}>Close</button>
                            <button type="submit" className="btn btn-primary">
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save changes'}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            {/* </div> */}
        </Modal>
    )
}

export default ChartAccountModal;