
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReportApi } from '../../resources/hooks/api/useReportApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS } from '../../resources/constants';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import { currencyFormat } from '../../resources/helper';

import {getBranch} from '../../store/user/selectors'

interface Props {

}

const StatementOfCashFlow: React.FC<Props> = () => {
    const { cashFlowData } = useReportApi();
    const [year, setYear] = useState<any>(2024);
    const [searchParams, setSearchParams] = useState<any>({
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [months, setMonths] = useState<any>({});
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [cashMonthList, setcashMonthList] = useState<any[]>([]);
    const [cashFlowList, setcashFlowList] = useState<any[]>([]);
    const [cashInData, setCashInData] = useState<any[]>([]);
    const [cashInHand, setCashInHand] = useState<any[]>([]);
    const [cashInTotal, setCashInTotal] = useState<any[]>([]);
    const [cashOutData, setCashOutData] = useState<any[]>([]);
    const [cashOutTotal, setCashOutTotal] = useState<any[]>([]);

    const branchData = useSelector(getBranch)

    useEffect(() => {
        const months = MONTHS.reduce((a: any, c: any) => {
            a = {
                ...a,
                [+c['value']]: c['label']
            }
            return a;
        }, {})
        setMonths(months);
        fetchCashFlow();
    }, [])
    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(78));
    })


    const fetchCashFlow = () => {
        const params: any = {
            "page": 1,
            "per_page": 5000,
            year: searchParams.year['value'],
            branch_id: +branchData['id']
        }
        setYear(searchParams.year['value']);

        setIsLoading(true);
        cashFlowData(params, (message: string, resp: any) => {
            setIsLoading(false);
            if (resp && resp['data'] && resp['data']['data']) {
                setcashFlowList(resp.data.data);
                setcashMonthList(resp.data.data.month_list);
                setCashInData(resp.data.data.cash_in_data);
                setCashInHand(resp.data.data.cash_in_hand);
                setCashInTotal(resp.data.data.cash_in_total);
                setCashOutData(resp.data.data.cash_out_data);
                setCashOutTotal(resp.data.data.cash_out_total);
            } else {
                setcashMonthList([]);
                setcashFlowList([]);
                setCashInData([]);
                setCashInHand([]);
                setCashInTotal([]);
                setCashOutData([]);
                setCashOutTotal([]);
            }

        }, (message: string, resp: any) => {
            setcashMonthList([]);
            setcashFlowList([]);
            setCashInData([]);
            setCashInHand([]);
            setCashInTotal([]);
            setCashOutData([]);
            setCashOutTotal([]);
            setIsLoading(false);
            toast.error(message);
        })
    }


    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Statement of Cash Flow</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap">

                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchCashFlow} className="btn-primary btn btn-lg">Generate Report &nbsp; <i className="fa fa-search" aria-hidden="true"></i></button>
                                </div>
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                        <table className="table table-default">

                            <thead>
                                <tr className="align-middle">
                                    <th className="text-center">ACC#</th>
                                    <th className="text-center ">CASH IN:</th>
                                    {cashMonthList.map((eachRecord: string, index: number) => (
                                        <th key={index} className="text-center">{months[+eachRecord.split('-')[0]]}</th>
                                    ))}
                                </tr>
                            </thead>
                            {
                                Object.keys(cashFlowList).length && (
                                    <>
                                        <tbody>
                                            {cashInData.map((data: any, index: number) => (
                                                <tr className="align-middle" key={index}>
                                                    <td className="text-center">{data.account_chart.account || 'NA'}</td>
                                                    <td className="text-left">{data.account_chart.name}</td>
                                                    {cashMonthList.map((month: any, monthIndex: number) => (
                                                        <td key={monthIndex} className="text-center">
                                                            {(data?.details?.[month] || 0).toFixed(2)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tbody>
                                            <tr className="align-middle">
                                                <td className="text-center"></td>
                                                <td className="text-center" style={{ fontWeight: 600 }}>TOTAL CASH:</td>
                                                {cashMonthList.map((month: any, index: number) => (
                                                    <td key={index} className="text-center" style={{ fontWeight: 600 }}>
                                                        {(cashInTotal?.[month] ?? 0).toFixed(2)}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>

                                        <thead>
                                            <tr>
                                                <th className="text-center">ACC#</th>
                                                <th className="text-center ">CASH OUT:</th>
                                                {cashMonthList.map((eachRecord: string, index: number) => (
                                        <th key={index} className="text-center">{months[+eachRecord.split('-')[0]]}</th>
                                    ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cashOutData.map((data: any, index: number) => (
                                                <tr className="align-middle" key={index}>
                                                    <td className="text-center">{data.account_chart.account}</td>
                                                    <td className="text-left">{data.account_chart.name}</td>
                                                    {cashMonthList.map((month: any, monthIndex: number) => (
                                                        <td key={monthIndex} className="text-center">
                                                            {(data?.details?.[month] || 0).toFixed(2)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tbody>
                                            <tr className="align-middle" style={{ borderBottom: "2px solid black" }}>
                                                <td className="text-center"></td>
                                                <td className="text-center" style={{ fontWeight: 600 }}>TOTAL CASH OUT:</td>
                                                {cashMonthList.map((month: any, index: number) => (
                                                    <td key={index} className="text-center" style={{ fontWeight: 600 }}>
                                                        {(cashOutTotal?.[month] ?? 0).toFixed(2)}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr className="align-middle" style={{ borderBottom: "2px solid black" }}>
                                                <td className="text-center"></td>
                                                <td className="text-center" style={{ fontWeight: 600 }}>CASH ON HAND:</td>
                                                {cashMonthList.map((month: any, index: number) => (
                                                    <td key={index} className="text-center" style={{ fontWeight: 600 }}>
                                                        {(cashInHand?.[month] ?? 0).toFixed(2)}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </>
                                ) || (
                                    <tr>
                                        <td colSpan={12} className='text-center'>No data found</td>
                                    </tr>

                                )
                            }
                        </table>


                    </div>

                </div>
            </div>
        </div>

    )
}

export default StatementOfCashFlow;