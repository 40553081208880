import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrimarySalesAccountSettings, getPrimaryStateTaxAccountSettings } from '../../store/settings/selectors';
import { getBranch } from '../../store/user/selectors';
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import SideNavbar from '../../layouts/SideNavbar';
import pencilIcon from '../../assets/images/pencil-icon.svg';
import SalesAccountingModal from '../../components/Modal/Sales';
import toast from 'react-hot-toast';

interface Props { }

const Sales: React.FC<Props> = () => {
    //const dispatch = useDispatch();
    const [showAccountingModal, setShowAccountingModal] = useState<boolean>(false);
    const [accountingSettings, setAccountingSettings] = useState<any>(null);
    const branchData = useSelector(getBranch);
    const saleAccount = useSelector(getPrimarySalesAccountSettings);
    const stateTaxAccount = useSelector(getPrimaryStateTaxAccountSettings);
    const [modalType, setModalType] = useState<string>('');
    const { fetchAccountSettingsData } = useSettingsApi();

    useEffect(() => {
        const fetchData = async () => {
            fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {
            }, (msg: string, resp: any) => {
                toast.error(msg);
            })

        };

        fetchData();
    }, [branchData, showAccountingModal]);

    const openModal = (type: string) => {
        setModalType(type);
        setShowAccountingModal(true);
    };

    const closeModal = () => {
        setShowAccountingModal(false);
    };

    return (
        <div className="main-container flex-grow-1">
            <div className="two-col-wrap">
                <SideNavbar />
                <div className="right-side-wrap">
                    <div className="page-title pb-4">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <h1 className="h3 font-weight-700 mb-0">Sales</h1>
                            </div>
                        </div>
                    </div>
                    <div className="white-body-wrap">
                        <div className="profile-box">
                            <h3 className="profile-title">
                                <em>Accounting</em>
                                <span onClick={() => openModal('state_tax')}>
                                    <img src={pencilIcon} alt="Edit" />
                                </span>
                            </h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Default State Tax GL Account</h4>
                                        <p> {stateTaxAccount ? `${stateTaxAccount.name} (${stateTaxAccount.account})`
                                            : 'NA'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-box">
                            <h3 className="profile-title"><em>Gross Sale</em>
                                <span onClick={() => openModal('gross_sale')}>
                                    <img src={pencilIcon} alt="Edit" />
                                </span>
                            </h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group-text">
                                        <h4>Default Gross Sale Gl Account</h4>
                                        <p> {saleAccount ? `${saleAccount.name} (${saleAccount.account})`
                                            : 'NA'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showAccountingModal && (
                <SalesAccountingModal saleAccount={saleAccount} stateTaxAccount={stateTaxAccount} closeModal={closeModal} type={modalType} />
            )}
        </div>
    );
};

export default Sales;
