import { Outlet, Navigate } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { useAuthStatus } from '../../resources/hooks/authHook'

const PrivateRoutes = () => {
    const isAuthenticated = useAuthStatus();

    return(
        isAuthenticated ? <Outlet/> : <Navigate to={URLS.LOGIN} />
    )
}

export default PrivateRoutes