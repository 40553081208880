import { call, put } from "redux-saga/effects";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* list(action: any): any {
    try {
      const payload = action.payload;
      const url = API_URLS.VIEW_REGISTER.FETCH_LIST + '?' + formQueryString(payload);
      let result = yield call(CallApi.GET, url,{},true);
      //yield put(updateUser(result.data));
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
}