import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import toast from 'react-hot-toast';

import { currencyFormat } from '../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import Card from 'react-bootstrap/Card';
import { ReconcileFormI, RevenueGlClassificationFormI } from '../../resources/form-props';
import { glClassificationApi } from '../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, BankRevenueValidate, BankReconcileValidate } from '../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../store/user/selectors'

import '../GlClassification/GlClassification.css'
import { GL_VP, MONTHS } from '../../resources/constants';
import { lastYears, calculateMaxHeightContent, usdCurrencyFormat } from '../../resources/functions';

interface Props {

}

const BankReconcileTxn: React.FC<Props> = () => {

    const { glReconcileTransactionSubmit, glReconcileTransactionlist } = glClassificationApi();
    const routeParams = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    const [reconciliationData, setReconciliationData] = useState<any>(null);
    const [bankData, setBankData] = useState<any>({
        bank_name: '',
        bank_number: ''
    });
    
   
    const [formParams, setFormParams] = useState<any>({
        glAccount: null,
        beginningBalance: 0,
        endingBalance: 0,
        day: new Date().getDay(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        endDate: new Date(),
        expense_amount: 0,
        revenue_amount: 0,
        cleared_balance_amount: 0,
        difference_amount: 0,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const checkAllRef = useRef<HTMLInputElement>(null);

    const { register, control, trigger, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<ReconcileFormI>({
        defaultValues: {
            reconciles: []
        },
        resolver: yupResolver(BankReconcileValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "reconciles"
    });

    useEffect(() => {
        const data: any = {};
        const openingBalance: string = searchParams.get('beginningBalance') ? searchParams.get('beginningBalance')! : '0';
        const endingBalance: string = searchParams.get('endingBalance') ? searchParams.get('endingBalance')! : '0';

        if (routeParams['coa']) {
            data['glAccount'] = routeParams['coa']
        }
        if ( openingBalance && !isNaN(+openingBalance) ) {
            data['beginningBalance'] = +openingBalance
        }
        if ( endingBalance && !isNaN(+endingBalance) ) {
            data['endingBalance'] = +endingBalance
        }

        if (searchParams.get('endDate')) {
            data['endDate'] = moment(searchParams.get('endDate')).format('YYYY-MM-DD')
            data['day'] = moment(searchParams.get('endDate')).format('DD')
            data['month'] = moment(searchParams.get('endDate')).format('MM')
            data['year'] = moment(searchParams.get('endDate')).format('YYYY')
        }

        setFormParams((prevState: any) => ({
            ...prevState,
            ...data
        }))

    }, [routeParams, searchParams])


    useEffect(() => {
        if (formParams['glAccount']) {
            fetchTransactionList();
        }
    }, [formParams['glAccount']])

    useEffect(() => {
        records.map((record: any, index: number) => {
            formAppend({
                formIndex: index,
                checked: true,
                id: record['id'],
                transaction_at: record['transaction']['transaction_at'],
                account_type: record['account_type'],
                description: record['transaction']['description'],
                posted: record['transaction']['updated_at'] ? true : false,
                account_value: record['account_value'],
                reconciled_id: record['reconciled_id']
            })
        })

        calculateSum()
    }, [records])


    useEffect(() => {
        if( reconciliationData ){
            const data: any = {};

            data['beginningBalance'] = reconciliationData.opening_balance_amount
            data['endingBalance'] = reconciliationData.closing_balance_amount
            data['cleared_balance_amount'] = reconciliationData.cleared_balance_amount
            data['difference_amount'] = reconciliationData.difference_amount

            setFormParams((prevState: any) => ({
                ...prevState,
                ...data
            }))
        }
       
    }, [reconciliationData])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
        if (fields.length == records.length) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = true;
            }
        }
    }, [fields])

    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            is_updated: 1,
            is_not_reconciled: 1,
            account_number: formParams['glAccount'],
            branch_id: +branchData['id'],
            day: formParams['day'],
            month: formParams['month'],
            year: formParams['year']
        }

        setIsLoading(true);
        glReconcileTransactionlist(params, (message: string, resp: any) => {
            reset();
            setRecords(resp.data.data.items.result);
            setReconciliationData(resp.data.data.reconciliation);
            setBankData({
                bank_name: resp.data.data.bank_name,
                bank_number: resp.data.data.bank_number
            });
            setIsLoading(false);

        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            records.map((record: any, index: number) => {
                setValue(`reconciles.${index}.checked`, e.target.checked)
            })

            calculateSum();
        }
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`reconciles.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = records.filter((record: any, index: number) => getValues(`reconciles.${index}.checked`) === true);

            if (checkedRecords.length == records.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = records.filter((record: any, index: number) => getValues(`reconciles.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }

        calculateSum();
    }

    const calculateSum = () => {
        
        setIsLoading(true);
        const sumAmount = records.reduce((a: any, c: any, index: number) => {
            if ( getValues(`reconciles.${index}.checked`) ) {
                if ( c['txn_type'] == 1 && c['transaction']['description'] != "Opening Balance" ) { // debit
                    a['revenue'] += +c['account_value'];
                } else if ( c['txn_type'] == 2 ) { // credit
                    a['expense'] += +c['account_value'];
                }
            }
            return a;
        }, {revenue: 0, expense: 0})
        
        setFormParams((prevState: any) => ({
            ...prevState,
            expense_amount: sumAmount['expense'],
            revenue_amount: sumAmount['revenue'],
            cleared_balance_amount: +prevState['beginningBalance'] - sumAmount['expense'] + sumAmount['revenue'],
            difference_amount: +prevState['beginningBalance'] - sumAmount['expense'] + sumAmount['revenue'] - +prevState['endingBalance']
        }))
        setIsLoading(false);
    }

    const processData = (data: any) => {
        if ( formParams['difference_amount'].toFixed(2) == 0 ) {
            const formData = {
                gl_account_number: formParams['glAccount'],
                // end_date_at: moment(formParams['endDate']).endOf('day').utc().format(),
                end_date_at: moment(formParams['endDate']+' 11:59:59').format(),
                "opening_balance_amount": +formParams['beginningBalance'].toFixed(2),
                "expense_amount": +formParams['expense_amount'].toFixed(2),
                "revenue_amount": +formParams['revenue_amount'].toFixed(2),
                "cleared_balance_amount": +formParams['cleared_balance_amount'].toFixed(2),
                "closing_balance_amount": +formParams['endingBalance'].toFixed(2),
                "difference_amount": +formParams['difference_amount'].toFixed(2),
                "account_ids": []
            }
            const records = data['reconciles']
                .map((d: any, index: number) => {
                    if (d['checked']) {
                        return d.id
                    } else {
                        return null;
                    }
                }).filter((e: any) => e);
    
            formData['account_ids'] = records;
            postData(formData)
        } else {
            toast.error(`You can't reconcile the bank account as the difference is not zero`)
        }
    }
    const postData = (formData: any) => {
        setIsLoading(true);
        glReconcileTransactionSubmit({formData, branch_id: +branchData['id']}, (message: string, resp: any) => {
            setIsLoading(false);
            toast.success(message)
            if ( records.length == fields.length ) {
                navigate(-1);
            }
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Transaction List</h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Card>
                            <Card.Body>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <span><strong>Statement Ending Date:</strong> {moment(formParams['endDate']).format('LL')}, <strong>
                                        {bankData['bank_name'] ? `${bankData['bank_name']} ( ${bankData['bank_number']} )` : 'NA'}</strong></span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-8 d-flex justify-content-between">
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong>Opening</strong>
                                            <span>{usdCurrencyFormat(+formParams['beginningBalance'])}</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong title='Withdrawals and other Debits, Checks, or Service Fees'>Withdrawals</strong>
                                            <span>{usdCurrencyFormat(+formParams['expense_amount'])}</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong title='Deposits and Additions'>Deposit</strong>
                                            <span>{usdCurrencyFormat(+formParams['revenue_amount'])}</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong>Cleared Balance</strong>
                                            <span>{usdCurrencyFormat(+formParams['cleared_balance_amount'])}</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong>Closing Balance</strong>
                                            <span>{usdCurrencyFormat(+formParams['endingBalance'])}</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <strong>Difference</strong>
                                            <span>{formParams['difference_amount'] < 0 ? `(${usdCurrencyFormat(Math.abs(+formParams['difference_amount']))})` :  usdCurrencyFormat(+formParams['difference_amount'])}</span>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Form>
                            {
                                fields.length > 0  && !reconciliationData && (
                                    <div className="modal-footer mt-3">
                                        <button type="button" className="btn btn-secondary" onClick={handleSubmit(processData)}>Reconcile</button>
                                    </div> || <></>
                                )
                            }

                            <br></br>
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" disabled={fields.length > 0 && fields[0].reconciled_id ? true : false} onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>
                                            <th className="text-center w-5">Date</th>
                                            <th className="text-start w-5">Type </th>
                                            <th className="text-start">Description </th>
                                            <th className="text-start w-5">Posted </th>
                                            <th className="text-end w-5">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => (
                                                    <tr className="align-middle" key={data['id']}>
                                                        <td className="text-center">
                                                            <div className="form-check ">
                                                                <label >
                                                                    <input className="form-check-input wh-20 checkbox" {...register(`reconciles.${index}.checked`)}
                                                                        name={`revenues.${index}.checked`}
                                                                        defaultChecked={fields[index]['checked']}
                                                                        disabled={data.reconciled_id ? true : false}
                                                                        type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="text-start"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY')}</strong></td>
                                                        <td className="text-start">{fields[index].account_type || 'NA'}</td>
                                                        <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>
                                                        <td className="text-start">{fields[index].posted ? 'Yes' : 'No'}</td>
                                                        <td className="text-end">{usdCurrencyFormat(fields[index].account_value)}</td>
                                                    </tr>
                                                )) : <tr>
                                                    <td colSpan={13} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default BankReconcileTxn;