import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserI } from './types'

export const initialState: UserI = {
  details: {},
  branchDetails: {},
  isAuthenticated: false,
  accessToken:null,
  /*mappedAccount: {
    sales_activity_match: true,
    bank_account_match: true
  },
  fisrtLoginCheck : 0,*/
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state: UserI, action: PayloadAction<any>) {
      state.details = action.payload
    },
    updateBranch(state: UserI, action: PayloadAction<any>) {
      state.branchDetails = action.payload
    },
    authenticateUser(state: UserI, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    updateAccessToken(state: UserI, action: PayloadAction<any>) {
      state.accessToken = action.payload
    },
    /*updateMappedAcc(state: UserI, action: PayloadAction<any>) {
      state.mappedAccount = {
        ...state.mappedAccount,
        ...action.payload
      }
    },
    updateFirstLogin(state: UserI, action: PayloadAction<any>) {
      state.fisrtLoginCheck = action.payload
    },*/
  },
})

export const { reducer } = userSlice

export const { updateUser, updateBranch, authenticateUser,updateAccessToken, /*updateMappedAcc, updateFirstLogin*/ } = userSlice.actions
