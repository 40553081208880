import React from 'react';
import SweetAlert2 from 'react-sweetalert2';
import SweetAlertIcon from 'react-sweetalert2'

type SAlertIcon = 'warning' | 'error' | 'success' | 'info' | 'question'

interface Props {
  msg?: string, 
  show: boolean, 
  confirmBtnText?: string, 
  cancelBtnText?: string, 
  confirmBtnBsStyle?: string,
  cancelBtnBsStyle?: string, 
  title: string, 
  onConfirm: () => void,
  onCancel: () => void,
  showCancel?: boolean,
  icon?: SAlertIcon,
}
const SAlert: React.FC<Props> = ({
    msg, show, confirmBtnText, cancelBtnText, confirmBtnBsStyle, cancelBtnBsStyle, title, onConfirm, onCancel, showCancel, icon
}) => {
    return (
      <SweetAlert2
        show={show}
        icon={icon || 'warning'}
        showCancelButton={showCancel || false}
        confirmButtonText={confirmBtnText || 'Yes'}
        cancelButtonText={cancelBtnText || "No"}
        confirmButtonColor={confirmBtnBsStyle || 'primary'}
        cancelButtonColor={cancelBtnBsStyle || "secondary"}
        title={title}
        text={msg}
        onConfirm={onConfirm}
        onResolve={onCancel}
        onError={onCancel}
        focusCancel={false}
        backdrop={false}
      />
    );
}

export default SAlert;
