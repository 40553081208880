import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import { URLS } from '../../resources/constants'
import { getUser } from '../../store/user/selectors'
import { getBankAccountMatchSettings, getOpeningBalanceExistsSettings, getAccountSettings } from '../../store/settings/selectors'
// import { getFirstLoginStatus } from '../../store/settings/selectors'
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import ChartAccountModal from '../../components/Modal/ChartOfAccount/ChartAccountModal'
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { Link } from 'react-router-dom'

import RevrexSyncGLAcc from '../../components/Modal/RevrexSyncGLAcc/RevrexSyncGLAcc';
import revenueReconciliationIcon from '../../assets/images/revenue-reconciliation-icon-w.svg';
import expenseClassificationIcon from '../../assets/images/expense-classification-icon-w.svg';
import exitStrategyIcon from '../../assets/images/exit-strategy-icon-w.svg';
import reportIcon from '../../assets/images/report-icon-w.svg';

import { getBranch } from '../../store/user/selectors'
import SAlert from 'components/SAlert';
import toast from 'react-hot-toast';


interface Props {

}

const Dashboard: React.FC<Props> = () => {
    //   const _records = useSelector(getTableDataRecords)
    //   const _users = useSelector(getUser)
    const _getBankAccountMatchSettings = useSelector(getBankAccountMatchSettings);
    const _getOpeningBalanceExistsSettings = useSelector(getOpeningBalanceExistsSettings);
    const _getAccountSettings = useSelector(getAccountSettings);
    const userData = useSelector(getUser)
    // const { userAllowance, userStepStatus } = useUserApi();
    const { fetchAccountSettingsData, upgradeToLatestGL } = useSettingsApi();
    // const { dashboardRaioData } = useReportApi();
    // const _userSteps = useSelector(getFirstLoginStatus);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCOAModal, setShowCOAModal] = useState<boolean>(false)
    const [createNewAccount, setCreateNewAccount] = useState<boolean>(false);
    const [confirmationToLatest, setConfirmationToLatest] = useState<boolean>(false);
    // const [dashboardRatio, setDashboardRatio] = useState<any | null>(null);
    const [modalSyncGl, setModalSyncGl] = useState<any>({
        syncBankAcc: false,
        syncSalesAcc: false
    });

    const branchData = useSelector(getBranch)

    useEffect(() => {
        // fetchAccMapping();
        getSettingsData();
        // fetchUserSteps();
        // getDashboardRatioData();
    }, [])

    /*const fetchAccMapping = () => {
        userAllowance({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
        }, (message: string, resp: any) => {

        })
    }

    const fetchUserSteps = () => {
        setIsLoading(true);
        userStepStatus({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);

        })
    }*/

    const getSettingsData = () => {
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

        }, (msg: string, resp: any) => {

        })
    }

    const upsertCOA = (addNew: boolean) => {
        setCreateNewAccount(addNew);
        setShowCOAModal(true);
    }

    const hideCOAModal = () => {
        setShowCOAModal(false);
    }

    // const getDashboardRatioData = () => {
    //     setIsLoading(true);
    //     dashboardRaioData({ year: new Date().getFullYear(), branch_id: +branchData['id'] }, (msg: string, resp: any) => {
    //         setIsLoading(false)
    //         setDashboardRatio(resp.data.data);
    //     }, (msg: string, resp: any) => {
    //         setIsLoading(false)
    //     })
    // }

    const updateSyncGlModal = (params: any) => {
        setModalSyncGl((prevState: any) => {
            return {
                ...prevState,
                ...params
            }
        })
    }

    const confirmUpdationToLatest = () => {
        setIsLoading(true);
        upgradeToLatestGL({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {
            setIsLoading(false);
            toast.success(resp?.data?.message || msg);
        }, (msg: string, resp: any) => {
            setIsLoading(false);
            toast.error(resp?.data?.message || msg);
        })
    }

    const closeSyncModal = () => {
        setModalSyncGl({
            syncBankAcc: false,
            syncSalesAcc: false
        })
        getSettingsData();
        // fetchAccMapping();
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title py-4">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <h1 className="h3 font-weight-700  mb-0">Dashboard Overview</h1>
                        </div>
                    </div>
                </div>

                <div className="filter-btn">
                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                        <div className="loader"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {
                            !_getBankAccountMatchSettings && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the bank account(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Revenue/Expense GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncBankAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        }
                        {
                            _getOpeningBalanceExistsSettings && (
                                <Alert variant="danger">
                                    <span>
                                        Based on your recent data entry, it appears that you need to upgrade to the latest version of REVREX GL to generate accurate balance sheets and P&L statements. Please click the <strong>Upgrade to Latest</strong> button before proceeding with other tasks.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => setConfirmationToLatest(true)}>Upgrade to Latest</button>
                                </Alert>
                            ) || <></>
                        }
                        {/* {
                            !_getMappedAcc.sales_activity_match && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the POS(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Sales GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncSalesAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        } */}
                        {/* {
                            !_getMappedAcc.sales_activity_match && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the POS(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Sales GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncSalesAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        } */}
                    </div>
                </div>
                {
                    _getAccountSettings.transaction_activity_status >= 3 && (
                        <div className="dashboard-wrap">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <img src={revenueReconciliationIcon} alt="" /> Classification
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS}>Deposit Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.EXPENSE_GL_CLASSIFICATION_STATUS}>Expense Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.SALES_GL_CLASSIFICATION_STATUS}>Revenue Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BANK_RECONCILE}>Bank Reconciliation</Link>
                                                            </li>


                                                            {/* <li>
                                                        <Link to="#">Credit Card Transaction</Link>
                                                    </li> */}
                                                            {/* <li>
                                                        <Link to={URLS.BANK_TRANSACTION}>Bank Transactions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sales Transactions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Bank Reconciliation</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sales Reconciliation</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Credit Card Transaction</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Credit Card Reconciliation </Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        <img src={expenseClassificationIcon} alt="" /> Maintenance
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.CHARTACCOUNT}>Manage Chart of Accounts</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'#'} onClick={() => upsertCOA(false)}>Change Existing Account</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'#'} onClick={() => upsertCOA(true)}>Add New Account</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BUDGET}>Budget Entry</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.OPENNING_BALANCE}>Beginning Balance Entry</Link>
                                                            </li>
                                                            {/* {
                                                                _getMappedAcc?.sales_activity_count == 0 && (

                                                                    <li>
                                                                        <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>
                                                                    </li>

                                                                ) || <></>
                                                            }
                                                            <li>
                                                            {
                                                                _getMappedAcc?.sales_activity_count != 0 && (
                                                                    <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>

                                                                ) || <></>
                                                            }
                                                            </li> */}
                                                            {/* <li>
                                                        <Link to={URLS.SALES_GL_CLASSIFICATION}>Sales GL Classification</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={URLS.CREDIT_CARD_GL_CLASSIFICATION}>Credit GL Classification</Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample3">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                        <img src={exitStrategyIcon} alt="" /> Forms
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            {/* <li>
                                                        <Link to="#">Data Export</Link>
                                                    </li> */}
                                                            <li>
                                                                <Link to={URLS.FOURTHREETHREEB}>433-B</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PERSONAL_INCOME_STATMENT}>Personal Income Statement</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PERSONAL_BALANCE_SHEET}>Personal Balance Sheet</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#">Five Year Forecasting Entry</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                        <img src={reportIcon} alt="" /> Reports
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.BANK_TRANSACTION}>Bank Transaction History</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.SALES_TRANSACTION}>Sales Transaction History</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BALANCESHEET}>Balance Sheet</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PROFITLOSS}>Profit and Loss Statement</Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link to={URLS.STATEMENT_OF_CASH_FLOW}>Statement of Cash Flow</Link>
                                                            </li> */}
                                                            <li>
                                                                <Link to={URLS.BUSINESS_RATIO}>Business Ratios</Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link to={URLS.FOURTHREETHREEB}>433-B FORM</Link>
                                                            </li> */}
                                                            {/* <li>
                                                        <Link to="#">Custom Reports</Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) || (
                        <div className="dashboard-wrap">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="steps-panel-wrapper">
                                        <ul className="steps-panel">
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? 'active' : 'completed'}`}>
                                                <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className='steps-panel-a'>
                                                    <span>1</span>
                                                    <h4>Deposit Classification</h4>
                                                </Link>
                                            </li>
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? '' : _getAccountSettings.transaction_activity_status === 1 ? 'active' : _getAccountSettings.transaction_activity_status === 2 ? 'completed' : 'completed'}`}>
                                                <Link to={_getAccountSettings.transaction_activity_status == 1 || _getAccountSettings.transaction_activity_status == 2 ? URLS.EXPENSE_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>
                                                    <span>2</span>
                                                    <h4>Expense Classification</h4>
                                                </Link>
                                            </li>
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? '' : _getAccountSettings.transaction_activity_status === 1 ? '' : _getAccountSettings.transaction_activity_status === 2 ? 'active' : _getAccountSettings.transaction_activity_status >= 3 ? 'completed' : 'completed'}`}>
                                                <Link to={_getAccountSettings.transaction_activity_status == 2 ? URLS.SALES_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>
                                                    <span>3</span>
                                                    <h4>Revenue Classification</h4>
                                                </Link>

                                            </li>

                                            {/* <li className="step-block completed">
                                            <div>
                                                <span>2</span>
                                                <h4>Expense GL Classification</h4>
                                            </div>
                                        </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* {
                    _userSteps == 3 && (
                        <div className="row mt-4">
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-gmr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-gmr text-uppercase mb-1">
                                                    <strong>Gross Margin Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">{(dashboardRatio?.gross_margin_ratio || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-roar shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-roar text-uppercase mb-1">
                                                    <strong>Return On Assets Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.return_on_assets_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-roer shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-roer text-uppercase mb-1">
                                                    <strong>Return On Equity Ratio</strong>
                                                </div>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                            {(dashboardRatio?.return_on_equity_ratio || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-cr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-cr text-uppercase mb-1">
                                                    <strong>Current Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.cash_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-atr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-atr text-uppercase mb-1">
                                                    <strong>Acid-Test Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.acid_test_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-cashr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-cashr text-uppercase mb-1">
                                                    <strong>Cash Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.cash_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-ocfr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-ocfr text-uppercase mb-1">
                                                    <strong>Operating Cash Flow Ratio</strong>
                                                </div>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                            {(dashboardRatio?.operating_cashflow_ratio || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-dr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-dr text-uppercase mb-1">
                                                    <strong>Debt Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.debt_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-dter shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-dter text-uppercase mb-1">
                                                    <strong>Debt To Equity Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.debt_to_equity_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-icr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-icr text-uppercase mb-1">
                                                    <strong>Interest Coverage Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.interest_coverage_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-dscr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-dscr text-uppercase mb-1">
                                                    <strong>Debt Service Coverage Ratio</strong>
                                                </div>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                            {(dashboardRatio?.debt_service_coverage_ratio || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-aturnr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-aturnr text-uppercase mb-1">
                                                    <strong>Asset turnover Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.asset_turnover_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-itr shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-itr text-uppercase mb-1">
                                                    <strong>Inventory Turnover Ratio</strong>
                                                </div>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                                            {(dashboardRatio?.inventory_turnover_ratio || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-dsiir shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-dsiir text-uppercase mb-1">
                                                    <strong>Days Sales In Inventory Ratio</strong>
                                                </div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {(dashboardRatio?.days_sales_in_inventory_ratio || 0).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-percentage fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ) || <></>
                } */}

            </div>

            {
                (modalSyncGl['syncBankAcc'] || modalSyncGl['syncSalesAcc']) &&
                <RevrexSyncGLAcc syncBankAcc={modalSyncGl['syncBankAcc']} syncSalesAcc={modalSyncGl['syncSalesAcc']} _handleCloseModal={closeSyncModal} /> || <></>
            }

            {
                showCOAModal && <ChartAccountModal closeModal={hideCOAModal} addRecord={createNewAccount} record={null} /> || <></>
            }

            <SAlert
                msg={`Based on your recent data entry, it appears that you need to upgrade to the latest version of REVREX GL to generate accurate balance sheets and P&L statements. Please click the 'Upgrade to Latest' button before proceeding with other tasks.`}
                show={confirmationToLatest}
                title={'Upgrade to get the latest version of GL system.'}
                confirmBtnText={'Upgrade to Latest'}
                cancelBtnText={'No, cancel'}
                onConfirm={confirmUpdationToLatest}
                showCancel={true}
                onCancel={() => setConfirmationToLatest(false)}
            />

        </div>
    )
}

export default Dashboard;