import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { URLS } from '../../resources/constants'
import { getUser } from '../../store/user/selectors'
import { useUserApi } from '../../resources/hooks/api/userApiHook'
import { getAccessToken } from '../../store/user/selectors'
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { CustomTable } from '../../components'
import { useNavigate,Link } from 'react-router-dom'
import { updateUser, authenticateUser,updateAccessToken } from "../../store/user";
import {store} from '../../store';
import pleaseWait from '../../assets/images/loader.webp';
import toast from 'react-hot-toast';




interface Props {
  
}

const UserVerification: React.FC<Props> = () => {
  
  const _users = useSelector(getUser)
  const { userAccess,fetchUserData } = useUserApi();
  const navigate = useNavigate();
  let urlElements = window.location.href.split('=')
  let tempToken = urlElements[1];
    useEffect(() => {
      userAccess({"token":tempToken}, (message: string, resp: any) => {
         navigate(URLS.DASHBOARD);
      }, (message: string, resp: any) => {
      })
    }, [])
    
  


  

  return (
    <>
    <div>
    <center><img src={pleaseWait} className="App-logo" alt="logo" width="50"/></center>
    <center><p><b>Please Wait, Checking Authenticity</b></p></center>
    </div>
    
    </>
      
  )
}

export default UserVerification;