import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { URLS } from '../../resources/constants'
import { getUser } from '../../store/user/selectors'
import { getAccessToken } from '../../store/user/selectors'
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { CustomTable } from '../../components'
import { Link } from 'react-router-dom'
import SideNavbar from '../../layouts/SideNavbar'
import pencilIcon from '../../assets/images/pencil-icon.svg';
import cameraIcon from '../../assets/images/camera-icon.svg';

interface Props {
  
}

const UserProfile: React.FC<Props> = () => {
  const _users = useSelector(getUser)


  return (
    <div className="main-container flex-grow-1">
        <div className="two-col-wrap">
            <SideNavbar/>
            <div className="right-side-wrap">
                <div className="page-title pb-4">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <h1 className="h3 font-weight-700  mb-0">Company Profile</h1>
                        </div>
                    </div>
                </div>
                <div className="white-body-wrap">
                    <div className="profile-box">
                        <h3 className="profile-title"><em>Company info</em> <span><img src={pencilIcon} alt=""/></span></h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Company logo</h4>
                                    <div className="upload-image-wrapper">
                                        <button className="upload-image-btn"><img src={cameraIcon}/>Upload Logo</button>
                                        <input type="file" name="myfile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group-text">
                                            <h4>Company name</h4>
                                            <p>ABC Company</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group-text">
                                            <h4>EIN/SSN</h4>
                                            <p>123456987452213</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group-text">
                                            <h4>Legal name</h4>
                                            <p>XYZ Company Limited</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="profile-box">
                        <h3 className="profile-title"><em>Company type</em> <span><img src={pencilIcon} alt=""/></span></h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Tax form</h4>
                                    <p>Sole proprietor (Form 1040)</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Industry</h4>
                                    <p>Software publishers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-box">
                        <h3 className="profile-title"><em>Contact info</em> <span><img src={pencilIcon} alt=""/></span></h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Company email</h4>
                                    <p>info@abccompany.com</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Customer-facing email</h4>
                                    <p>contact@abccomapny.com</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Company phone</h4>
                                    <p>+1 (480) 329-5661</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Company Website</h4>
                                    <p>www.abccompany.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-box">
                        <h3 className="profile-title"><em>All Addresses</em> <span><img src={pencilIcon} alt=""/></span></h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Company address</h4>
                                    <p>123 e baseline rd, ste d106, Tempe, AZ 85283</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Customer-facing address</h4>
                                    <p>123 e baseline rd, ste d106, Tempe, AZ 85283</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Legal address</h4>
                                    <p>123 e baseline rd, ste d106, Tempe, AZ 85283</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-box mb-0">
                        <h3 className="profile-title"><em>Communications </em> <span><img src={pencilIcon} alt=""/></span></h3>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group-text">
                                    <h4>Communications with RevRex</h4>
                                    <p>Marketing Preferences</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
      
  )
}

export default UserProfile;