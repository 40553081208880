import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link } from 'react-router-dom'
import filterSolidIcon from '../../assets/images/filter-solid-icon.svg';
import toast from 'react-hot-toast';

import { currencyFormat } from '../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import { ExpenseGlClassificationFormI } from '../../resources/form-props';
import { BankSearchFormI } from '../../resources/form-props';
import SAlert from '../../components/SAlert';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import useDebounce from '../../resources/hooks/UseDebounce';
import { glClassificationApi } from '../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate } from '../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import { useBankFeedApi } from '../../resources/hooks/api/bankFeedApiHook'

import './GlClassification.css'
import { GL_VP, MONTHS, SELECT_CUSTOM_STYLE } from '../../resources/constants';
import {  } from '../../resources/form-props';
import { Spinner } from 'react-bootstrap';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import {getBranch} from '../../store/user/selectors'
import SelectCustomOption from '../../components/SelectCustomOption';

interface Props {

}
interface SearchI {
    year: number,
    month: number,
    type: number
}


const BankFeed: React.FC<Props> = () => {

    const { glTransactionlist } = glClassificationApi();
    const { glAccountsList } = useChartAccountApi();
    const { fetchBankTransData, feedUpdate } = useBankFeedApi();

    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [glCreditAccount, setGlCreditAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ contentMaxHeight, setContentMaxHeight ] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#fff'));

    useEffect(() => {
        fechAllAccountList().then(r => {
            setGlCreditAccount(r);
        })
    }, [])

    useEffect(() => {
        fetchBankFeedList();
    }, [selectedCalendar])
    useEffect(() => {
        setContentMaxHeight( calculateMaxHeightContent(98) );
    })

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<BankSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });
    const fechAllAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve1, reject1) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_name_in'] = 'BANK ACCOUNTS';
            glAccountsList(params, (message: string, resp: any) => {

                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve1(data);
                //console.log(resp);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve1([]);
            })
        })
    }

    const fetchBankFeedList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: +selectedCalendar['year'],
            month: +selectedCalendar['month'],
            branch_id: +branchData['id']
        }
        // params['id'] = 0;
        params['is_updated'] = 1;

        setIsLoading(true);

        fetchBankTransData(params, (message: string, resp: any) => {
            //sconsole.log(resp);
            //const modifyData: any[] = [];
            const storeData: any[] = [];
            const result = resp.data.data.result;
            //console.log(result);
            //const result = record_jason;
            const header: any[] = [];
            const subRecord: any[] = [];
            result.map((rec: any, index: any) => {

                const header = rec.breakdowns.map((breakdown: any, index: any) => {

                    if (breakdown.account_chart) {
                        if (rec.account_type == 3 && breakdown.account_type == 'Deposit') {
                            return {
                                id: breakdown.id,
                                previous_master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                account_type: breakdown.account_type,
                                name: breakdown.account_chart && breakdown.account_chart.name || '',
                                account: breakdown.account_chart && breakdown.account_chart.account || '',
                                account_value: breakdown.account_value,
                                debit: breakdown.txn_type == 1 ? breakdown.account_value : 0,
                                credit: breakdown.txn_type == 2 ? breakdown.account_value : 0,
                                revrex_account_id: breakdown.account_chart && breakdown.account_chart.revrex_account_id || null,
                                isEdit: false,
                                showLoader: false,
                                errorMsg: null
                            }
                        }
                        else if (rec.account_type == 1 && breakdown.account_type == 'Credit_Deposit') {
                            return {
                                id: breakdown.id,
                                previous_master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                account_type: breakdown.account_type,
                                name: breakdown.account_chart.name,
                                account: breakdown.account_chart.account,
                                account_value: breakdown.txn_type == 2 ? breakdown.account_value * -1 : breakdown.account_value,
                                debit: breakdown.txn_type == 1 ? breakdown.account_value : 0,
                                credit: breakdown.txn_type == 2 ? breakdown.account_value : 0,
                                revrex_account_id: breakdown.account_chart.revrex_account_id,
                                isEdit: false,
                                showLoader: false,
                                errorMsg: null
                            }
                        }/* else if (rec.account_type == 4 && breakdown.account_type == 'Overshort') {
                            return {
                                account_type: breakdown.account_type,
                                name: breakdown.account_chart.name,
                                account: breakdown.account_chart.account,
                                account_value: breakdown.account_value,
                                debit: breakdown.account_value > 0 ? breakdown.account_value : 0,
                                credit: breakdown.account_value <= 0 ? breakdown.account_value : 0,
                                revrex_account_id: breakdown.account_chart.revrex_account_id
                            }
                        }*/
                        return null;
                    }

                }).filter((item: any) => item);

                const subObjects = rec.breakdowns.map((breakdown: any, index: any) => {
                    if (breakdown.account_chart) {
                        if (breakdown.account_type != 'Credit_Deposit' && breakdown.account_type != 'Deposit') {
                            return {
                                id: breakdown.id,
                                previous_master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                master_chart_of_account_id: breakdown.master_chart_of_account_id && {
                                    label: breakdown.account_chart.name +'('+ breakdown.account_chart.account +')',
                                    value: breakdown.account_chart.id
                                } || null,
                                account_type: breakdown.account_type,
                                name: breakdown.account_chart && breakdown.account_chart.name || '',
                                account: breakdown.account_chart && breakdown.account_chart.account || '',
                                account_value: breakdown.account_value,
                                debit: breakdown.txn_type == 1 ? breakdown.account_value : "",
                                credit: breakdown.txn_type == 2 ? breakdown.account_value : "",
                                revrex_account_id: breakdown.account_chart && breakdown.account_chart.revrex_account_id || null,
                                isEdit: false,
                                showLoader: false,
                                errorMsg: null
                            }
                        }
                        return null;
                    }
                }).filter((item: any) => item);

                // if ( subObjects.length ) {
                    const main_rec = {
                        parent_id: rec.parent_id,
                        transaction_id: rec.id,
                        description: rec.description,
                        transaction_at: rec.transaction_at,
                        header: header.length && header[0] || {},
                        subRecord: subObjects,
                    }
                    storeData.push(main_rec);
                // }

            })

            setData(storeData);
            //setData(storeData);
            //setData(res);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })

    }
    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAllAccountList(inputValue)
        return data;
    };

    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }

    function groupBy(arr: any, property: any) {
        return arr.reduce((acc: any, obj: any) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    const onChangeMasterChart = (event: any, groupIndex: number, recordIndex: number|null ) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];
            if ( recordIndex === null ) {
                groupData['header']['master_chart_of_account_id'] = event;
            } else {
                groupData['subRecord'][recordIndex]['master_chart_of_account_id'] = event;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleEdit = (groupIndex: number, recordIndex: number | null, toggle: boolean) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];
            
            if ( recordIndex === null ) {
                if ( !toggle ) {
                    groupData['header']['master_chart_of_account_id'] = groupData['header']['previous_master_chart_of_account_id'];
                }
                groupData['header']['isEdit'] = toggle;
            } else {
                if ( !toggle ) {
                    groupData['subRecord'][recordIndex]['master_chart_of_account_id'] = groupData['subRecord'][recordIndex]['previous_master_chart_of_account_id'];
                }
                groupData['subRecord'][recordIndex]['isEdit'] = toggle;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleLoader = (groupIndex: number, recordIndex: number | null, toggle: boolean) => {
        setData((prevState: any) => {
            const objects = JSON.parse(JSON.stringify(prevState));
            const groupData = objects[groupIndex];
            if ( recordIndex === null ) {
                groupData['header']['showLoader'] = toggle;
            } else {
                groupData['subRecord'][recordIndex]['showLoader'] = toggle;
            }
            objects.splice(groupIndex, 1, groupData)

            return objects;
        })
    }

    const _handleUpdate = (record: any, groupIndex: number, recordIndex: number | null) => {
        _handleLoader(groupIndex, recordIndex, true);
        const objects = JSON.parse(JSON.stringify(data));
        const groupData = objects[groupIndex];

        if ( !record['master_chart_of_account_id'] ) {
            if ( recordIndex === null ) {
                groupData['header']['errorMsg'] = 'GL is required';
            } else {
                groupData['subRecord'][recordIndex]['errorMsg'] = 'GL is required';
            }
            objects.splice(groupIndex, 1, groupData)
            setData(objects);
            return;
        }

        processData(record).then((resp: any) => {
            if ( recordIndex === null ) {
                groupData['header']['previous_master_chart_of_account_id'] = record['master_chart_of_account_id'];
                groupData['header']['isEdit'] = false;
            } else {
                groupData['subRecord'][recordIndex]['previous_master_chart_of_account_id'] = record['master_chart_of_account_id'];
                groupData['subRecord'][recordIndex]['isEdit'] = false;
            }
            objects.splice(groupIndex, 1, groupData)
            setData(objects);
            _handleLoader(groupIndex, recordIndex, false);
        }).catch((err: any) => {
            _handleLoader(groupIndex, recordIndex, false);
        })

    }

    const processData = (record: any) => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                breakdown_id: record['id'],
                master_chart_of_account_id: record['master_chart_of_account_id']['value'],
                branch_id: +branchData['id']
            }
            feedUpdate(params, (message: string, resp: any) => {
                toast.success(message);
                return resolve(resp)
            }, (message: string, resp: any) => {
                toast.error(message);
                return reject(resp);
            })
        })
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Bank Feed</h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="table-top" id="ux_page_filter">
                            <div className="row">
                                <Form >
                                    <div className="col-sm-6">

                                        <div className="filter-wrap">
                                            <Form.Group className="filter-box" controlId="formBasicEmail">
                                                <Controller
                                                    name="month"
                                                    control={control2}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={MONTHS}
                                                            className={'zindex-10'}
                                                        />
                                                    )}
                                                />
                                                
                                                {
                                                    errors2 && errors2['month'] && errors2['month'].message && (
                                                        <Form.Text className="text-danger">
                                                            {errors2['month'].message as ReactNode || ''}
                                                        </Form.Text>
                                                    ) || ''
                                                }

                                            </Form.Group>
                                            <Form.Group className="filter-box" controlId="formBasicEmail">
                                                <Controller
                                                    name="year"
                                                    control={control2}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={lastYears(5)}
                                                            className={'zindex-10'}
                                                        />
                                                    )}
                                                />
                                                {
                                                    errors2 && errors2['year'] && errors2['year'].message && (
                                                        <Form.Text className="text-danger">
                                                            {errors2['year'].message as ReactNode || ''}
                                                        </Form.Text>
                                                    ) || ''
                                                }

                                            </Form.Group>
                                            <div className="filter-btn">
                                                <button type="button" onClick={handleSubmit2(processSearchData)}><img src={filterSolidIcon} alt="" /> Filter</button>
                                            </div>
                                            <div className="filter-btn">
                                                <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                                    <div className="loader"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    


                                </Form>
                            </div>
                        </div>

                        <br></br>
                        <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                            <table className="table table-default">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="text-start">TXN ID</th>
                                        <th className="text-center">TXN Date</th>
                                        <th className="text-start">Description</th>
                                        <th className="text-end">Amount</th>
                                        <th className="text-end"></th>
                                        <th className="text-start">RevRex GL ACC NUMBER</th>
                                        <th className="text-end">RevRex GL Post  DEBIT</th>
                                        <th className="text-end">RevRex GL Post CREDIT</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        data.length > 0 ?
                                            data.map((rec: any, index: any) => (
                                                <React.Fragment key={index}>
                                                    <tr className="align-middle">
                                                        <th className="text-start">{rec.transaction_id}</th>
                                                        <th className="text-center">
                                                            {moment(rec.transaction_at).format('MM-DD-YYYY h:mm:ss')}
                                                        </th>
                                                        <th className="text-start text-truncate" style={{maxWidth: '110px'}} title={rec.description || 'NA'}>{rec.description || 'NA'}</th>
                                                        <th className="text-end">{ currencyFormat(rec.header.account_value) }</th>
                                                        <th></th>
                                                        <th className="text-start">
                                                            {/* {rec.header.name} ({rec.header.account}) */}
                                                            {
                                                                rec.header.isEdit && (
                                                                    <>
                                                                        <AsyncSelect
                                                                            isClearable={false}
                                                                            placeholder={'Select'}
                                                                            defaultOptions={glCreditAccount}
                                                                            loadOptions={filterAllAccountList}
                                                                            defaultValue={rec.header.master_chart_of_account_id}
                                                                            onChange={(e: any) => onChangeMasterChart(e, index, null)}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                        />
                                                                        { rec.header.errorMsg && (<span className='text-danger'>{rec.header.errorMsg}</span>) || ''}
                                                                    </>
                                                                ) || (
                                                                    rec.header.master_chart_of_account_id && (
                                                                        <span className='text-wrap'>{rec.header.master_chart_of_account_id.label}</span>
                                                                    ) || 
                                                                    <span>NA</span>
                                                                )
                                                            }
                                                        </th>
                                                        <th className="text-end">
                                                            {currencyFormat(Math.abs(rec.header.debit))}
                                                        </th>
                                                        <th className="text-end">
                                                            {currencyFormat(Math.abs(rec.header.credit))}
                                                        </th>
                                                        <th className="text-center">
                                                            {
                                                                rec.header.isEdit && (
                                                                    <div>
                                                                        {
                                                                            rec.header.showLoader && (<Spinner animation="border" size="sm" />) || (
                                                                                <>
                                                                                    <span 
                                                                                    title='Cancel'
                                                                                    className='cursor-pointer text-danger' onClick={() => _handleEdit(index, null, false)} >
                                                                                        <i className="fa fa-cancel" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                    <span 
                                                                                    title='Save'
                                                                                    className='cursor-pointer text-success' onClick={() => _handleUpdate(rec.header, index, null)}>
                                                                                        <i className="fa fa-save" aria-hidden="true"></i>
                                                                                    </span>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) || (
                                                                    <span 
                                                                    title='Update'
                                                                    className='cursor-pointer text-secondary' 
                                                                    onClick={() => _handleEdit(index, null, true)}>
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                    </span>
                                                                )
                                                            }
                                                        </th>
                                                    </tr>
                                                    {rec.subRecord.map((record: any, recordIndex: any) => (
                                                        <tr className="align-middle" key={recordIndex}>
                                                            <td className="text-start">
                                                                {/* {rec.transaction_id} */}
                                                            </td>
                                                            <td className="text-center">
                                                                {/* {rec.transaction_at} */}
                                                            </td>
                                                            <td className="text-start">
                                                                {/* {rec.description} */}
                                                            </td>
                                                            <td className="text-end">
                                                                {/* {record.account_value} */}
                                                            </td>
                                                            <td></td>
                                                            <td className="text-start">
                                                            {
                                                                record.isEdit && (
                                                                    <>
                                                                        <AsyncSelect
                                                                            isClearable={false}
                                                                            placeholder={'Select'}
                                                                            defaultOptions={glCreditAccount}
                                                                            loadOptions={filterAllAccountList}
                                                                            defaultValue={record.master_chart_of_account_id}
                                                                            onChange={(e: any) => onChangeMasterChart(e, index, recordIndex)}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                        />
                                                                        { record.errorMsg && (<span className='text-danger'>{record.errorMsg}</span>) || ''}
                                                                    </>
                                                                ) || (
                                                                    record.master_chart_of_account_id && (
                                                                        <span className='text-wrap'>{record.master_chart_of_account_id.label}</span>
                                                                    ) || 
                                                                    <span>NA</span>
                                                                )
                                                            }
                                                            </td>
                                                            <td className="text-end">
                                                                {currencyFormat(Math.abs(record.debit))}
                                                            </td>
                                                            <td className="text-end">
                                                                {currencyFormat(Math.abs(record.credit))}
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    record.isEdit && (
                                                                        <div>
                                                                            {
                                                                                record.showLoader && (<Spinner animation="border" size="sm" />) || (
                                                                                    <>
                                                                                        <span 
                                                                                        title='Cancel'
                                                                                        className='cursor-pointer text-danger' onClick={() => _handleEdit(index, recordIndex, false)} >
                                                                                            <i className="fa fa-cancel" aria-hidden="true"></i>
                                                                                        </span>
                                                                                        &nbsp;&nbsp;&nbsp;
                                                                                        <span 
                                                                                        title='Save'
                                                                                        className='cursor-pointer text-success' onClick={() => _handleUpdate(record, index, recordIndex)}>
                                                                                            <i className="fa fa-save" aria-hidden="true"></i>
                                                                                        </span>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    ) || (
                                                                        <span 
                                                                        title='Update'
                                                                        className='cursor-pointer text-secondary' 
                                                                        onClick={() => _handleEdit(index, recordIndex, true)}>
                                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                        </span>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            )) :
                                            <tr>
                                                <td colSpan={9} className='text-center'>No data found</td>
                                            </tr>
                                    }



                                </tbody>
                            </table>

                        </div>

                    </div>



                </div>
            </div>
        </div>

    )
}

export default BankFeed;